import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { LoadingStatus } from '../../enums/loading-status.enum';
import { environment } from '../../../environments/environment';
import { Countly, CountlyEvent } from './countly.service.types';
import { OAuthHelperService } from '../o-auth-helper/o-auth-helper.service';

@Injectable({
  providedIn: 'root',
})
export class CountlyService {
  private loadingStatus = new BehaviorSubject(LoadingStatus.NOT_LOADED);
  private readonly appKey: string = environment.countlyAppKey;
  private readonly url: string = environment.countlyUrl;
  private readonly renderer: Renderer2;
  private lastJwtTokenSub?: string;

  constructor(
    private readonly rendererFactory: RendererFactory2,
    private oAuthHelperService: OAuthHelperService,
  ) {
    this.watchTokenChangedAndReconnectWhenTokenChanged();
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  private get Countly(): Countly {
    return window['Countly'] || {};
  }

  handleUserTokenReceived() {
    if (!this.oAuthHelperService.isUserLoggedIn()) {
      return;
    }

    const currentTruId = this.oAuthHelperService.getTruId();

    if (currentTruId === this.lastJwtTokenSub) {
      return;
    }

    this.lastJwtTokenSub = currentTruId;
    this.setDeviceId(this.lastJwtTokenSub);
  }

  handleUserLogout() {
    this.lastJwtTokenSub = undefined;
  }

  watchTokenChangedAndReconnectWhenTokenChanged() {
    this.oAuthHelperService.OAuthService.events.subscribe(event => {
      if (event.type === 'token_received') {
        this.handleUserTokenReceived();
      } else if (event.type === 'logout') {
        this.handleUserLogout();
      }
    });
  }

  public loadCountlyScript(): void {
    if ([LoadingStatus.LOADED, LoadingStatus.LOADING].includes(this.loadingStatus.value)) {
      console.log('Count.ly already loaded');
      return;
    }

    this.loadingStatus.next(LoadingStatus.LOADING);

    const script = this.renderer.createElement('script');
    this.renderer.setAttribute(script, 'type', 'text/javascript');
    this.renderer.setAttribute(script, 'async', 'true');
    this.renderer.setAttribute(script, 'src', 'https://cdn.jsdelivr.net/npm/countly-sdk-web@latest/lib/countly.min.js');
    this.renderer.listen(script, 'load', () => {
      this.loadingStatus.next(LoadingStatus.LOADED);
      this.initCountly();
      this.handleUserTokenReceived();
    });
    this.renderer.listen(script, 'error', () => {
      this.loadingStatus.next(LoadingStatus.ERROR);
    });
    const head = document.head || document.getElementsByTagName('head')[0];
    this.renderer.appendChild(head, script);
  }

  public trackEventWithSegmentation({ key, segmentation }: CountlyEvent) {
    if (this.loadingStatus.value !== LoadingStatus.LOADED) {
      return;
    }

    this.Countly.q.push(['add_event', { key: key, segmentation: segmentation }]);
  }

  public setDeviceId(deviceId: string) {
    if (this.loadingStatus.value !== LoadingStatus.LOADED) {
      return;
    }

    this.Countly.q.push(['change_id', deviceId, true]);
  }

  private initCountly(): void {
    this.Countly.init({
      app_key: this.appKey,
      url: this.url,
      debug: false,
    });
    this.Countly.track_sessions();
    this.Countly.track_pageview(location.pathname + location.hash);
  }
}
