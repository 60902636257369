import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { CountlyService, DeviceEnvironmentService, ModalsService, NftStoreService, OAuthHelperService } from '../../services';
import { PageUrls } from '../../enums/page-urls.enum';
import { ModalId } from '../../enums/modal-id';
import { debounceTime, distinctUntilChanged, Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'togg-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss'],
})
export class MainHeaderComponent implements OnInit, OnDestroy {
  readonly appVersion = environment.appVersion;
  private ngOnDestroy$ = new Subject<void>();
  private searchTextChanged$ = new Subject<string>();

  isUserUsingMobileApp = false;
  isSearchInputFocused = false;
  fullNameOfUser = '';

  @Input() showSearch = false;

  constructor(
    private readonly router: Router,
    private readonly countlyService: CountlyService,
    private readonly deviceEnvironmentService: DeviceEnvironmentService,
    private readonly modalsService: ModalsService,
    private readonly oAuthHelperService: OAuthHelperService,
    protected readonly nftStoreService: NftStoreService,
  ) {}

  ngOnDestroy(): void {
    this.ngOnDestroy$.next();
    this.ngOnDestroy$.complete();
  }

  ngOnInit(): void {
    this.isUserUsingMobileApp = this.deviceEnvironmentService.isMobileApp();
    this.fullNameOfUser = this.oAuthHelperService.getFullName();
    this.searchTextChanged$.pipe(takeUntil(this.ngOnDestroy$), debounceTime(300), distinctUntilChanged()).subscribe(searchValue => {
      this.nftStoreService.saveMainSearchKeyword(searchValue);
    });
  }

  searchTextChanged($event: Event) {
    const inputValue = ($event.target as HTMLInputElement)?.value;
    this.searchTextChanged$.next(inputValue);
  }

  async goToHomePage() {
    await this.router.navigate([PageUrls.HOME]);
  }

  async goToIntroduction() {
    this.countlyService.trackEventWithSegmentation({
      key: 'info icon clicked',
    });
    await this.router.navigate([PageUrls.INTRODUCTION]);
  }

  async goToOwnedNfts() {
    this.countlyService.trackEventWithSegmentation({
      key: 'owned nfts icon clicked',
    });
    await this.router.navigate([PageUrls.MY_NFTS]);
  }

  openBiddingHistoryModal() {
    this.countlyService.trackEventWithSegmentation({
      key: 'bids icon clicked',
    });
    this.modalsService.open(ModalId.BIDDING_HISTORY);
  }

  openLogoutModal() {
    this.countlyService.trackEventWithSegmentation({
      key: 'logout icon clicked',
    });
    this.modalsService.open(ModalId.LOGOUT);
  }

  openMainSearchModal() {
    this.modalsService.open(ModalId.MAIN_SEARCH);
  }

  searchInputBlurred() {
    this.isSearchInputFocused = false;
  }

  searchInputFocused() {
    this.isSearchInputFocused = true;
  }
}
