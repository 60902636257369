import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalId } from '../../enums/modal-id';
import { ModalScreenType } from '../../enums/modal-screen-type';
import { AuctionStoreService, ModalsService } from '../../services';
import { PageUrls } from '../../enums/page-urls.enum';
import { Router } from '@angular/router';
import { nftOperationDtoToNftOperationMapper } from '../../mappers/nft-operations-dto-to-nft-operations.mapper';
import { firstValueFrom, Subscription } from 'rxjs';
import { LoadingStatus } from '../../enums/loading-status.enum';

@Component({
  selector: 'togg-bidding-history',
  templateUrl: './bidding-history.component.html',
  styleUrls: ['./bidding-history.component.scss'],
})
export class BiddingHistoryComponent implements OnInit, OnDestroy {
  protected readonly ModalType = ModalId;
  protected readonly ModalScreenType = ModalScreenType;
  protected readonly nftOperationDtoToNftOperationMapper = nftOperationDtoToNftOperationMapper;
  protected readonly LoadingStatus = LoadingStatus;
  private modalSubscription?: Subscription;

  constructor(
    public readonly auctionStoreService: AuctionStoreService,
    private readonly router: Router,
    private readonly modalService: ModalsService,
  ) {}

  ngOnInit() {
    this.modalSubscription = this.modalService.modalState$.subscribe(modalIds => this.modalStateChange(modalIds));
  }

  ngOnDestroy() {
    this.modalSubscription?.unsubscribe();
  }

  async modalStateChange(modalIds: ModalId[]) {
    if (modalIds.includes(ModalId.BIDDING_HISTORY)) {
      if ((await firstValueFrom(this.auctionStoreService.bidHistoryFirstPageLoadStatusObservable$)) === LoadingStatus.NOT_LOADED) {
        this.auctionStoreService.loadBidHistory();
      }
    }
  }

  async goToMarketplace() {
    this.modalService.close(ModalId.BIDDING_HISTORY);
    await this.router.navigate([PageUrls.HOME]);
  }
}
