import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SelectOption } from './select.component.types';
import { NgSelectConfig } from '@ng-select/ng-select';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'togg-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent {
  @Input() label?: string;
  @Input() disabled: boolean = false;
  @Input() options: SelectOption[] = [];
  @Input() selectedValue?: string;
  @Output() selectedValueChange = new EventEmitter<string>();

  constructor(
    private ngSelectConfig: NgSelectConfig,
    private readonly translate: TranslateService,
  ) {
    this.ngSelectConfig.notFoundText = this.translate.instant('SELECT-COMPONENT.notFoundText');
    this.ngSelectConfig.clearAllText = this.translate.instant('SELECT-COMPONENT.clearAllText');
    this.ngSelectConfig.bindValue = 'value';
    this.ngSelectConfig.bindLabel = 'translationKey';
  }

  onChange() {
    this.selectedValueChange.emit(this.selectedValue);
  }
}
