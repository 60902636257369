import { Component, OnDestroy, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NftsService } from '@togg-trumore/toggens-operations-api-client';
import { Calendar } from 'primeng/calendar';
import { Subject, catchError, of, take } from 'rxjs';
import { SelectOption } from 'src/app/components/select/select.component.types';
import { ModalId } from 'src/app/enums/modal-id';
import { PageUrls } from 'src/app/enums/page-urls.enum';
import { PresetTime } from 'src/app/enums/preset-time.enum';
import { SellType } from 'src/app/enums/sell-flows';
import { AuctionStoreService, CountlyService, ModalsService, Nft } from 'src/app/services';

@Component({
  selector: 'togg-sell-nft-page',
  templateUrl: './sell-nft-page.component.html',
  styleUrls: ['./sell-nft-page.component.scss'],
})
export class SellNftPageComponent implements OnDestroy {
  SellType = SellType;
  @ViewChild(Calendar) startDateCalendar!: Calendar;
  @ViewChild(Calendar) endDateCalendar!: Calendar;
  selectedNfts: Nft[] = [];
  public auctionForm: FormGroup;
  public fixedPriceForm: FormGroup;
  public loading = false;

  public activeIndex = 0;
  private _step = 0;
  public get step(): number {
    return this._step;
  }

  public set step(value: number) {
    this._step = value;
    if (this._step > this.maxStep) {
      this.maxStep = this._step;
    }
    if (value === 0) {
      this.sellType = undefined;
    }
    this.fixedPriceForm.controls['agreed']?.setValue(false);
    this.auctionForm.controls['agreed']?.setValue(false);
  }

  public sellType?: SellType;
  public skipTimeLimitation = false;
  public ngUnsubscribe$ = new Subject<void>();
  public gasFee = 0;
  public maxStep = 0;

  public fixedPriceSteps = [
    {
      step: 1,
      label: this.translate.instant('AUCTIONS.FIX-PRICE-TAB'),
    },
    {
      step: 2,
      label: this.translate.instant('AUCTIONS.TIME-EXPIRATION-TAB'),
    },
    {
      step: 3,
      label: this.translate.instant('AUCTIONS.SUMMARY'),
    },
  ];

  public auctionSteps = [
    {
      step: 1,
      label: this.translate.instant('AUCTIONS.START-PRICE-TAB'),
    },
    {
      step: 2,
      label: this.translate.instant('AUCTIONS.BUY-NOW-TAB'),
    },
    {
      step: 3,
      label: this.translate.instant('AUCTIONS.TIME-EXPIRATION-TAB'),
    },
    {
      step: 4,
      label: this.translate.instant('AUCTIONS.SUMMARY'),
    },
  ];

  public carouselResponsiveOptions = [
    {
      breakpoint: '1250px',
      numVisible: 5.2,
      numScroll: 1,
    },
    {
      breakpoint: '1000px',
      numVisible: 4.2,
      numScroll: 1,
    },
    {
      breakpoint: '850px',
      numVisible: 3.2,
      numScroll: 1,
    },
    {
      breakpoint: '700px',
      numVisible: 2.2,
      numScroll: 1,
    },
    {
      breakpoint: '500px',
      numVisible: 1.2,
      numScroll: 1,
    },
  ];

  private now = new Date();

  presetTimeOptions: SelectOption[] = Object.keys(PresetTime).map(value => ({
    value,
    translationKey: `AUCTIONS.PRESET-TIME.${value}`,
  }));

  constructor(
    fb: FormBuilder,
    private router: Router,
    private modalsService: ModalsService,
    private auctionStoreService: AuctionStoreService,
    private nftsService: NftsService,
    private readonly translate: TranslateService,
    private countlyService: CountlyService,
  ) {
    const endDate = new Date();
    endDate.setDate(this.now.getDate() + 7);
    this.selectedNfts = this.router.getCurrentNavigation()?.extras?.state?.['selectedNfts'] ?? [];
    if (!this.selectedNfts.length) {
      this.router.navigateByUrl(PageUrls.MY_NFTS);
    }

    //take just one item when editing an exisiting auction
    if (this.selectedNfts.length > 2 && this.selectedNfts[0].onSale) {
      this.selectedNfts = [this.selectedNfts[0]];
    }

    const onSaleItem = this.selectedNfts[0].onSale ? this.selectedNfts[0] : null;

    this.auctionForm = fb.group({
      startPrice: [onSaleItem?.initialPrice ?? null, [Validators.required, this.validateLargerThan0.bind(this)]],
      maxPrice: [onSaleItem?.maxPrice ?? null, [Validators.required, this.validateMaxPrice.bind(this)]],
      minimumBid: [onSaleItem?.minBid ?? null, [Validators.required, this.validateLargerThan0.bind(this)]],
      endDate: [this.parseDate(onSaleItem?.bidEndDate) ?? endDate, [Validators.required, this.validateEndDate.bind(this)]],
      agreed: [false],
    });

    this.fixedPriceForm = fb.group({
      fixedPrice: [onSaleItem?.initialPrice ?? null, [Validators.required, this.validateLargerThan0.bind(this)]],
      endDate: [this.parseDate(onSaleItem?.bidEndDate) ?? endDate, [this.validateEndDate.bind(this)]],
      skipTimeLimitation: [onSaleItem ? !onSaleItem.bidEndDate : false],
      agreed: [false],
    });

    if (onSaleItem) {
      this.sellType = onSaleItem.initialPrice === onSaleItem.maxPrice ? SellType.FIXED_PRICE : SellType.AUCTION;
      this.step = 1;
      this.maxStep = this.sellType === SellType.AUCTION ? 4 : 3;
    }
  }

  private parseDate(stringDate: string | undefined): Date | null {
    if (stringDate) {
      return new Date(stringDate);
    }

    return null;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  get auctionControls(): { [key: string]: AbstractControl } {
    return this.auctionForm?.controls;
  }

  get fixedPriceControls(): { [key: string]: AbstractControl } {
    return this.fixedPriceForm?.controls;
  }

  async goToHomePage() {
    await this.router.navigate([PageUrls.HOME]);
  }

  setFixedPriceSell() {
    this.sellType = SellType.FIXED_PRICE;
    this.step = 1;

    this.countlyService.trackEventWithSegmentation({
      key: 'fixed price flow clicked',
    });
  }

  setAuctionSell() {
    this.sellType = SellType.AUCTION;
    this.step = 1;

    this.countlyService.trackEventWithSegmentation({
      key: 'auction flow clicked',
    });
  }

  private validateMaxPrice(control: AbstractControl) {
    const startPrice = control.parent?.get('startPrice')?.value ?? 0;
    const maxPrice = control.value ?? 0;

    if (startPrice <= 0 || startPrice >= maxPrice) {
      return {
        minPriceInvalid: true,
      };
    }

    return null;
  }

  private validateLargerThan0(control: AbstractControl) {
    if (this.sellType === SellType.FIXED_PRICE) {
      const value = control.value ?? 0;

      if (value <= 0) {
        return {
          maxPriceInvalid: true,
        };
      }
    }

    return null;
  }

  private validateEndDate(control: AbstractControl): ValidationErrors | null {
    if (this.sellType === SellType.FIXED_PRICE && this.fixedPriceControls['skipTimeLimitation']?.value) {
      return null;
    }

    const today = this.now;
    if (!control.value || control.value <= today) {
      return { endDateInvalid: true };
    }

    return null;
  }

  onSubmitAuction() {
    if (this.step == 1 && this.auctionControls['startPrice'].valid) {
      this.step++;
      return;
    }
    if (this.step == 2 && this.auctionControls['maxPrice'].valid) {
      this.step++;
      return;
    }
    if (this.step < 4) {
      return;
    }

    this.countlyService.trackEventWithSegmentation({
      key: 'listing auction contine clicked',
    });

    this.loading = true;
    const nfts = this.mapSelectedNftsForAuction();
    const submitObservable = this.selectedNfts[0].onSale ? this.nftsService.editNftListing(nfts) : this.nftsService.nftlisting(nfts);
    submitObservable.pipe(take(1)).subscribe({
      next: () => {
        this.loading = false;
        this.auctionStoreService.setConfirmation({
          type: 'info',
          header: this.translate.instant('AUCTIONS.LISTING-SUCCEEDED'),
          text: this.translate.instant('AUCTIONS.LISTING-SUCCEEDED-TEXT'),
          showClose: false,
          actionHandler: async () => {
            this.countlyService.trackEventWithSegmentation({
              key: 'magnet nft listing succeeded',
            });
            this.modalsService.close(ModalId.CONFIRMATION);
            await this.router.navigate([PageUrls.MY_NFTS]);
          },
          confirmBtnText: this.translate.instant('AUCTIONS.PROFILE-PAGE'),
        });
        this.modalsService.open(ModalId.CONFIRMATION);
      },
      error: () => {
        this.loading = false;
        this.auctionStoreService.setConfirmation({
          type: 'error',
          header: this.translate.instant('AUCTIONS.LISTING-FAILED'),
          text: this.translate.instant('AUCTIONS.LISTING-FAILED-TEXT'),
          showClose: false,
          actionHandler: () => {
            this.modalsService.close(ModalId.CONFIRMATION);
          },
          confirmBtnText: this.translate.instant('CONFIRMATION-MODAL.CLOSE'),
        });
        this.modalsService.open(ModalId.CONFIRMATION);
      },
    });
  }

  private mapSelectedNftsForAuction() {
    const values = this.auctionForm.value;
    return this.selectedNfts.map(nft => ({
      nftId: nft.nftId,
      collectionAddress: nft.collectionAddress,
      initialPrice: values.startPrice,
      maxPrice: values.maxPrice,
      launchDate: this.now.toISOString(),
      bidEndDate: values.endDate,
      highlighted: false,
    }));
  }

  openFixedPriceSummaryPage() {
    this.step++;
    this.countlyService.trackEventWithSegmentation({
      key: 'listing fixed price checkout page opened',
    });

    const nfts = this.mapSelectedNftsForFixedPrice();
    const gasFeeObservable = this.selectedNfts[0].onSale ? this.nftsService.estimateEditNftListing(nfts) : this.nftsService.estimateListNFT(nfts);
    gasFeeObservable
      .pipe(
        catchError(() => of(0)),
        take(1),
      )
      .subscribe(value => (this.gasFee = value));
  }

  openAuctionSummaryPage() {
    this.step++;
    this.countlyService.trackEventWithSegmentation({
      key: 'listing auction checkout page opened',
    });

    const nfts = this.mapSelectedNftsForAuction();
    const gasFeeObservable = this.selectedNfts[0].onSale ? this.nftsService.estimateEditNftListing(nfts) : this.nftsService.estimateListNFT(nfts);
    gasFeeObservable
      .pipe(
        catchError(() => of(0)),
        take(1),
      )
      .subscribe(value => (this.gasFee = value));
  }

  onSubmitFixedPrice() {
    if (this.step < 3) {
      if (this.fixedPriceForm.valid) {
        this.step++;
      }
      return;
    }

    this.countlyService.trackEventWithSegmentation({
      key: 'listing fixed price contine clicked',
    });

    this.loading = true;
    const nfts = this.mapSelectedNftsForFixedPrice();
    const submitObservable = this.selectedNfts[0].onSale ? this.nftsService.editNftListing(nfts) : this.nftsService.nftlisting(nfts);
    submitObservable.pipe(take(1)).subscribe({
      next: () => {
        this.loading = false;
        this.auctionStoreService.setConfirmation({
          type: 'info',
          header: this.translate.instant('AUCTIONS.LISTING-SUCCEEDED'),
          text: this.translate.instant('AUCTIONS.LISTING-SUCCEEDED-TEXT'),
          showClose: false,
          actionHandler: async () => {
            this.countlyService.trackEventWithSegmentation({
              key: 'magnet nft listing succeeded',
            });
            this.modalsService.close(ModalId.CONFIRMATION);
            await this.router.navigate([PageUrls.MY_NFTS]);
          },
          confirmBtnText: this.translate.instant('AUCTIONS.PROFILE-PAGE'),
        });
        this.modalsService.open(ModalId.CONFIRMATION);
      },
      error: () => {
        this.loading = false;
        this.auctionStoreService.setConfirmation({
          type: 'error',
          header: this.translate.instant('AUCTIONS.LISTING-FAILED'),
          text: this.translate.instant('AUCTIONS.LISTING-FAILED-TEXT'),
          showClose: false,
          actionHandler: () => {
            this.modalsService.close(ModalId.CONFIRMATION);
          },
          confirmBtnText: this.translate.instant('CONFIRMATION-MODAL.CLOSE'),
        });
        this.modalsService.open(ModalId.CONFIRMATION);
      },
    });
  }

  private mapSelectedNftsForFixedPrice() {
    const values = this.fixedPriceForm.value;
    const nfts = this.selectedNfts.map(nft => ({
      nftId: nft.nftId,
      collectionAddress: nft.collectionAddress,
      initialPrice: values.fixedPrice,
      maxPrice: values.fixedPrice,
      launchDate: this.now.toISOString(),
      bidEndDate: values.skipTimeLimitation ? null : values.endDate,
      highlighted: false,
    }));
    return nfts;
  }

  setFixedPriceEndTime(endTime: Date) {
    const currentEndDate = this.fixedPriceControls['endDate'].value as Date;
    this.fixedPriceControls['endDate'].setValue(
      new Date(currentEndDate.getFullYear(), currentEndDate.getMonth(), currentEndDate.getDate(), endTime.getHours(), endTime.getMinutes()),
      { emitEvent: false },
    );
  }

  setFixedPriceEndDate(endDate: Date) {
    const currentEndTime = this.fixedPriceControls['endDate'].value as Date;
    this.fixedPriceControls['endDate'].setValue(new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), currentEndTime.getHours(), currentEndTime.getMinutes()), {
      emitEvent: false,
    });
  }

  setAuctionEndTime(endTime: Date) {
    const currentEndDate = this.auctionControls['endDate'].value as Date;
    this.auctionControls['endDate'].setValue(
      new Date(currentEndDate.getFullYear(), currentEndDate.getMonth(), currentEndDate.getDate(), endTime.getHours(), endTime.getMinutes()),
      { emitEvent: false },
    );
  }

  setAuctionEndDate(endDate: Date) {
    const currentEndTime = this.auctionControls['endDate'].value as Date;
    this.auctionControls['endDate'].setValue(new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), currentEndTime.getHours(), currentEndTime.getMinutes()), {
      emitEvent: false,
    });
  }

  setDateNow(control: AbstractControl) {
    control.setValue(this.now);
    control.markAsDirty();
  }

  resetTime(control: AbstractControl) {
    if (!control.value) {
      return;
    }
    const date = control.value as Date;
    date.setHours(0, 0, 0, 0);
    control.setValue(date);
    control.markAsDirty();
  }

  handlePresetTimeChange(value: string, control: AbstractControl) {
    const presetTime = value as PresetTime;
    const date = new Date(this.now);
    switch (presetTime) {
      case PresetTime.Day1:
        date.setDate(date.getDate() + 1);
        break;
      case PresetTime.Days3:
        date.setDate(date.getDate() + 3);
        break;
      case PresetTime.Days7:
        date.setDate(date.getDate() + 7);
        break;
      case PresetTime.Days30:
        date.setDate(date.getDate() + 30);
        break;
      case PresetTime.Days180:
        date.setDate(date.getDate() + 180);
        break;
    }
    control.setValue(date);
  }

  close(calendarControl: Calendar) {
    calendarControl.hideOverlay();
  }

  getExpirationDaysCount(endDate: AbstractControl) {
    const expiration = endDate.value as Date;
    return Math.round((expiration.getTime() - this.now.getTime() + 1000 * 600) / (1000 * 3600 * 24));
  }
}
