<div class="login-page">
  <div class="max-width-limiter">
    <div class="home-introduction-image-background">
      <div class="image-grid">
        <div class="grid-item" style="background-image: url(/assets/images/home_top_left.webp)"></div>
        <div class="grid-item" style="background-image: url(/assets/images/home_top_right.webp)"></div>
        <div class="grid-item" style="background-image: url(/assets/images/home_bottom_left.webp)"></div>
        <div class="grid-item" style="background-image: url(/assets/images/home_bottom_right.webp)"></div>
      </div>
    </div>

    <div class="home-text-and-action-area">
      <p [innerHTML]="'Home-Page_Component.TextTitle.IntroText' | translate" class="home-text-msg"></p>

      <div class="home-actions-area">
        <span class="home-lets-start-text">{{ 'Home-Page_Component.LetsStart' | translate }}</span>

        <button (click)="onFabButtonClicked()" class="home-click-button">
          <img alt="arrow" height="15" ngSrc="/assets/images/next-icon.svg" width="21" />
        </button>
      </div>
    </div>
  </div>
</div>
