<div class="nft-details-header">
  <div *ngIf="type === 'expand_image'" class="expand-image-area">
    <button *ngIf="showEdit && type === 'expand_image'" class="btn-text white mr-auto" (click)="emitCancelListing()" type="button">
      <span class="expand-image-text">
        {{ 'BIDDING-PAGE-COMPONENT.CANCEL-LISTING' | translate }}
      </span>
    </button>
    <button *ngIf="showEdit" class="btn-text white expand-image-button" (click)="emitEditListing()" type="button">
      <span class="expand-image-text">
        {{ 'BIDDING-PAGE-COMPONENT.EDIT-AUCTION' | translate }}
      </span>
      <img alt="expand" class="expand-icon" height="18" ngSrc="/assets/images/edit.svg" width="18" />
    </button>
    <div *ngIf="showEdit" class="divider"></div>
    <button (click)="expandImageButtonClicked()" class="expand-image-button" type="button">
      <span class="expand-image-text">
        {{ 'DETAILS-HEADER.IMG' | translate }}
      </span>

      <img alt="expand" class="expand-icon" height="18" ngSrc="/assets/images/expand.svg" width="18" />
    </button>
  </div>

  <div *ngIf="type === 'play_video'" class="play-video-area">
    <button (click)="sendCountlyPlayVideoClickEvent(); playVideoButtonClickedHandler()" class="play-video-button" type="button">
      <span class="play-video-text">
        {{ 'DETAILS-HEADER.VID' | translate }}
      </span>

      <img alt="play" class="play-video-icon" height="18" ngSrc="/assets/images/play.svg" width="15" />
    </button>
  </div>

  <div *ngIf="type === 'only_close_icon'" class="expand-image-area">
    <button (click)="onCloseButtonClicked()" class="expand-image-button" type="button">
      <span class="expand-image-text">
        {{ 'DETAILS-HEADER.HIDE' | translate }}
      </span>

      <img alt="inner" class="expand-icon" height="18" ngSrc="/assets/images/inner.svg" width="18" />
    </button>
  </div>
</div>
