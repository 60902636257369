import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject, filter, takeUntil, tap } from 'rxjs';

export type NftFilter = {
  all: boolean;
  unlisted: boolean;
  fixedPrice: boolean;
  onAuction: boolean;
  nonTransferable: boolean;
};

@Component({
  selector: 'togg-nft-filter',
  templateUrl: './nft-filter.component.html',
  styleUrls: ['./nft-filter.component.scss'],
})
export class NftFilterComponent implements OnDestroy {
  protected filterForm: FormGroup;
  private ignoreEvents = false;
  private ngUnsubscribe$ = new Subject<void>();
  @Output() filterChanged = new EventEmitter<NftFilter>();

  constructor(fb: FormBuilder) {
    this.filterForm = fb.group({
      all: [true],
      unlisted: [true],
      fixedPrice: [true],
      onAuction: [true],
      nonTransferable: [true],
    });

    this.filterForm.controls['all'].valueChanges
      .pipe(
        takeUntil(this.ngUnsubscribe$),
        filter(() => !this.ignoreEvents),
      )
      .subscribe((value: boolean) => {
        this.ignoreEvents = true;
        this.filterForm.setValue({
          all: value,
          unlisted: value,
          fixedPrice: value,
          onAuction: value,
          nonTransferable: value,
        });
        this.ignoreEvents = false;
      });

    this.filterForm.valueChanges
      .pipe(
        takeUntil(this.ngUnsubscribe$),
        filter(() => !this.ignoreEvents),
        tap(value => this.filterChanged.emit(value)),
      )
      .subscribe(value => {
        this.ignoreEvents = true;
        const { unlisted, fixedPrice, onAuction, nonTransferable } = value;
        this.filterForm.controls['all'].setValue(unlisted && fixedPrice && onAuction && nonTransferable);
        this.ignoreEvents = false;
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
