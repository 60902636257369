import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

type Step = {
  step: number;
  label: string;
};

@Component({
  selector: 'togg-step-tabs',
  templateUrl: './step-tabs.component.html',
  styleUrls: ['./step-tabs.component.scss'],
})
export class StepTabsComponent implements OnChanges {
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['currentStep'] && this.maxStep < changes['currentStep'].currentValue) {
      this.maxStep = changes['currentStep'].currentValue;
    }

    const tabsContainer = document.getElementById('tabs-container');
    let stepToGo = changes['currentStep'].currentValue;
    if (stepToGo > 1) {
      stepToGo--;
    }
    const stepElement = document.getElementById('step-' + stepToGo);
    if (stepElement && tabsContainer) {
      tabsContainer.scrollTo({
        top: 0,
        left: stepElement.offsetLeft,
        behavior: 'smooth',
      });
    }
  }
  @Input() currentStep!: number;
  @Input() maxStep = 0;
  @Input() steps!: Step[];
  @Output() stepChange = new EventEmitter<number>();
}
