import { Injectable } from '@angular/core';
import { NftCollectionDto, NftDto, NftOperationDto, NftsService } from '@togg-trumore/toggens-operations-api-client';
import { BehaviorSubject, catchError, combineLatest, firstValueFrom, map, Observable, take } from 'rxjs';
import { Nft } from './nft-store.service.types';
import { LoadingStatus } from '../../enums/loading-status.enum';
import { nftDtoToNftMapper } from '../../mappers/nft-dto-to-nft.mapper';
import { NftInBiddingMessage, ReceivedBiddingMessage, ReceivedOutbiddingMessage } from '../socket/socket.service.types';
import { OAuthHelperService } from '../o-auth-helper/o-auth-helper.service';

@Injectable({
  providedIn: 'root',
})
export class NftStoreService {
  public static readonly PAGE_SIZE = 20;

  public currentPage = 0;

  morePageAvailable$: Observable<boolean>;
  isWhitelistingEnabled$: Observable<boolean>;
  private morePageIsEmpty$ = new BehaviorSubject(false);
  private duplicateNftItemsCount = new BehaviorSubject<number>(0);

  private nftItems = new BehaviorSubject<Nft[]>([]);
  nftItems$ = this.nftItems.asObservable();
  private totalNftItems = new BehaviorSubject<number>(0);
  totalNftItems$ = this.totalNftItems.asObservable();
  private firstPageLoadStatus = new BehaviorSubject<LoadingStatus>(LoadingStatus.NOT_LOADED);
  firstPageLoadStatus$ = this.firstPageLoadStatus.asObservable();
  private loadingCollectionsStatus = new BehaviorSubject<LoadingStatus>(LoadingStatus.NOT_LOADED);
  loadingCollections$ = this.loadingCollectionsStatus.asObservable();
  private nextPageLoadStatus = new BehaviorSubject<LoadingStatus>(LoadingStatus.NOT_LOADED);
  private collections = new BehaviorSubject<NftCollectionDto[]>([]);
  public collections$ = this.collections.asObservable();
  nextPageLoadStatus$ = this.nextPageLoadStatus.asObservable();
  private userNftItems = new BehaviorSubject<Nft[]>([]);
  userNftItems$ = this.userNftItems.asObservable();

  private selectedNftItemForDetailsPage = new BehaviorSubject<Nft | null>(null);
  selectedNftItemForDetailsPage$ = this.selectedNftItemForDetailsPage.asObservable();

  private selectedNftItemOffers = new BehaviorSubject<NftOperationDto[]>([]);
  selectedNftItemOffers$ = this.selectedNftItemOffers.asObservable();

  private selectedNftItemOffersLoadingStatus = new BehaviorSubject<LoadingStatus>(LoadingStatus.NOT_LOADED);
  selectedNftItemOffersLoadingStatus$ = this.selectedNftItemOffersLoadingStatus.asObservable();

  private searchNftKeyword = new BehaviorSubject<string>('');
  searchNftKeyword$ = this.searchNftKeyword.asObservable();
  private collectionAddress = new BehaviorSubject('');

  private mainSearchKeyword = new BehaviorSubject<string>('');
  mainSearchKeyword$ = this.mainSearchKeyword.asObservable();

  private loadingNftItems = new BehaviorSubject(false);
  loadingNftItems$ = this.loadingNftItems.asObservable();

  constructor(
    private readonly nftsService: NftsService,
    private readonly oAuthHelperService: OAuthHelperService,
  ) {
    this.morePageAvailable$ = combineLatest([this.totalNftItems$, this.nftItems$, this.morePageIsEmpty$, this.duplicateNftItemsCount]).pipe(
      map(([totalNftItems, nftItems, morePageIsEmpty, duplicateNftItemsCount]) => {
        return totalNftItems > nftItems.length + duplicateNftItemsCount && !morePageIsEmpty;
      }),
    );

    this.isWhitelistingEnabled$ = this.nftsService.isWhitelistingEnabled();
  }

  setSelectedNftItemForDetailsPage(nft: Nft | null) {
    this.selectedNftItemForDetailsPage.next(nft);

    if (nft) {
      this.getNftOffers(nft.nftInternalId);
    } else {
      this.selectedNftItemOffers.next([]);
    }
  }

  setCollectionAddress(collectionAddress: string) {
    this.collectionAddress.next(collectionAddress);
  }

  saveSearchNftKeyword(keyword: string) {
    this.searchNftKeyword.next(keyword);
    this.loadFirstPage();
  }

  saveMainSearchKeyword(keyword: string) {
    this.mainSearchKeyword.next(keyword);
  }

  getUserNfts() {
    this.loadingNftItems.next(true);
    return this.nftsService
      .getNfts(this.oAuthHelperService.getTruId())
      .pipe(
        take(1),
        map(result => result.map(nftDtoToNftMapper)),
      )
      .subscribe({
        next: value => {
          this.userNftItems.next(value);
          this.loadingNftItems.next(false);
        },
        error: () => {
          this.loadingNftItems.next(false);
        },
      });
  }

  getNftsForCollection(collectionAddress: string) {
    return this.nftsService.getNftsByCollection(collectionAddress).pipe(map(result => result.map(nftDtoToNftMapper)));
  }

  loadCollections() {
    this.loadingCollectionsStatus.next(LoadingStatus.LOADING);
    combineLatest([this.nftsService.getNftCollections1(), this.mainSearchKeyword$])
      .pipe(
        catchError(error => {
          this.loadingCollectionsStatus.next(LoadingStatus.ERROR);
          throw error;
        }),
      )
      .subscribe(([collections, searchKeyword]) => {
        if (!searchKeyword) {
          this.collections.next(collections);
        } else {
          this.collections.next(collections.filter(c => c.name?.toLocaleLowerCase().startsWith(searchKeyword.toLocaleLowerCase())));
        }
        this.loadingCollectionsStatus.next(LoadingStatus.LOADED);
      });
  }

  loadFirstPage() {
    this.currentPage = 0;
    this.loadPage();
  }

  loadNextPage() {
    this.loadPage();
  }

  removeFromNftItems(nftId: number) {
    const currentNftItems = this.nftItems.getValue();
    const updatedNftItems = currentNftItems.filter(nft => nft.nftId !== nftId);

    this.nftItems.next(updatedNftItems);
    this.totalNftItems.next(this.totalNftItems.getValue() - 1);
  }

  public addNftOfferFromWebsocket(message: ReceivedBiddingMessage) {
    const currentSelectedNftItemOffers = this.selectedNftItemOffers.getValue();
    const currentSelectedNftItem = this.selectedNftItemForDetailsPage.getValue();

    if (!['RELEASED', 'BEATEN', 'ERROR', 'PAYMENT_ERROR'].includes(message.status)) {
      return;
    }

    if (message.nft.nftInternalId === currentSelectedNftItem?.nftInternalId) {
      const newOffer = message as NftOperationDto;
      this.selectedNftItemOffers.next([newOffer, ...currentSelectedNftItemOffers]);
    }
  }

  public addNftOfferFromOutbidWebsocket(message: ReceivedOutbiddingMessage) {
    const currentSelectedNftItemOffers = this.selectedNftItemOffers.getValue();
    const currentSelectedNftItem = this.selectedNftItemForDetailsPage.getValue();

    if (!['RELEASED', 'BEATEN', 'ERROR', 'PAYMENT_ERROR'].includes(message.status)) {
      return;
    }

    if (message.nft.nftInternalId === currentSelectedNftItem?.nftInternalId) {
      const newOffer = message as unknown as NftOperationDto;
      newOffer.price = message.newPrice;
      newOffer.destinationWallet = message.newBidderAddress;
      newOffer.customerId = message.newBidderToggId;
      this.selectedNftItemOffers.next([newOffer, ...currentSelectedNftItemOffers]);
    }
  }

  public updateNftItemFromWebsocket(message: NftInBiddingMessage) {
    const currentSelectedNftItem = this.selectedNftItemForDetailsPage.getValue();
    if (message.nftInternalId === currentSelectedNftItem?.nftInternalId) {
      this.selectedNftItemForDetailsPage.next({
        ...currentSelectedNftItem,
        ...(message as Nft),
      });
    }

    const currentNftItems = this.nftItems.getValue();
    const nftItemHasNftId = currentNftItems.find(nftItem => nftItem.nftInternalId === message.nftInternalId);
    if (!nftItemHasNftId) {
      return;
    }

    const updatedNftItems = currentNftItems.map(nftItem => {
      if (nftItem.nftId === message.nftId && nftItem.collectionAddress === message.collectionAddress) {
        return {
          ...nftItem,
          ...(message as Nft),
        };
      }
      return nftItem;
    });

    this.nftItems.next(updatedNftItems);
  }

  clearNfts() {
    this.totalNftItems.next(0);
    this.nftItems.next([]);
  }

  private async getNftOffers(nftInternalId: string) {
    this.selectedNftItemOffers.next([]);
    this.selectedNftItemOffersLoadingStatus.next(LoadingStatus.LOADING);

    try {
      const offersData = await firstValueFrom(
        this.nftsService.getNftOperationsByFilter(nftInternalId, 'DESC', 0, 4, undefined, ['RELEASED', 'BEATEN', 'ERROR', 'PAYMENT_ERROR', 'WON']),
      );
      this.selectedNftItemOffers.next(offersData);
      this.selectedNftItemOffersLoadingStatus.next(LoadingStatus.LOADED);
    } catch (err) {
      this.selectedNftItemOffersLoadingStatus.next(LoadingStatus.ERROR);
    }
  }

  private loadPage() {
    const isFirstPage = this.currentPage === 0;

    if (isFirstPage) {
      this.loadingCollectionsStatus.next(LoadingStatus.LOADING);
    } else {
      this.nextPageLoadStatus.next(LoadingStatus.LOADING);
    }

    this.nftsService
      .getNftsWithFilter(this.currentPage, NftStoreService.PAGE_SIZE, this.collectionAddress.getValue(), this.searchNftKeyword.getValue())
      .pipe(
        catchError(error => {
          if (isFirstPage) {
            this.firstPageLoadStatus.next(LoadingStatus.ERROR);
          } else {
            this.nextPageLoadStatus.next(LoadingStatus.ERROR);
          }
          throw error;
        }),
      )
      .subscribe(response => {
        this.currentPage++;

        this.totalNftItems.next(response.nftCount ?? 0);

        const nfts = response.nfts as unknown as NftDto[];

        const nftList = nfts.map(nft => {
          return nftDtoToNftMapper(nft);
        });

        this.morePageIsEmpty$.next(nftList.length === 0);

        if (isFirstPage) {
          this.nftItems.next(nftList);
          this.duplicateNftItemsCount.next(0);
        } else {
          const currentNftItems = this.nftItems.getValue();
          const combinedList = [...currentNftItems, ...nftList];
          const uniqueNftList = combinedList.filter((item, index) => !combinedList.some((otherItem, otherIndex) => otherItem.nftId === item.nftId && otherIndex < index));

          this.duplicateNftItemsCount.next(this.duplicateNftItemsCount.getValue() + (combinedList.length - uniqueNftList.length));

          this.nftItems.next(uniqueNftList);
        }

        if (isFirstPage) {
          this.firstPageLoadStatus.next(LoadingStatus.LOADED);
        } else {
          this.nextPageLoadStatus.next(LoadingStatus.LOADED);
        }
      });
  }
}
