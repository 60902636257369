<togg-base-modal [modalId]="ModalId.PRESALE" [showCloseButton]="true">
  <div bodyContent class="presale-modal-drawer">
    <div class="presale-modal-drawer-content">
      <h4 class="presale-modal-drawer-title">
        {{ 'PRESALE.ALMOST' | translate }}
      </h4>

      <div class="presale-modal-no-bid">
        {{ 'PRESALE.MSG' | translate }}
      </div>
    </div>
  </div>
</togg-base-modal>
