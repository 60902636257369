<div (click)="logout()" class="fixed-logout-button">
  <img alt="logOut" class="logout-icon" height="20" ngSrc="/assets/images/logout.svg" width="20" />
  <p>Çıkış</p>
</div>
<div class="no-wallet-bg">
  <div class="no-wallet-container">
    <header class="no-wallet-logo">
      <img alt="Trumore Logo" height="64" ngSrc="/assets/images/trumore_logo.svg" width="200" />
    </header>

    <main class="no-wallet-main">
      <h2>
        {{ 'NO-WALLET.HEADING' | translate }}
      </h2>

      <div class="qr-code-block">
        <div class="qr-code-image-wrapper">
          <img alt="Wallet Download QR Code" class="qr-code-image" height="200" ngSrc="/assets/images/wallet-download-qr.svg" width="200" />
        </div>

        <div class="download_buttons">
          <a href="https://apps.apple.com/tr/app/trumore/id1610978415" rel="noopener noreferrer" target="_blank">
            <img alt="App Store" height="52" ngSrc="/assets/images/app_store_badge.svg" width="200" />
          </a>

          <a href="https://play.google.com/store/apps/details?id=com.togg.toggmobile&amp;hl=tr&amp;gl=US" rel="noopener noreferrer" target="_blank">
            <img alt="Google Play" height="59" ngSrc="/assets/images/google_play_badge.svg" width="200" />
          </a>
        </div>

        <div class="qr-code-description">
          {{ 'NO-WALLET.DESCRIPTION' | translate }}
        </div>
      </div>

      <div class="image-grid">
        <div class="grid-item" style="background-image: url(/assets/images/home_top_left.webp)"></div>
        <div class="grid-item" style="background-image: url(/assets/images/home_top_right.webp)"></div>
        <div class="grid-item" style="background-image: url(/assets/images/home_bottom_left.webp)"></div>
        <div class="grid-item" style="background-image: url(/assets/images/home_bottom_right.webp)"></div>
      </div>
    </main>

    <footer class="no-wallet-footer">
      <span class="no-wallet-copyright">{{ 'NO-WALLET.COPYRIGHT' | translate }}</span>

      <a href="https://toggprodcdn.blob.core.windows.net/agreements/togg-kullanici-sozlesmesi.html" rel="noopener noreferrer" target="_blank">{{
        'NO-WALLET.TERMS-OF-USE' | translate
      }}</a>

      <a href="https://toggprodcdn.blob.core.windows.net/agreements/togg-aydinlatma-metni.html" rel="noopener noreferrer" target="_blank">{{
        'NO-WALLET.DISCLOSURE-TEXT' | translate
      }}</a>

      <a (click)="logout()">{{ 'NO-WALLET.LOGOUT' | translate }}</a>
    </footer>

    <div class="google_copyright">Google Play ve Google Play logosu Google LLC kuruluşunun ticari markalarıdır.</div>

    <div class="apple_copyright">App Store® is trademarks of Apple Inc., registered in the U.S. and other countries.</div>
  </div>
</div>
