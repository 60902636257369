import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthHelperService } from '../../services';
import { PageUrls } from '../../enums/page-urls.enum';
import { AsyncGuardFunction } from '../../declarations/guard';

export const requireAuthGuard: AsyncGuardFunction = async () => {
  const oAuthHelperService = inject(OAuthHelperService);
  const router = inject(Router);

  if (oAuthHelperService.isUserLoggedIn()) {
    return true;
  }

  return router.parseUrl(PageUrls.LOGIN);
};
