<div
  [ngClass]="{
    'image-with-fallback-and-loader-loading-or-failed': loadState === 0 || loadState === 2
  }"
  class="image-with-fallback-and-loader">
  <div *ngIf="loadState === 0" [class]="['spinner-8bit', loaderImageClass].join(' ')"></div>

  <div *ngIf="imgSrcFromDetail === 'detail'">
    <div *ngIf="loadState === 0" [class]="['spinner-8bit', loaderImageClass].join(' ')"></div>
    <div *ngIf="media === 'VIDEO'">
      <video (loadeddata)="onLoadVideo()" [hidden]="loading" [muted]="isVideoMuted" autoplay="autoplay" class="fallbackImageClass" id="video" itemref="video" loop playsinline>
        <source (error)="onSourceError()" [src]="imgSrc" type="video/mp4" />
      </video>
    </div>

    <img (load)="onLoad()" *ngIf="media === 'IMAGE'" [alt]="imgAlt" [class]="successImageClass" [hidden]="loadState !== 1" [src]="imgSrc" />
  </div>

  <div *ngIf="imgSrcFromDetail !== 'detail'">
    <img (load)="onLoad()" [alt]="imgAlt" [class]="successImageClass" [hidden]="loadState !== 1" src="{{ imgSrc }}" />
    <img *ngIf="loadState === 2" [class]="fallbackImageClass" [src]="fallbackImage" alt="fallback image" />
  </div>
</div>
