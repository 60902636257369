import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PageUrls } from '../../enums/page-urls.enum';
import { Subscription } from 'rxjs';

@Component({
  selector: 'togg-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss'],
})
export class ErrorPageComponent implements OnDestroy {
  isRetrying = false;
  private queryParamSubscription?: Subscription;

  constructor(private route: ActivatedRoute) {}

  retry() {
    if (this.isRetrying) {
      return;
    }

    this.isRetrying = true;

    this.queryParamSubscription = this.route.queryParams.subscribe(params => {
      const retryUrl = params['retry_url'];

      if (retryUrl) {
        window.location.href = retryUrl;
      } else {
        window.location.href = PageUrls.LOGIN;
      }
    });
  }

  ngOnDestroy(): void {
    this.queryParamSubscription?.unsubscribe();
  }
}
