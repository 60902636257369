import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuctionStoreService, CustomerStoreService, ModalsService, Nft, NftStoreService } from '../../services';
import { ModalScreenType } from '../../enums/modal-screen-type';
import { ModalId } from '../../enums/modal-id';
import { Subscription } from 'rxjs';
import { BidType } from '../../services/auction-store/auction-store.service.types';

@Component({
  selector: 'togg-bid-submission-modal',
  templateUrl: './bid-submission-modal.component.html',
  styleUrls: ['./bid-submission-modal.component.scss'],
})
export class BidSubmissionModalComponent implements OnInit, OnDestroy {
  nftItem: Nft | null = null;
  amIHighestBidder: boolean = false;
  errorMessage = '';
  errorParams: { minBid: number } | null = null;
  isBidInputValid = true;
  errorMaxPrice = false;
  availableBalance = 0;
  protected readonly ModalScreenType = ModalScreenType;
  protected readonly ModalId = ModalId;
  private selectedNftItemSubscription?: Subscription;
  private readonly customerDataObservable$;
  private customerDataSubscription?: Subscription;
  private auctionSubscription?: Subscription;
  private modalSubscription?: Subscription;

  constructor(
    private readonly nftStoreService: NftStoreService,
    private readonly customerService: CustomerStoreService,
    private readonly auctionService: AuctionStoreService,
    private readonly modalService: ModalsService,
  ) {
    this.customerDataObservable$ = this.customerService.getCustomerDataObservable();
  }

  _bidInputValue = '';

  get bidInputValue() {
    return this._bidInputValue;
  }

  set bidInputValue(value: string) {
    if (!this.nftItem) {
      return;
    }

    this.isBidInputValid = true;
    this.errorMaxPrice = false;
    this.errorMessage = '';

    let parsedValue = 0.0;

    if (value) {
      parsedValue = parseFloat(value.toString().replace(',', '.')) || 0.0;
    } else {
      this._bidInputValue = '';
      return;
    }

    if (this.availableBalance < parsedValue) {
      this.isBidInputValid = false;
      this.errorMaxPrice = false;
      this.errorMessage = 'BIDDING-PAGE-COMPONENT.ERROR-NOT-BALANCE';
    } else if (this.nftItem.price > parsedValue) {
      this.errorMaxPrice = false;
      this.isBidInputValid = false;
      this.errorMessage = 'BIDDING-PAGE-COMPONENT.ERROR-LOW';
    } else if (Math.round((this.nftItem.price + this.nftItem.minBid) * 10000) > Math.round(parsedValue * 10000) && this.nftItem.biddings !== 0) {
      this.errorMaxPrice = false;
      this.isBidInputValid = false;
      this.errorMessage = 'BIDDING-PAGE-COMPONENT.ERROR-MIN-BID';
      this.errorParams = { minBid: this.nftItem.minBid };
    } else if (parsedValue >= this.nftItem.maxPrice) {
      this.errorMaxPrice = true;
      this.isBidInputValid = false;
      this.errorMessage = 'BIDDING-PAGE-COMPONENT.ERROR-MAX-PRICE';
    }
    this._bidInputValue = value;
  }

  ngOnInit() {
    this.selectedNftItemSubscription = this.nftStoreService.selectedNftItemForDetailsPage$.subscribe({
      next: nft => {
        if (!nft) {
          return;
        }

        this.nftItem = nft;
        this._bidInputValue = '';
        this.initCustomerDataSubscription();
        this.initAuctionSubscription();
      },
    });

    this.modalSubscription = this.modalService.modalState$.subscribe(modalIds => this.modalStateChange(modalIds));
  }

  initCustomerDataSubscription() {
    if (this.customerDataSubscription) {
      return;
    }

    this.customerDataSubscription = this.customerDataObservable$.subscribe({
      next: data => {
        if (!data || this.nftItem?.price == null) {
          return;
        }

        this.availableBalance = parseFloat(data.balance?.avaxBalance?.availableAmount ?? '0');
      },
    });
  }

  initAuctionSubscription() {
    if (this.auctionSubscription) {
      return;
    }

    this.auctionSubscription = this.auctionService.bidHistoryObservable$.subscribe({
      next: bids => {
        const activeBid = bids.find(bid => bid.nft.nftId === this.nftItem?.nftId);
        if (!activeBid?.nft || !this.nftItem) {
          return;
        }

        this.amIHighestBidder = activeBid.nft.nftId === this.nftItem.nftId;
      },
    });
  }

  modalStateChange(modalIds: ModalId[]) {
    if (!modalIds.includes(ModalId.BID_SUBMISSION)) {
      this.bidInputValue = '';
    }
  }

  ngOnDestroy() {
    this.selectedNftItemSubscription?.unsubscribe();
    this.customerDataSubscription?.unsubscribe();
    this.auctionSubscription?.unsubscribe();
    this.modalSubscription?.unsubscribe();
  }

  openConfirmationModal() {
    this.auctionService.changeBidType(BidType.BID);
    this.auctionService.changeBidValue(parseFloat(this.bidInputValue));
    this.modalService.close(ModalId.BID_SUBMISSION);
    this.modalService.open(ModalId.BID_SUBMISSION_CONFIRMATION);
  }
}
