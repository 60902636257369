import { StatusesBidEnum } from '../../enums/statuses-bids.enum';
import { Nft } from '../nft-store/nft-store.service.types';

export enum BidType {
  BID = 'BID',
  DIRECT_BUY = 'DIRECT_BUY',
}

export interface NftOperation {
  nftOperationId?: string;
  nft?: Nft;
  type?: NftOperationType;
  date?: string;
  updateDate?: string;
  destinationWallet?: string;
  price?: number;
  initialPrice?: number;
  maxPrice?: number;
  finalPrice?: number;
  launchDate?: string;
  bidEndDate?: string;
  status?: StatusesBidEnum;
  descriptionError?: string;
  customerId?: string;
  fee?: number;
  highlighted?: boolean;
}

export enum NftOperationType {
  TRANSFER = 'TRANSFER',
  MINTING = 'MINTING',
  BURNING = 'BURNING',
  BIDDING = 'BIDDING',
  LISTING = 'LISTING',
  EDIT_LISTING = 'EDIT_LISTING',
  SELLING = 'SELLING',
  MINTING_LISTING = 'MINTING_LISTING',
  MINTING_SELLING = 'MINTING_SELLING',
  CANCEL_LISTING = 'CANCEL_LISTING',
  CLOSE_AUCTION = 'CLOSE_AUCTION',
  BUY = 'BUY',
  TRANSACTION = 'TRANSACTION',
  CANCEL_AUCTION = 'CANCEL_AUCTION',
  HIGHLIGHTING = 'HIGHLIGHTING',
  UNKNOWN_DEFAULT_OPEN_API = 'UNKNOWN_DEFAULT_OPEN_API',
}
