import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PageUrls } from 'src/app/enums/page-urls.enum';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { NftCollectionDto } from '@togg-trumore/toggens-operations-api-client';
import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs';

type BannerInfo = {
  imgSrc: string;
  title: string;
  subText: string;
  navigateUrl?: [string, string];
};

@Component({
  selector: 'togg-nfts-list-header',
  templateUrl: './nfts-list-header.component.html',
  styleUrls: ['./nfts-list-header.component.scss'],
})
export class NftsListHeaderComponent implements OnInit, OnDestroy {
  @Input() collections$!: Observable<NftCollectionDto[]>;
  ngOnDestroy$ = new Subject<void>();

  private bannersTr = [
    {
      imgSrc: '/assets/images/banner-1.png',
      title: "Magnet NFT'ler artık satışta!",
      subText: 'Satışta olan koleksiyonu incelemek için tıklayın',
    },
  ];

  private bannersEn = [
    {
      imgSrc: '/assets/images/banner-1.png',
      title: 'Magnet NFTs are now available for sale!',
      subText: 'Click to browse the collection.',
    },
  ];

  private get translatedBanners(): BannerInfo[] {
    const ret = this.translate.currentLang === 'tr' ? this.bannersTr : this.bannersEn;
    return ret;
  }

  banners$ = new BehaviorSubject<BannerInfo[]>(this.translatedBanners);

  constructor(
    private readonly translate: TranslateService,
    private readonly router: Router,
  ) {}

  ngOnInit(): void {
    this.collections$.pipe(takeUntil(this.ngOnDestroy$)).subscribe(collections => {
      const magnetCollection = collections.find(col => col.collectionId === environment.magnetCollectionId);
      if (!magnetCollection) {
        return;
      }
      this.banners$.next(
        this.translatedBanners.map(banner => ({
          ...banner,
          navigateUrl: [PageUrls.COLLECTION.replace('/:collection', ''), magnetCollection.address],
        })),
      );
    });
  }

  async navigateTo(url?: [string, string]) {
    if (!url) {
      return;
    }

    await this.router.navigate(url);
  }

  ngOnDestroy(): void {
    this.ngOnDestroy$.next();
    this.ngOnDestroy$.complete();
  }
}
