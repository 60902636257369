import { NgModule } from '@angular/core';
import * as OperationsApi from '@togg-trumore/toggens-operations-api-client';
import * as CustomersApi from '@togg-trumore/toggens-customers-api-client';
import { environment } from '../../../environments/environment';

export function OperationsApiConfigFactory(): OperationsApi.Configuration {
  const params: OperationsApi.ConfigurationParameters = {
    basePath: environment.baseToggMpApiUrl,
  };
  return new OperationsApi.Configuration(params);
}

export function CustomerApiConfigFactory(): CustomersApi.Configuration {
  const params: CustomersApi.ConfigurationParameters = {
    basePath: environment.baseToggMpApiUrl,
  };
  return new CustomersApi.Configuration(params);
}

@NgModule({
  imports: [OperationsApi.ApiModule.forRoot(OperationsApiConfigFactory), CustomersApi.ApiModule.forRoot(CustomerApiConfigFactory)],
  exports: [OperationsApi.ApiModule, CustomersApi.ApiModule],
})
export class ApiClientModule {}
