import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Nft, NftStatus, NftStoreService } from '../../services';
import { animate, AnimationEvent, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'togg-nft-list-item[nftItem]',
  templateUrl: './nft-list-item.component.html',
  styleUrls: ['./nft-list-item.component.scss'],
  animations: [
    trigger('fade', [
      state('in', style({ opacity: 1, transform: 'translateY(0)' })),
      state('out', style({ opacity: 0, transform: 'translateY(-50%)' })),
      transition('in => out', [animate('3s', style({ opacity: 1 })), animate('0.3s ease-in-out')]),
      transition('out => in', [animate('3s', style({ opacity: 0 })), animate('0.3s ease-in-out')]),
    ]),
  ],
})
export class NftListItemComponent implements OnChanges {
  @Input({
    required: true,
  })
  nftItem!: Nft;
  @Output() clicked = new EventEmitter<Nft>();
  @Input() isSold = false;
  @Input() multiselectMode = false;
  @Input() selectedCounter = 0;
  @Input() disabled = false;

  soldImageShow = false;

  public NftStatus = NftStatus;

  constructor(private readonly nftStoreService: NftStoreService) {}

  get sellClass() {
    if (this.nftItem.onSale) {
      if (this.nftItem.initialPrice === this.nftItem.maxPrice) {
        return 'fixed-price';
      }
      return 'auction';
    }

    if (!this.nftItem.transferable) {
      return 'nonTransferable';
    }

    if (this.nftItem.collectionPaused) {
      return 'paused';
    }
    return '';
  }

  get sellStateName() {
    if (this.nftItem.onSale) {
      if (this.nftItem.initialPrice === this.nftItem.maxPrice) {
        return 'Nfts-List-Page_Component.FIXED-PRICE-SALE';
      }
      return 'Nfts-List-Page_Component.ON-AUCTION';
    }

    if (!this.nftItem.transferable) {
      return 'Nfts-List-Page_Component.NON-TRANSFERABLE';
    }

    if (this.nftItem.collectionPaused) {
      return 'Nfts-List-Page_Component.PAUSED';
    }
    return '';
  }

  ngOnChanges(changes: SimpleChanges) {
    const isSoldValue = changes['isSold']?.currentValue;

    if (isSoldValue) {
      this.soldImageShow = true;
    }
  }

  onAnimationDone(event: AnimationEvent) {
    if (event.toState === 'out') {
      this.nftStoreService.removeFromNftItems(this.nftItem.nftId);
    }
  }

  goToDetails() {
    this.clicked.emit(this.nftItem);
  }
}
