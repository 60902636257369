import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { NftOperation } from '../../services/auction-store/auction-store.service.types';

@Component({
  selector: 'togg-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
})
export class AccordionComponent implements OnInit, OnChanges {
  @Input() nftOperation?: NftOperation | null;
  @Input() isDefaultOpen = false;
  @Input() closedExtraClass = '';
  @Input() openedExtraClass = '';
  @Output() openModal = new EventEmitter<void>();
  @Input() contentElement?: HTMLElement;
  @Input() titleText: string = '';

  isOpen = false;

  ngOnInit() {
    this.isOpen = this.isDefaultOpen;
  }

  ngOnChanges(changes: SimpleChanges) {
    const changedOpen = changes['isDefaultOpen'];
    if (changedOpen?.currentValue) {
      this.isOpen = true;
    }
  }

  toggleAccordion(): void {
    this.isOpen = !this.isOpen;

    if (this.isOpen) {
      this.openModal.emit();
    }
  }
}
