import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CustomerStoreService, Nft, NftStoreService } from '../../services';
import Swiper from 'swiper';
import { formatWalletAddress } from '../../utils/formatWalletAddress';
import { Subscription } from 'rxjs';
import { Offer } from './nft-details-swiper.component.types';
import { nftOperationsDtoToOfferMapper } from '../../mappers/nft-operations-dto-to-offer.mapper';
import { LoadingStatus } from '../../enums/loading-status.enum';
import { SwiperOptions } from 'swiper/types';

@Component({
  selector: 'togg-nft-details-swiper',
  templateUrl: './nft-details-swiper.component.html',
  styleUrls: ['./nft-details-swiper.component.scss'],
})
export class NftDetailsSwiperComponent implements OnInit, OnDestroy {
  @Input({ required: true }) nftItem!: Nft;
  @Input({ required: true }) auctionAboutToStart!: boolean;

  copied: boolean = false;
  copiedId: boolean = false;
  creations = '';
  nftCounter = '';
  indexOffers = 4;
  userInWhitelist: boolean = false;

  mappedNftOffers: Offer[] = [];

  swiper?: Swiper;
  @ViewChild('swiperRef', { static: true })
  protected _swiperRef: ElementRef | undefined;
  protected readonly formatWalletAddress = formatWalletAddress;
  protected readonly LoadingStatus = LoadingStatus;
  private readonly customerDataObservable$;
  private customerDataSubscription?: Subscription;
  private nftOffersSubscription?: Subscription;

  constructor(
    public readonly customerService: CustomerStoreService,
    public readonly nftStoreService: NftStoreService,
  ) {
    this.customerDataObservable$ = this.customerService.getCustomerDataObservable();
  }

  ngOnInit() {
    this.customerDataSubscription = this.customerDataObservable$.subscribe(data => {
      this.userInWhitelist = data?.whitelisting === 'WHITELISTED';
    });

    this.nftOffersSubscription = this.nftStoreService.selectedNftItemOffers$.subscribe(offers => {
      this.mappedNftOffers = offers.map(nftOperationsDtoToOfferMapper);
    });

    this._initSwiper();
  }

  ngOnDestroy() {
    this.customerDataSubscription?.unsubscribe();
    this.nftOffersSubscription?.unsubscribe();
  }

  public copyToClipboard() {
    if (this.copied) {
      return;
    }

    this.copiedId = false;
    this.copied = true;
    setTimeout(() => {
      this.copied = false;
    }, 3000);
  }

  public copyIdToClipboard() {
    if (this.copiedId) {
      return;
    }

    this.copied = false;
    this.copiedId = true;
    setTimeout(() => {
      this.copiedId = false;
    }, 3000);
  }

  private _initSwiper() {
    const options: SwiperOptions = {
      slidesPerView: 'auto',
      spaceBetween: 5,
      pagination: { dynamicBullets: true, clickable: true, bulletClass: 'swiper-pagination-bullet' },
      scrollbar: false,
      grabCursor: true,
      height: 400,
      breakpoints: {
        345: {
          slidesPerView: 1.25,
          slidesPerGroup: 1,
          enabled: true,
        },
        402: {
          slidesPerView: 1.4,
          slidesPerGroup: 1,
          enabled: true,
        },
        430: {
          slidesPerView: 1.5,
          slidesPerGroup: 1,
          enabled: true,
        },
        490: {
          slidesPerView: 1.6,
          slidesPerGroup: 1,
          enabled: true,
        },
        550: {
          slidesPerView: 1.8,
          slidesPerGroup: 1,
          enabled: true,
        },
        580: {
          slidesPerView: 2,
          slidesPerGroup: 2,
          enabled: true,
        },
        670: {
          slidesPerView: 2.2,
          slidesPerGroup: 2,
          enabled: true,
        },
        730: {
          slidesPerView: 2.5,
          slidesPerGroup: 2,
          enabled: true,
        },
        840: {
          slidesPerView: 2.7,
          slidesPerGroup: 2,
          enabled: true,
        },
        890: {
          slidesPerView: 3,
          slidesPerGroup: 3,
          enabled: false,
        },
      },
    };

    const swiperEl = this._swiperRef?.nativeElement;
    Object.assign(swiperEl, options);

    swiperEl.initialize();

    if (this.swiper) this.swiper.currentBreakpoint = false;
    this.swiper = this._swiperRef?.nativeElement.swiper;
  }
}
