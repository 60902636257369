import { Component } from '@angular/core';
import { ModalId } from 'src/app/enums/modal-id';
import { ModalScreenType } from 'src/app/enums/modal-screen-type';
import { AuctionStoreService, ModalsService } from 'src/app/services';

@Component({
  selector: 'togg-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent {
  protected readonly ModalType = ModalId;
  protected readonly ModalScreenType = ModalScreenType;

  constructor(
    protected readonly modalsService: ModalsService,
    protected readonly auctionStoreService: AuctionStoreService,
  ) {}

  onCloseButtonClickHandler() {
    this.modalsService.close(ModalId.CONFIRMATION);
  }
}
