import { Component, Input } from '@angular/core';

@Component({
  selector: 'togg-clipboard-button',
  templateUrl: './clipboard-button.component.html',
  styleUrls: ['./clipboard-button.component.scss'],
})
export class ClipboardButtonComponent {
  @Input() copyText!: string | undefined;
  copied: boolean = false;

  public copyToClipboard() {
    if (this.copied) {
      return;
    }

    this.copied = true;
    setTimeout(() => {
      this.copied = false;
    }, 3000);
  }
}
