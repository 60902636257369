export enum WebsocketMarketplaceMessageTopic {
  MARKETPLACE_BIDS = '/topic/toggens.marketplace.bids',
  WALLET_TRANSACTIONS = '/topic/toggens.wallet.transactions',
  WALLET_NFTS = '/topic/toggens.wallet.nfts',
  MARKETPLACE_OUTBIDS = '/topic/toggens.marketplace.outbids',
  WALLET_CUSTOMERS = '/topic/toggens.wallet.customers',
  WALLET_BALANCES = '/topic/toggens.wallet.balances',
  CUSTOMER_NFTS = '/topic/toggens.marketplace.customers.nfts',
}

export interface WebsocketMarketplaceMessageHeaders {
  'correlation-id': string;
  destination: string;
  timestamp: string;
  expires: string;
  'message-id': string;
  priority: string;
  subscription: string;
  type: string;
}

export interface WebsocketMarketplaceMessage<T> {
  command: string;
  headers: WebsocketMarketplaceMessageHeaders;
  isBinaryBody: boolean;
  skipContentLengthHeader: boolean;
  _binaryBody: never;
  escapeHeaderValues: boolean;
  _body: string;
  parsedBody: T;
}

export interface ReceivedBiddingMessage {
  nftOperationId: string;
  nft: NftInBiddingMessage;
  type: string;
  date: string;
  updateDate: string;
  destinationWallet: string;
  price: number;
  status: string;
  customerId: string;
  highlighted: boolean;
}

export interface ReceivedOutbiddingMessage extends ReceivedBiddingMessage {
  newBidderAddress: string;
  newBidderToggId: string;
  newPrice: number;
}

export interface NftInBiddingMessage {
  nftInternalId: string;
  ownerWallet: string;
  nftId: number;
  collectionAddress: string;
  collectionValidated: boolean;
  tokenUri: string;
  mediaTokenUri: string;
  mediaType: string;
  mediaFileUrl: string;
  thumbnailTokenUri: string;
  thumbnailFileUrl: string;
  rarity: string;
  name: string;
  creator: string;
  description: string;
  onSale: boolean;
  price: number;
  initialPrice: number;
  maxPrice: number;
  royaltyFee: number;
  bidEndDate: string;
  launchDate: string;
  listingId: number;
  biddings: number;
  highlighted: boolean;
  nftType: string;
  quantity: number;
  qrCodeUrl: string;
  status: string;
  operation: string;
}

export interface ReceivedTokenBalanceMessage {
  accountId: string;
  tokenBalances: TokenBalanceInTokenBalanceMessage[];
}

export interface TokenBalanceInTokenBalanceMessage {
  amount: number;
  name: string;
  symbol: string;
  address: string;
  date: string;
}

export interface ReceivedWalletCustomerMessage {
  toggId: string;
  name: string;
  walletId: string;
  affiliate: boolean;
  showWhitelisting: boolean;
  showConditions: boolean;
  creationDate: string;
  modificationDate: string;
  kycVerificationLevel: string;
  kycExpirationDate: string;
  locale: string;
  whitelisting: string;
}

export interface ReceivedWalletTransactionMessage {
  originWallet: string;
  originName: string;
  originToggId: string;
  destinationWallet: string;
  amount: string;
  tokenType: string;
  transactionHash: string;
  transactionId: string;
  creationTime: string;
  updateTime: string;
  status: string;
  fees: string;
  chainanalysysStatus: string;
  quarantine: boolean;
}
