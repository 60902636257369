<form [formGroup]="filterForm" class="flex flex-column border-round-lg bg-white z-1 p-3 gap-2">
  <span class="flex gap-2">
    <p-checkbox [binary]="true" formControlName="all" inputId="all" />
    <label for="all">{{ 'Nft-Filter.SELECT-ALL' | translate }}</label>
  </span>
  <span class="flex gap-2">
    <p-checkbox [binary]="true" formControlName="unlisted" inputId="unlisted" />
    <label for="unlisted">{{ 'Nft-Filter.UNLISTED' | translate }}</label>
  </span>
  <span class="flex gap-2">
    <p-checkbox [binary]="true" formControlName="fixedPrice" inputId="fixedPrice" />
    <label for="fixedPrice">{{ 'Nft-Filter.FIXED-PRICE' | translate }}</label>
  </span>
  <span class="flex gap-2">
    <p-checkbox [binary]="true" formControlName="onAuction" inputId="onAuction" />
    <label for="onAuction">{{ 'Nft-Filter.ON-AUCTION' | translate }}</label>
  </span>
  <span class="flex gap-2">
    <p-checkbox [binary]="true" formControlName="nonTransferable" inputId="nonTransferable" />
    <label for="nonTransferable">{{ 'Nft-Filter.NOT-TRANSFERABLE' | translate }}</label>
  </span>
</form>
