import { Component, Input, OnDestroy, OnInit } from '@angular/core';

interface TimeParts {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

@Component({
  selector: 'togg-countdown-timer',
  templateUrl: './countdown-timer.component.html',
  styleUrls: ['./countdown-timer.component.scss'],
})
export class CountdownTimerComponent implements OnInit, OnDestroy {
  @Input() finishDateString!: string | null;
  @Input() nftsList!: boolean;

  timer: ReturnType<typeof setTimeout> | null = null;

  time: TimeParts;
  timeoutRaised = false;

  constructor() {
    this.time = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  }

  ngOnInit(): void {
    this.updateTime();
    this.timer = setInterval(this.updateTime.bind(this), 1000);
  }

  ngOnDestroy(): void {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  }

  public getUpdateTime() {
    return this.updateTime();
  }

  private updateTime() {
    const now = new Date();
    const finishDate = new Date(this.finishDateString!);
    const diff = finishDate.getTime() - now.getTime();

    if (diff <= 0) {
      this.time = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
      if (!this.timeoutRaised) {
        this.timeoutRaised = true;
      }

      return;
    }

    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor(diff / (1000 * 60 * 60));
    const mins = Math.floor(diff / (1000 * 60));
    const secs = Math.floor(diff / 1000);

    this.time.days = days;
    this.time.hours = hours - days * 24;
    this.time.minutes = mins - hours * 60;
    this.time.seconds = secs - mins * 60;
  }
}
