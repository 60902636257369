export const environment = {
  appVersion: 'v1.41.1',
  production: false,
  languages: ['tr'],
  currentLang: 'tr',
  baseToggMpApiUrl: 'https://toggens-api.dev.togg.cloud',
  stsAuthority: 'https://toggid.development.togg.cloud/auth/realms/toggid',
  clientId: 'client_toggen_development',
  clientRoot: 'https://nftmarketplace.dev.togg.cloud/',
  logoutUrl: 'https://nftmarketplace.dev.togg.cloud/',
  silentRefreshUrl: 'https://nftmarketplace.dev.togg.cloud/silent-refresh.html',
  clientScope: 'openid',
  responseType: 'code',
  useSilentRefresh: true,
  socketUrl: 'wss://toggens-socket.dev.togg.cloud',
  googleAnalyticsMeasurementId: 'UA-251855724-1',
  countlyAppKey: 'c19e39b423d7f9a769d4045cb8c870c4cf659ca7',
  countlyUrl: 'https://trial-togg.count.ly',
  instanaReportingUrl: 'https://monitoring-instana.togg.cloud/eum/',
  instanaKey: '3YWYd7UCScComJH_Hlt_gA',
  instanaEumJsUrl: 'https://monitoring-instana.togg.cloud/eum/eum.min.js',
  magnetCollectionId: 'cab000a0-c0d0-00e0-b000-000a0b000f02',
};
