<swiper-container #swiperRef class="nft-swiper-container" init="false">
  <swiper-slide [className]="'swiper-slide-custom'">
    <div class="info-card">
      <h2 class="form-title-item">{{ 'INFO-CAROUSEL-COMPONENT.CONTRACT-ADDRESS' | translate }}</h2>
      <div class="form-info-item-wrapper">
        <p class="form-info-item hyperlink">{{ formatWalletAddress(nftItem.collectionAddress) }}</p>
        <togg-clipboard-button [copyText]="nftItem.collectionAddress" />
      </div>

      <div class="divider"></div>

      <h2 class="form-title-item">{{ 'INFO-CAROUSEL-COMPONENT.TOKEN-ID' | translate }}</h2>
      <div class="form-info-item-wrapper">
        <p class="form-info-item hyperlink">{{ nftItem.nftId }}</p>
        <togg-clipboard-button [copyText]="nftItem.nftId.toString()" />
      </div>

      <div class="divider"></div>

      <h2 class="form-title-item">{{ 'INFO-CAROUSEL-COMPONENT.BLOCKCHAIN' | translate }}</h2>
      <div class="form-info-item-wrapper">
        <p class="form-info-item">Avalanche C-Chain</p>
      </div>

      <div class="divider"></div>

      <h2 class="form-title-item">{{ 'INFO-CAROUSEL-COMPONENT.TOKEN-STANDARD' | translate }}</h2>
      <div class="form-info-item-wrapper">
        <p class="form-info-item">{{ nftItem.nftType | uppercase }}</p>
      </div>

      <!--<h2 class="form-title-item">{{ 'INFO-CAROUSEL-COMPONENT.ROYALTIES' | translate }}</h2>
                              <div class="form-info-item-wrapper">
                                <p class="form-info-item">{{ 'SHARED.PERCENTAGE-TR' | translate }}{{ nftItem.royaltyFee }}{{ 'SHARED.PERCENTAGE-EN' | translate }}</p>
                              </div>
                              -->
    </div>
  </swiper-slide>

  <swiper-slide [className]="'swiper-slide-custom'">
    <div class="info-card profiles">
      <togg-info-person [infoLabel]="nftItem.creator" [label]="'INFO-PERSON.CREATOR'" [numberOfCreation]="creations" [type]="'INFO-PERSON.CREATIONS'"></togg-info-person>
      <togg-info-person
        [infoLabel]="formatWalletAddress(nftItem.ownerWallet)"
        [label]="'INFO-PERSON.OWNER'"
        [numberOfCreation]="nftCounter"
        [type]="'INFO-PERSON.COLLECTED'"></togg-info-person>
      <div *ngIf="!!nftItem.rarity">
        <togg-rarity [rarity]="nftItem.rarity"></togg-rarity>
      </div>
    </div>
  </swiper-slide>

  <swiper-slide [className]="'swiper-slide-custom'">
    <ng-container *ngIf="nftStoreService.selectedNftItemOffersLoadingStatus$ | async as selectedNftItemOffersLoadingStatus">
      <div *ngIf="selectedNftItemOffersLoadingStatus === LoadingStatus.NOT_LOADED || selectedNftItemOffersLoadingStatus === LoadingStatus.LOADING" class="info-card">
        <div class="spinner-8bit"></div>
      </div>
      <ng-container *ngIf="mappedNftOffers && selectedNftItemOffersLoadingStatus === LoadingStatus.LOADED">
        <div *ngIf="mappedNftOffers.length > 0" class="info-card">
          <h2 class="form-info-item">{{ 'INFO-CAROUSEL-COMPONENT.BIDS' | translate }}</h2>

          <div *ngFor="let offer of mappedNftOffers | slice: 0 : indexOffers" class="offer-wrapper">
            <div class="offer-headers">
              <h2 class="offer-header">{{ 'INFO-CAROUSEL-COMPONENT.PRICE' | translate }}</h2>
              <h2 class="offer-header">{{ 'INFO-CAROUSEL-COMPONENT.FLOOR' | translate }}</h2>
              <h2 class="offer-header">{{ 'INFO-CAROUSEL-COMPONENT.FROM' | translate }}</h2>
              <h2 class="offer-header">{{ 'INFO-CAROUSEL-COMPONENT.STATUS' | translate }}</h2>
            </div>

            <div class="offer-details">
              <div class="offer-column">
                <div class="offer-price">
                  <img alt="avax-token" height="14" ngSrc="assets/images/AVAX-vividBlue.svg" width="15" />
                  {{ offer.price }}
                </div>
              </div>

              <div class="offer-column">
                {{ 'SHARED.PERCENTAGE-TR' | translate }}
                {{ offer.floor | number }}
                {{ 'SHARED.PERCENTAGE-EN' | translate }}
                {{ 'SHARED.ABOVE' | translate }}
              </div>

              <div class="offer-column">
                {{ offer.fromAddress }}
              </div>

              <div *ngIf="offer.status === 'RELEASED' || offer.status === 'BEATEN' || offer.status === 'WON'" class="offer-column">
                <img alt="avax-token" height="15" ngSrc="assets/images/success.svg" width="15" />
              </div>

              <div *ngIf="offer.status !== 'RELEASED' && offer.status !== 'BEATEN' && offer.status !== 'WON'" class="offer-column">
                <img alt="avax-token" height="15" ngSrc="assets/images/error-status.svg" width="15" />
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="mappedNftOffers.length === 0" class="info-card bidding-history-no-bid">
          <h2 class="form-info-item bids-title">{{ 'INFO-CAROUSEL-COMPONENT.BIDS' | translate }}</h2>
          <div class="no-bids-yet">
            <img alt="No bid" class="no-bid" height="64" ngSrc="/assets/images/bidding-nfts-empty.svg" width="64" />
            <div class="no-bid-text">
              {{ 'INFO-CAROUSEL-COMPONENT.NO-BIDS' | translate }}
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </swiper-slide>
</swiper-container>
