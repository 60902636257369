import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription, take } from 'rxjs';
import { AuctionStoreService, CountlyService, CustomerStoreService, ModalsService, Nft, NftStoreService } from '../../services';
import { ModalId } from '../../enums/modal-id';
import { WhitelistedEnum } from '../../enums/whitelisted.enum';
import { nftWhitelistingToWhitelistedMapper } from '../../mappers/nft-whitelisting-to-whitelisted.mapper';
import { BidType } from '../../services/auction-store/auction-store.service.types';
import { PageUrls } from 'src/app/enums/page-urls.enum';
import { Router } from '@angular/router';
import { NftsService } from '@togg-trumore/toggens-operations-api-client';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'togg-nft-details-page',
  templateUrl: './nft-details-page.component.html',
  styleUrls: ['./nft-details-page.component.scss'],
})
export class NftDetailsPageComponent implements OnInit, OnDestroy {
  protected WhitelistedEnum = WhitelistedEnum;
  headerType: 'expand_image' | 'only_close_icon' | 'play_video' = 'expand_image';
  nftItem: Nft | null = null;
  isShowingDetails = false;
  descriptionOpen = false;
  actionsActive = false;
  isWhitelistedLocked = false;

  userWhitelistingStatus?: WhitelistedEnum;
  auctionAboutToStart = false;
  auctionFinished = false;
  nftIsSold = false;

  amIHighestBidder: boolean = false;
  notEnoughBalanceToBuy: boolean = false;
  ownedNft = false;
  availableBalance = 0;
  activeBidValue?: number;
  auctionStartTimer?: ReturnType<typeof setTimeout>;
  auctionEndTimer?: ReturnType<typeof setTimeout>;
  private lastOfferStatus?: string;
  private selectedNftItemSubscription?: Subscription;
  private readonly customerDataObservable$;
  private customerDataSubscription?: Subscription;
  private auctionSubscription?: Subscription;
  private offersSubscription?: Subscription;
  private whitelistingSubscription?: Subscription;
  isModalLoading = false;
  isSellAllowed = false;

  constructor(
    private readonly nftStoreService: NftStoreService,
    private readonly nftsService: NftsService,
    private readonly modalsService: ModalsService,
    private readonly customerService: CustomerStoreService,
    private readonly auctionStoreService: AuctionStoreService,
    private readonly router: Router,
    private readonly translateSerivce: TranslateService,
    private readonly countlyService: CountlyService,
  ) {
    this.customerDataObservable$ = this.customerService.getCustomerDataObservable();
  }

  get shortDescription() {
    return this.nftItem?.description?.trim()?.slice(0, 87);
  }

  get showMoreDetailsButton() {
    const len = this.nftItem?.description?.trim()?.length;
    return len != null && len > 87;
  }

  ngOnInit() {
    this.selectedNftItemSubscription = this.nftStoreService.selectedNftItemForDetailsPage$.subscribe(nft => {
      if (!nft) {
        return;
      }

      this.initNftItem(nft);
      this.initializeAuctionAndMediaState();
      this.initCustomerDataSubscription();
      this.initAuctionSubscription();
      this.initOffersSubscription();
    });
  }

  ngOnDestroy() {
    this.selectedNftItemSubscription?.unsubscribe();
    this.customerDataSubscription?.unsubscribe();
    this.auctionSubscription?.unsubscribe();
    this.offersSubscription?.unsubscribe();
    this.whitelistingSubscription?.unsubscribe();
    clearInterval(this.auctionStartTimer);
    clearInterval(this.auctionEndTimer);
  }

  initNftItem(nft: Nft) {
    this.nftItem = nft;
  }

  updateActionsActiveStatus() {
    const isWhitelisted = this.userWhitelistingStatus === WhitelistedEnum.WHITELISTED;
    const isNotWhitelisted = this.userWhitelistingStatus === WhitelistedEnum.NOT_WHITELISTED;
    const auctionNotFinished = !this.auctionFinished;
    const launchDatePassed = new Date(this.nftItem!.launchDate) <= new Date();
    const isOnSale = this.nftItem!.onSale;
    const isWhitelistLocked = this.isWhitelistedLocked;

    if (isWhitelistLocked && isNotWhitelisted) {
      this.actionsActive = false;
    } else if ((isWhitelisted || isNotWhitelisted) && auctionNotFinished && launchDatePassed && isOnSale && !this.ownedNft) {
      this.actionsActive = true;
    } else {
      this.actionsActive = false;
    }

    this.isSellAllowed = isWhitelisted && auctionNotFinished && launchDatePassed && isOnSale && this.ownedNft;
  }

  initCustomerDataSubscription() {
    if (this.customerDataSubscription || !this.nftItem) {
      return;
    }

    this.customerDataSubscription = this.customerDataObservable$.subscribe(data => {
      if (data && data.whitelisting) {
        this.userWhitelistingStatus = nftWhitelistingToWhitelistedMapper(data.whitelisting);
        this.updateActionsActiveStatus();

        this.availableBalance = parseFloat(data.balance?.avaxBalance?.availableAmount ?? '0');
        this.notEnoughBalanceToBuy = this.availableBalance < (this.nftItem?.maxPrice ?? 0.01);

        this.ownedNft = data.walletId === this.nftItem?.ownerWallet;
      }
    });
  }

  initWhitelistingSubscription() {
    this.whitelistingSubscription = this.nftStoreService.isWhitelistingEnabled$.subscribe(data => {
      this.isWhitelistedLocked = !data;
    });
  }

  initAuctionSubscription() {
    if (this.auctionSubscription) {
      return;
    }

    this.auctionSubscription = this.auctionStoreService.bidHistoryObservable$.subscribe({
      next: bids => {
        this.updateActionsActiveStatus();

        const activeBid = bids.find(bid => bid.nft.nftId === this.nftItem?.nftId);
        this.activeBidValue = activeBid?.price;
        if (!activeBid?.nft || !this.nftItem) {
          this.amIHighestBidder = false;
          return;
        }

        this.amIHighestBidder = activeBid.nft.nftId === this.nftItem.nftId;
      },
    });
  }

  initOffersSubscription() {
    if (this.offersSubscription) {
      return;
    }

    this.offersSubscription = this.nftStoreService.selectedNftItemOffers$.subscribe({
      next: offers => {
        if (!offers) {
          this.nftIsSold = false;
          return;
        }

        if (offers.length > 0) {
          this.lastOfferStatus = offers[0].status;
          this.nftIsSold = offers[0].status === 'WON';

          if (this.nftItem?.bidEndDate && new Date(this.nftItem.bidEndDate) <= new Date()) {
            this.nftIsSold = offers[0].status === 'WON' || offers[0].status === 'RELEASED' || offers[0].status === 'BEATEN';
          }
        }

        if (this.nftIsSold) {
          this.actionsActive = false;
        }
      },
    });
  }

  async handleCommonChecks() {
    if (this.auctionAboutToStart) {
      this.auctionSoon();
      return false;
    }

    if (this.userWhitelistingStatus === WhitelistedEnum.NOT_WHITELISTED && this.isWhitelistedLocked) {
      this.modalsService.open(ModalId.WHITELISTING_PAUSED);
      return false;
    }

    if (!this.actionsActive || !this.nftItem || this.checkAuctionFinished(this.nftItem)) {
      return false;
    }

    if (this.userWhitelistingStatus === WhitelistedEnum.NOT_WHITELISTED) {
      this.modalsService.open(ModalId.BECOME_WHITELISTED);
      return false;
    } else if (this.userWhitelistingStatus !== WhitelistedEnum.WHITELISTED) {
      return false;
    }

    return true;
  }

  async handleBid() {
    if (!(await this.handleCommonChecks())) return;

    if (this.nftItem && this.actionsActive) {
      this.modalsService.open(ModalId.BID_SUBMISSION);
    }
  }

  async handleBuyNow() {
    if (!(await this.handleCommonChecks())) return;

    let computedNotEnoughBalanceToBuy = this.notEnoughBalanceToBuy;
    if (this.amIHighestBidder && this.activeBidValue && this.nftItem) {
      computedNotEnoughBalanceToBuy = this.availableBalance + this.activeBidValue < this.nftItem.maxPrice;
    }

    if (computedNotEnoughBalanceToBuy) {
      this.modalsService.open(ModalId.NOT_ENOUGH_BALANCE);
      return;
    }

    if (this.nftItem && this.actionsActive) {
      this.auctionStoreService.changeBidType(BidType.DIRECT_BUY);
      this.auctionStoreService.changeBidValue(this.nftItem.maxPrice);
      this.modalsService.open(ModalId.BID_SUBMISSION_CONFIRMATION);
    }
  }

  public descriptionToggle() {
    this.descriptionOpen = !this.descriptionOpen;
  }

  closeDetails() {
    this.isShowingDetails = false;
  }

  expandButtonClicked() {
    this.isShowingDetails = true;
  }

  checkAuctionFinished(nftItem: Nft) {
    if (nftItem.status === 'WON') {
      this.actionsActive = false;
      this.auctionFinished = true;
    } else {
      this.auctionFinished = new Date() > new Date(nftItem.bidEndDate);
    }

    return this.auctionFinished;
  }

  async sellNft() {
    if (this.userWhitelistingStatus === WhitelistedEnum.NOT_WHITELISTED) {
      this.modalsService.open(ModalId.BECOME_WHITELISTED);
      return;
    }
    this.countlyService.trackEventWithSegmentation({
      key: 'sell nft from details clicked',
    });
    await this.router.navigate([PageUrls.SELL_NFT], { state: { selectedNfts: [this.nftItem] } });
  }

  cancelListingClicked() {
    if (!this.nftItem) {
      return;
    }
    this.countlyService.trackEventWithSegmentation({
      key: 'delisting nft clicked',
    });
    this.isModalLoading = true;
    this.nftsService
      .estimateCancelAuction({
        cancelAuctionsList: [
          {
            collectionAddress: this.nftItem.collectionAddress,
            nftId: this.nftItem.nftId,
            listingId: this.nftItem.listingId,
          },
        ],
      })
      .pipe(take(1))
      .subscribe({
        next: value => {
          this.isModalLoading = false;
          this.auctionStoreService.setConfirmation({
            type: 'warning',
            header: this.translateSerivce.instant('BIDDING-PAGE-COMPONENT.CANCEL-AUCTION-HEADER'),
            text: this.translateSerivce.instant('BIDDING-PAGE-COMPONENT.CANCEL-AUCTION-TEXT', { gasFee: value.toFixed(4) }),
            actionHandler: this.cancelAuction.bind(this),
            showClose: true,
          });
          this.modalsService.open(ModalId.CONFIRMATION);
        },
        error: () => {
          this.isModalLoading = false;
          this.auctionStoreService.setError(this.translateSerivce.instant('BIDDING-PAGE-COMPONENT.TRY-LATER'));
          this.modalsService.open(ModalId.ERROR);
        },
      });
  }

  cancelAuction() {
    if (!this.nftItem) {
      return;
    }
    this.countlyService.trackEventWithSegmentation({
      key: 'delisting nft succeeded',
    });
    this.nftsService
      .cancelAuction({
        cancelAuctionsList: [
          {
            collectionAddress: this.nftItem.collectionAddress,
            nftId: this.nftItem.nftId,
            listingId: this.nftItem.listingId,
          },
        ],
      })
      .pipe(take(1))
      .subscribe({
        next: async () => {
          await this.router.navigate([PageUrls.MY_NFTS]);
          this.auctionStoreService.setConfirmation({
            type: 'info',
            header: this.translateSerivce.instant('CONFIRMATION-MODAL.DELIISTING-SUCCEEDED'),
            text: this.translateSerivce.instant('CONFIRMATION-MODAL.DELIISTING-SUCCEEDED-TEXT'),
            showClose: false,
            actionHandler: async () => {
              this.modalsService.close(ModalId.CONFIRMATION);
              await this.router.navigate([PageUrls.MY_NFTS]);
            },
            confirmBtnText: this.translateSerivce.instant('AUCTIONS.PROFILE-PAGE'),
          });
          this.modalsService.open(ModalId.CONFIRMATION);
        },
        error: () => {
          this.auctionStoreService.setConfirmation({
            type: 'error',
            header: this.translateSerivce.instant('CONFIRMATION-MODAL.DELIISTING-FAILED'),
            text: this.translateSerivce.instant('CONFIRMATION-MODAL.DELIISTING-FAILED-TEXT'),
            showClose: false,
            actionHandler: () => this.modalsService.close(ModalId.CONFIRMATION),
            confirmBtnText: this.translateSerivce.instant('CONFIRMATION-MODAL.CLOSE'),
          });
          this.modalsService.open(ModalId.CONFIRMATION);
        },
      });
  }

  private initializeAuctionAndMediaState() {
    if (!this.nftItem) {
      return;
    }

    this.checkAuctionFinished(this.nftItem);

    if (new Date(this.nftItem.launchDate) > new Date()) {
      this.auctionAboutToStart = true;
      this.auctionSoon();
      this.handleAuctionStartTimer();
    }

    if (!this.auctionFinished) {
      this.handleAuctionEndTimer();
    }

    if (this.nftItem.mediaType === 'VIDEO') {
      this.headerType = 'play_video';
    } else {
      this.headerType = 'expand_image';
    }
  }

  private handleAuctionStart() {
    this.auctionAboutToStart = false;
    this.updateActionsActiveStatus();
    clearInterval(this.auctionStartTimer);
  }

  private handleAuctionEnd() {
    this.auctionFinished = true;
    this.actionsActive = false;

    if (this.nftItem?.bidEndDate && new Date(this.nftItem.bidEndDate) <= new Date()) {
      this.nftIsSold = this.lastOfferStatus === 'WON' || this.lastOfferStatus === 'RELEASED' || this.lastOfferStatus === 'BEATEN';
    }

    clearInterval(this.auctionEndTimer);
  }

  private handleAuctionStartTimer() {
    this.auctionStartTimer = setInterval(() => {
      if (new Date(this.nftItem!.launchDate) <= new Date()) {
        this.handleAuctionStart();
      }
    }, 5000);
  }

  private handleAuctionEndTimer() {
    this.auctionEndTimer = setInterval(() => {
      if (this.nftItem?.bidEndDate && new Date(this.nftItem.bidEndDate) <= new Date()) {
        this.handleAuctionEnd();
      }
    }, 2000);
  }

  private auctionSoon() {
    this.modalsService.open(ModalId.PRESALE);
  }
}
