<div *ngIf="!hideBanner" class="nft-list-banner-wrapper my-3">
  <ng-container [ngSwitch]="whitelisting">
    <ng-container *ngSwitchCase="whitelistedEnum.NOT_WHITELISTED">
      <div (click)="openWhitelistModal()" *ngIf="!isWhitelistedLocked" class="nft-list-banner clickable">
        <div class="nft-list-banner-avax-icon-area1">
          <img alt="info-banner" class="nfts-icon" height="60" ngSrc="assets/images/nft-icon1.png" width="60" />
        </div>
        <div class="nft-list-banner-text">
          <span class="nft-list-banner-title">{{ 'BANNER.TITLE-NOT-WHITELISTED' | translate }}</span>
          <span class="nft-list-banner-description">{{ 'BANNER.DESCRIP-NOT-WHITELISTED' | translate }} {{ 'BANNER.DESCRIP-NOT-WHITE-CLICK' | translate }}</span>
        </div>
        <div class="nft-list-banner-avax-icon-area">
          <img alt="chevron" class="nfts-list-footer-avax-icon chevron" height="14" ngSrc="/assets/images/chevron-right.svg" width="9" />
        </div>
      </div>

      <div *ngIf="isWhitelistedLocked" class="nft-list-banner locked">
        <div class="nft-list-banner-avax-icon-area">
          <div class="nfts-list-footer-avax-icon">
            <img alt="info-alert" class="nfts-list-footer-avax-icon" height="24" ngSrc="assets/images/alert-triangle-warning.svg" width="24" />
          </div>
        </div>
        <div class="nft-list-banner-text">
          <span class="nft-list-banner-title">{{ 'BANNER.TITLE-LOCKED' | translate }}</span>
          <span class="nft-list-banner-description">{{ 'BANNER.DESCRIP-LOCKED' | translate }} {{ 'BANNER.DESCRIP-LOCKED-DOWN' | translate }}</span>
        </div>
      </div>
    </ng-container>

    <div *ngSwitchCase="whitelistedEnum.IN_PROGRESS" class="nft-list-banner progress">
      <div class="nft-list-banner-avax-icon-area">
        <togg-image-with-fallback-and-loader [imgAlt]="'loading...'" [loaderColor]="'#222222'" [successImageClass]="'ntf-list-item-image'"></togg-image-with-fallback-and-loader>
      </div>
      <div class="nft-list-banner-text">
        <span class="nft-list-banner-title">{{ 'BANNER.TITLE-PROGRESS' | translate }}</span>
        <span class="nft-list-banner-description">{{ 'BANNER.DESCRIP-PROGRESS' | translate }}</span>
      </div>
    </div>

    <div *ngSwitchCase="whitelistedEnum.TRANSFER_ERROR" class="nft-list-banner error">
      <div class="nft-list-banner-avax-icon-area">
        <img alt="info-alert" class="nfts-list-footer-avax-icon" height="24" ngSrc="assets/images/alert-triangle.svg" width="24" />
      </div>
      <div class="nft-list-banner-text">
        <span class="nft-list-banner-title">{{ 'BANNER.TITLE-ERROR' | translate }}</span>
        <span class="nft-list-banner-description">{{ 'BANNER.DESCRIP-ERROR' | translate }} {{ 'BANNER.DESCRIP-ERROR-DOWN' | translate }}</span>
      </div>
      <div class="nft-list-banner-avax-icon-area">
        <img (click)="tryAgainWhitelist()" alt="try again" class="nfts-list-footer-avax-icon chevron" height="24" ngSrc="/assets/images/try-again-danger.svg" width="24" />
      </div>
    </div>

    <div *ngSwitchCase="whitelistedEnum.REGISTER_ERROR" class="nft-list-banner error">
      <div class="nft-list-banner-avax-icon-area">
        <img alt="info-alert" class="nfts-list-footer-avax-icon" height="24" ngSrc="assets/images/alert-triangle.svg" width="24" />
      </div>
      <div class="nft-list-banner-text">
        <span class="nft-list-banner-title">{{ 'BANNER.TITLE-ERROR' | translate }}</span>
        <span class="nft-list-banner-description">{{ 'BANNER.DESCRIP-ERROR' | translate }} {{ 'BANNER.DESCRIP-ERROR-DOWN' | translate }}</span>
      </div>
      <div class="nft-list-banner-avax-icon-area">
        <img (click)="tryAgainWhitelist()" alt="try again" class="nfts-list-footer-avax-icon chevron" height="24" ngSrc="/assets/images/try-again.svg" width="24" />
      </div>
    </div>

    <div *ngSwitchCase="whitelistedEnum.BLOCKED" class="nft-list-banner blocked">
      <div class="nft-list-banner-avax-icon-area">
        <img alt="info-alert" class="nfts-list-footer-avax-icon" height="22" ngSrc="assets/images/blocked.svg" width="22" />
      </div>
      <div class="nft-list-banner-text">
        <span class="nft-list-banner-title">{{ 'BANNER.TITLE-BLOCKED' | translate }}</span>
        <span class="nft-list-banner-description">{{ 'BANNER.DESCRIP-BLOCKED' | translate }} {{ 'BANNER.DESCRIP-BLOCKED-DOWN' | translate }}</span>
      </div>
    </div>

    <ng-container>
      <div *ngSwitchCase="whitelistedEnum.WHITELISTED" class="nft-list-banner whitelisted">
        <div class="nft-list-banner-avax-icon-area">
          <img alt="info-progress" class="nfts-list-footer-avax-icon" height="24" ngSrc="assets/images/check-success.svg" width="24" />
        </div>
        <div class="nft-list-banner-text">
          <span class="nft-list-banner-title">{{ 'BANNER.TITLE-WHITELISTED' | translate }}</span>
          <span class="nft-list-banner-description">{{ 'BANNER.DESCRIP-WHITELISTED' | translate }}</span>
        </div>
        <button (click)="closeBanner()" class="whitelist-modal-drawer-close-button" type="button">
          <img alt="Close" height="24" ngSrc="/assets/images/close_icon_green.svg" width="24" />
        </button>
      </div>
    </ng-container>
  </ng-container>
</div>
