<togg-base-modal [modalId]="ModalId.ERROR" [showCloseButton]="true" *ngIf="auctionStoreService.errorMessage$ | async as errorMessage">
  <div bodyContent class="modal-error">
    <div class="modal-content">
      <div class="error-image-wrapper">
        <img alt="Error icon" height="48" ngSrc="/assets/images/error-icon.svg" width="48" />

        <h4 class="header">{{ errorMessage.header ?? ('CONFIRMATION-MODAL.GENERIC-ERROR' | translate) }}</h4>
      </div>

      <p class="font-16">{{ errorMessage.text }}</p>
    </div>
  </div>
  <div class="error-footer-content" footerContent>
    <button (click)="close()" class="btn btn-primary" type="button">{{ 'CONFIRMATION-MODAL.CLOSE' | translate }}</button>
  </div>
</togg-base-modal>
