import { Component } from '@angular/core';
import { OAuthHelperService } from '../../services';

@Component({
  selector: 'togg-wallet-not-exists-page',
  templateUrl: './wallet-not-exists-page.component.html',
  styleUrls: ['./wallet-not-exists-page.component.scss'],
})
export class WalletNotExistsPageComponent {
  constructor(private readonly oAuthHelperService: OAuthHelperService) {}

  logout() {
    this.oAuthHelperService.logout();
  }
}
