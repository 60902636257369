import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { PageUrls } from '../../enums/page-urls.enum';
import { CustomerStoreService } from '../../services';
import { AsyncGuardFunction } from '../../declarations/guard';
import { ApiError } from '../../declarations/error';

export const walletAndIntroductionMissingRedirectGuard: AsyncGuardFunction = async () => {
  const router = inject(Router);
  const customerService = inject(CustomerStoreService);

  try {
    const customerData = await customerService.loadCustomerData();

    // console.log('customerData', customerData);

    if (customerData.showConditions) {
      return router.parseUrl(PageUrls.INTRODUCTION);
    }
  } catch (err: unknown) {
    if (typeof err === 'object' && err != null) {
      const errorObj = err as ApiError;

      if (errorObj.status === 404 && errorObj.error?.code === '1300') {
        console.warn('Wallet not exists', errorObj.url, errorObj.statusText);
        return router.parseUrl(PageUrls.WALLET_NOT_EXISTS);
      }
    }

    return router.parseUrl(PageUrls.ERROR);
  }

  return true;
};
