import { inject } from '@angular/core';
import { SocketService } from '../../services';
import { AsyncGuardFunction } from '../../declarations/guard';

export const DisconnectSocketGuard: AsyncGuardFunction = async () => {
  const socketService = inject(SocketService);
  socketService.disconnectIfAlreadyConnected();

  return true;
};
