import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuctionStoreService, CountlyService, CustomerStoreService, ModalsService, Nft, NftStoreService } from '../../services';
import { WhitelistedEnum } from '../../enums/whitelisted.enum';
import { LoadingStatus } from '../../enums/loading-status.enum';
import { ModalId } from '../../enums/modal-id';
import { PageUrls } from '../../enums/page-urls.enum';
import { Subscription } from 'rxjs';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { nftOperationDtoToNftOperationMapper } from '../../mappers/nft-operations-dto-to-nft-operations.mapper';
import { nftWhitelistingToWhitelistedMapper } from '../../mappers/nft-whitelisting-to-whitelisted.mapper';

@Component({
  selector: 'togg-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent implements OnInit, OnDestroy {
  whitelisting?: WhitelistedEnum;
  isMobile = true;
  protected readonly NftLoadingStatus = LoadingStatus;
  protected readonly nftOperationDtoToNftOperationMapper = nftOperationDtoToNftOperationMapper;
  protected readonly LoadingStatus = LoadingStatus;
  private breakpointSubscription?: Subscription;
  private customersSubscription?: Subscription;
  private whitelistingSubscription?: Subscription;
  private readonly customerDataObservable$;
  private isWhitelistedLocked = false;

  constructor(
    private readonly router: Router,
    public readonly nftStoreService: NftStoreService,
    public readonly auctionStoreService: AuctionStoreService,
    private readonly countlyService: CountlyService,
    private readonly modalsService: ModalsService,
    private readonly breakpointObserver: BreakpointObserver,
    private readonly customerService: CustomerStoreService,
  ) {
    this.customerDataObservable$ = this.customerService.getCustomerDataObservable();
  }

  public collectionsResponsiveOptions = [
    {
      breakpoint: '1370px',
      numVisible: 4,
      numScroll: 3,
    },
    {
      breakpoint: '1060px',
      numVisible: 3,
      numScroll: 2,
    },
    {
      breakpoint: '750px',
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: '440px',
      numVisible: 1,
      numScroll: 1,
    },
  ];

  ngOnInit() {
    this.nftStoreService.loadCollections();
    this.breakpointSubscription = this.breakpointObserver.observe(['(min-width: 768px)']).subscribe((result: BreakpointState) => {
      if (result.matches) {
        this.isMobile = false;
      }
    });

    this.customersSubscription = this.customerDataObservable$.subscribe(data => {
      if (!data?.whitelisting) {
        return;
      }

      this.whitelisting = nftWhitelistingToWhitelistedMapper(data.whitelisting);
    });

    this.whitelistingSubscription = this.nftStoreService.isWhitelistingEnabled$.subscribe(data => {
      this.isWhitelistedLocked = !data;
    });
  }

  ngOnDestroy() {
    this.customersSubscription?.unsubscribe();
    this.breakpointSubscription?.unsubscribe();
    this.whitelistingSubscription?.unsubscribe();
  }

  async navigateToNftDetails($event: Nft) {
    if (this.whitelisting === WhitelistedEnum.NOT_WHITELISTED && this.isWhitelistedLocked) {
      return;
    }

    if (this.whitelisting === WhitelistedEnum.NOT_WHITELISTED) {
      this.modalsService.open(ModalId.BECOME_WHITELISTED);
      return;
    }

    this.nftStoreService.setSelectedNftItemForDetailsPage($event);
    const navigateUrl = [PageUrls.NFT_DETAILS.replace('/:collection', '').replace('/:id', ''), $event.collectionAddress, $event.nftId];
    await this.router.navigate(navigateUrl);
  }

  sendCountlyNftViewEvent(nftName: string) {
    this.countlyService.trackEventWithSegmentation({
      key: 'nftViews',
      segmentation: {
        id: nftName,
      },
    });
  }

  async collectionClicked(collectionAddress: string) {
    this.countlyService.trackEventWithSegmentation({
      key: 'collection card clicked',
    });
    const navigateUrl = [PageUrls.COLLECTION.replace('/:collection', ''), collectionAddress];
    await this.router.navigate(navigateUrl);
  }
}
