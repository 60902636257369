<togg-base-modal
  *ngIf="auctionStoreService.confirmationMessage$ | async as confirmationMessage"
  [modalId]="ModalType.CONFIRMATION"
  [showCloseButton]="confirmationMessage.showClose"
  [screenType]="ModalScreenType.NORMAL"
  [disableBackdropClick]="!confirmationMessage.showClose">
  <div bodyContent class="confirm-modal-body-content" [ngClass]="{ 'pt-5': !confirmationMessage.showClose }">
    <ng-container [ngSwitch]="confirmationMessage.type">
      <img *ngSwitchCase="'info'" alt="info" height="32" ngSrc="assets/images/confirmed.svg" width="32" />
      <img *ngSwitchCase="'warning'" alt="info" height="32" ngSrc="assets/images/alert-triangle.svg" width="32" />
      <img *ngSwitchCase="'error'" alt="info" height="32" ngSrc="assets/images/error-status.svg" width="32" />
    </ng-container>
    <div class="flex flex-column gap-3 pt-2 pb-4">
      <span class="block font-20">{{ confirmationMessage.header }}</span>
      <span class="block font-16">{{ confirmationMessage.text }}</span>
    </div>
  </div>
  <div class="confirm-modal-footer-content" footerContent>
    <button
      [ngClass]="{ warning: confirmationMessage.type === 'warning' }"
      *ngIf="confirmationMessage.actionHandler"
      (click)="confirmationMessage.actionHandler(); modalsService.close(ModalType.CONFIRMATION)"
      class="btn btn-primary"
      type="button">
      {{ confirmationMessage.confirmBtnText ?? 'CONFIRMATION-MODAL.CONFIRM' | translate }}
    </button>
    <button *ngIf="confirmationMessage.showClose" (click)="onCloseButtonClickHandler()" class="btn btn-secondary" type="button">
      {{ confirmationMessage.cancelBtnText ?? 'CONFIRMATION-MODAL.CANCEL' | translate }}
    </button>
  </div>
</togg-base-modal>
