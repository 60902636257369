import { NgModule } from '@angular/core';
import { provideToastr, ToastrModule } from 'ngx-toastr';

@NgModule({
  declarations: [],
  imports: [ToastrModule.forRoot()],
  exports: [ToastrModule],
  providers: [provideToastr()],
})
export class ToastModule {}
