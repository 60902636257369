import { Component, Input, OnInit } from '@angular/core';
import { Nft } from '../../services';

@Component({
  selector: 'togg-bid-card',
  templateUrl: './bid-card.component.html',
  styleUrls: ['./bid-card.component.scss'],
})
export class BidCardComponent implements OnInit {
  @Input() color!: string;
  @Input() nftItem!: Nft;
  @Input() highestBidFromUser!: boolean;

  bidMsg!: string;
  startingBid: boolean | undefined;

  ngOnInit(): void {
    this.historicBid();
  }

  private historicBid() {
    if (this.nftItem.biddings <= 0) {
      this.bidMsg = 'BID-CARD-COMPONENT.STARTING-BID';
      this.startingBid = true;
    } else {
      this.bidMsg = 'BID-CARD-COMPONENT.HIGHEST-BID';
      this.startingBid = false;
    }
  }
}
