import { Injectable } from '@angular/core';
import { OAuthEvent, OAuthService, OAuthSuccessEvent } from 'angular-oauth2-oidc';
import { InitializeResult } from './o-auth-helper.service.types';
import { JwtClaims } from '../../declarations/jwtClaims';
import { environment } from '../../../environments/environment';

export const INTRO_PAGE_SEEN = 'SEEN_INTRO_PAGE';

@Injectable({
  providedIn: 'root',
})
export class OAuthHelperService {
  private isInitializeCalled = false;

  constructor(private readonly oAuthService: OAuthService) {}

  public get OAuthService(): OAuthService {
    return this.oAuthService;
  }

  public async loadDiscoveryDocument(retryCount = 0): Promise<boolean> {
    if (retryCount > 3) {
      return false;
    }

    const documentLoadChecker = new Promise<void>((resolve, reject) => {
      let timerId: ReturnType<typeof setTimeout> | null = null;

      const documentLoadSubscription = this.oAuthService.events.subscribe((event: OAuthEvent) => {
        // console.log(event);
        if (event instanceof OAuthSuccessEvent && event.type === 'discovery_document_loaded' && event.info != null) {
          if (timerId) {
            clearTimeout(timerId);
          }

          documentLoadSubscription.unsubscribe();
          resolve();
        }
      });

      timerId = setTimeout(() => {
        documentLoadSubscription.unsubscribe();
        reject();
      }, 2500);
    });

    this.oAuthService.loadDiscoveryDocument();

    try {
      await documentLoadChecker;
    } catch (e) {
      return new Promise(resolve => setTimeout(() => this.loadDiscoveryDocument(retryCount + 1).then(resolve), 500));
    }

    return true;
  }

  public async initialize(): Promise<InitializeResult> {
    if (this.isInitializeCalled) {
      return InitializeResult.ALREADY_INITIALIZED;
    }

    this.isInitializeCalled = true;

    if (!this.oAuthService.discoveryDocumentLoaded) {
      const discoveryLoadResult = await this.loadDiscoveryDocument();

      if (!discoveryLoadResult) {
        return InitializeResult.DISCOVERY_DOCUMENT_LOAD_ERROR;
      }
    }

    let loginResult;

    try {
      loginResult = await this.oAuthService.tryLogin();
    } catch (e) {
      loginResult = false;
    }

    if (loginResult && this.isUserLoggedIn()) {
      this.oAuthService.setupAutomaticSilentRefresh();
    }

    return InitializeResult.FINISHED;
  }

  public getTruId(): string {
    const claims = this.oAuthService.getIdentityClaims() as JwtClaims | undefined;

    if (!claims?.sub) {
      return '';
    }

    return claims.sub;
  }

  public getFullName(): string {
    const claims = this.oAuthService.getIdentityClaims() as JwtClaims | undefined;

    if (!claims?.given_name) {
      return '';
    }

    return claims.given_name;
  }

  public isUserLoggedIn(): boolean {
    return this.oAuthService.hasValidAccessToken() && this.oAuthService.getAccessTokenExpiration() > Date.now();
  }

  public startLoginFlow(): void {
    this.oAuthService.initImplicitFlow();
  }

  public logout(): void {
    this.oAuthService
      .revokeTokenAndLogout(
        {
          client_id: environment.clientId,
        },
        true,
      )
      .then(() => {
        localStorage?.clear();
      })
      .catch(() => {
        this.oAuthService.logOut();
      });
  }
}
