<div *ngIf="color === 'dark'" class="bid-card back-dark">
  <p class="title mt-2 light">{{ bidMsg | translate }}</p>
  <div class="row amount light">
    <div class="col amount light">
      <p>
        <img *ngIf="startingBid" [src]="'assets/images/AVAX-white.svg'" alt="avax-token" class="img-size" />
        <img *ngIf="!startingBid" [src]="'assets/images/AVAX-vividBlue.svg'" alt="avax-token" class="img-size" />
        {{ nftItem.price }}
        <img *ngIf="!startingBid && highestBidFromUser" [src]="'assets/images/Trophy.svg'" alt="avax-token" class="img-size" />
      </p>
    </div>
  </div>
</div>
