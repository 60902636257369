<togg-base-modal [modalId]="ModalId.BID_SUBMISSION_CONFIRMATION" [screenType]="ModalScreenType.NORMAL" [showCloseButton]="true">
  <ng-container *ngIf="nftItem" headerContent>
    <h4 *ngIf="!isSuccess && !isFailed && !isItDirectBuy" class="confirmation-drawer-title">
      {{ 'CONFIRMATION-MODAL.TITLE' | translate }}
    </h4>

    <h4 *ngIf="!isSuccess && !isFailed && isItDirectBuy" class="confirmation-drawer-title">
      {{ 'CONFIRMATION-MODAL.TITLE-BUY' | translate }}
    </h4>
  </ng-container>
  <ng-container *ngIf="nftItem" bodyContent>
    <div [ngClass]="{ success: isSuccess, failed: isFailed }" class="confirmation-drawer">
      <div class="confirmation-drawer-content">
        <h6 *ngIf="!isItDirectBuy" class="section-title">
          {{ 'CONFIRMATION-MODAL.BIDDED-AMOUNT' | translate }}
        </h6>
        <h6 *ngIf="isItDirectBuy" class="section-title">
          {{ 'CONFIRMATION-MODAL.BIDDED-AMOUNT-BUY' | translate }}
        </h6>
        <div class="avax-text turquoise">{{ value | number }} {{ 'SHARED.AVAX' | translate }}</div>

        <div class="add-padding">
          <div class="splitted-section">
            <h6 class="section-title">
              {{ 'CONFIRMATION-MODAL.ESTIMATED-FEE' | translate }}
            </h6>
            <div class="section-right">
              <button (click)="toggleGasFeeDescription()" [ngClass]="{ toggled: isGasFeeDescriptionOpened }" class="toggle-fee-description-button" type="button">
                <span class="toggle-fee-description-button-text">
                  {{ 'CONFIRMATION-MODAL.DETAILS' | translate }}
                </span>

                <img alt="chevron" class="toggle-fee-description-button-image" height="8" ngSrc="/assets/images/chevron-down.svg" width="14" />
              </button>
            </div>
          </div>

          <div [ngClass]="{ open: isGasFeeDescriptionOpened }" class="gas-fee-description">
            {{ 'CONFIRMATION-MODAL.DETAILS-1' | translate }}<br />
            {{ 'CONFIRMATION-MODAL.DETAILS-2' | translate }}
          </div>

          <ng-container *ngIf="bidFeeLoadingStatus$ | async as bidFeeLoadingStatus">
            <div *ngIf="bidFeeLoadingStatus === LoadingStatus.LOADING" class="spinner-8bit-wrapper">
              <div class="spinner-8bit"></div>
            </div>

            <ng-container *ngIf="bidFeeLoadingStatus === LoadingStatus.LOADED">
              <div class="avax-text">{{ feeValue | number: '1.4-4' }} {{ 'SHARED.AVAX' | translate }}</div>
            </ng-container>

            <ng-container *ngIf="bidFeeLoadingStatus === LoadingStatus.ERROR">
              <div class="bid-fee-error">
                <div>
                  {{ 'BANNER.NOT-LOADED-WHITELIST-FEE' | translate }}
                </div>
                <button (click)="estimateFee()" class="fee-retry-button" type="button">
                  <img alt="" height="16" ngSrc="/assets/images/refresh-cw.svg" width="16" />
                  {{ 'ERROR-PAGE-BUTTON.RETRY' | translate }}
                </button>
              </div>
            </ng-container>
          </ng-container>
        </div>

        <div class="divider"></div>

        <h5 class="big-section-title">
          <span class="total-text">
            {{ 'CONFIRMATION-MODAL.TOTAL' | translate }}
          </span>

          <span class="total-sub-text">
            {{ 'CONFIRMATION-MODAL.INFO' | translate }}
          </span>
        </h5>
        <div class="avax-text big">{{ value! + feeValue! | number: '1.4-4' }} {{ 'SHARED.AVAX' | translate }}</div>
      </div>

      <div class="confirmed-content">
        <div class="icon-area">
          <img alt="Success" height="70" ngSrc="/assets/images/sending-op.svg" width="70" />
        </div>

        <h5 class="info-text">
          {{ (isItDirectBuy ? 'CONFIRMATION-MODAL.MSG-DIRECT-BUY' : 'CONFIRMATION-MODAL.MSG') | translate }}
          <label *ngIf="isItDirectBuy" class="msg-2">{{ 'CONFIRMATION-MODAL.MSG-DIRECT-BUY-2' | translate }}</label>
          <label *ngIf="!isItDirectBuy" class="msg-2">{{ 'CONFIRMATION-MODAL.MSG-2' | translate }}</label>
        </h5>

        <div class="result-button-wrapper">
          <button (click)="goToHomePage()" class="view-placed-bids-button" type="button">
            {{ 'CONFIRMATION-MODAL.VIEW' | translate }}
          </button>
        </div>
      </div>

      <div class="error-content">
        <div class="icon-area">
          <img alt="error" height="48" ngSrc="/assets/images/error-icon.svg" width="48" />
        </div>

        <h5 class="info-text">
          {{ failedErrorTitle | translate }}
        </h5>

        <span class="info-sub-text">
          {{ failedErrorSubtitle | translate }}
        </span>

        <div class="result-button-wrapper">
          <button (click)="handleFailedErrorButtonClick()" class="retry-button" type="button">
            {{ failedErrorButton | translate }}
          </button>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="nftItem" footerContent>
    <ng-container *ngIf="bidFeeLoadingStatus$ | async as bidFeeLoadingStatus">
      <ng-container *ngIf="bidFeeLoadingStatus === LoadingStatus.LOADED && !enoughBalance">
        <div class="not-enough-balance">
          {{ 'CONFIRMATION-MODAL.NOT-ENOUGH-BALANCE' | translate }}
        </div>
      </ng-container>
      <button
        (click)="confirm()"
        *ngIf="!isSuccess && !isFailed"
        [disabled]="bidFeeLoadingStatus !== LoadingStatus.LOADED || !enoughBalance"
        [ngClass]="{ confirming: isConfirming }"
        class="confirm-button"
        type="button">
        {{ (isConfirming ? 'CONFIRMATION-MODAL.CONFIRMING' : 'CONFIRMATION-MODAL.CONFIRM') | translate }}
        <ng-container *ngIf="isConfirming"><span class="loader__dot">.</span><span class="loader__dot">.</span><span class="loader__dot">.</span></ng-container>
      </button>
    </ng-container>
  </ng-container>
</togg-base-modal>
