import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'togg-toggle-button',
  templateUrl: './toggle-button.component.html',
  styleUrls: ['./toggle-button.component.scss'],
})
export class ToggleButtonComponent {
  @Input() isChecked: boolean = false;
  @Output() isCheckedChange = new EventEmitter<boolean>();

  handleKeydown(event: KeyboardEvent) {
    if (event.code === 'Space' || event.code === 'Enter' || event.code === 'NumpadEnter') {
      this.toggleCheckbox();
    }
  }

  onChange() {
    this.toggleCheckbox();
  }

  private toggleCheckbox() {
    this.isChecked = !this.isChecked;
    this.isCheckedChange.emit(this.isChecked);
  }
}
