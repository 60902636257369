import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[toggAlphanumericTextInput]',
})
export class AlphanumericInputDirective {
  private regex = new RegExp(/^[a-zA-Z0-9]{0,5000}$/);
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight'];

  constructor(private el: ElementRef) {}

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if ((event.ctrlKey || event.metaKey) && ['a', 'x', 'c', 'v'].includes(event.key)) {
      return;
    }

    if (this.specialKeys.includes(event.key)) {
      return;
    }

    const next: string = this.el.nativeElement.value.concat(event.key);

    if (!next.match(this.regex)) {
      event.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    const pasteData = event.clipboardData?.getData('text/plain') || '';
    const pastedValue = this.el.nativeElement.value.concat(pasteData);
    const integerParsedValue = parseInt(pastedValue);

    if (isNaN(integerParsedValue) || integerParsedValue > 999999) {
      event.preventDefault();
    } else {
      event.preventDefault();
      this.el.nativeElement.value = integerParsedValue.toString();
      this.el.nativeElement.dispatchEvent(new KeyboardEvent('keypress', { bubbles: true }));
    }
  }
}
