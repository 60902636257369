import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { ModalsService } from '../../services';
import { ModalId } from '../../enums/modal-id';
import { ModalScreenType } from '../../enums/modal-screen-type';
import { ModalDisplayMode } from '../../enums/modal-display-mode';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';

@Component({
  selector: 'togg-base-modal',
  templateUrl: './base-modal.component.html',
  styleUrls: ['./base-modal.component.scss'],
})
export class BaseModalComponent implements OnInit, OnDestroy {
  @Input() showCloseButton = false;
  @Input() disableCloseButton = false;
  @Input() disableBackdropClick = false;
  @Input() closeAutomatically = true;
  @Input() screenType: ModalScreenType = ModalScreenType.NORMAL;
  @Input() displayMode: ModalDisplayMode = ModalDisplayMode.ALL;

  @Input({ required: true })
  modalId!: ModalId;

  @Output() closeButtonClicked = new EventEmitter<string>();
  @Output() backdropClicked = new EventEmitter<string>();
  isOpened = false;
  protected readonly ModalScreenType = ModalScreenType;
  protected readonly ModalDisplayMode = ModalDisplayMode;
  private subscription?: Subscription;
  private breakpointSubscription?: Subscription;

  constructor(
    private modalService: ModalsService,
    private readonly breakpointObserver: BreakpointObserver,
  ) {}

  ngOnInit() {
    this.subscription = this.modalService.modalState$.subscribe(state => {
      this.isOpened = state.includes(this.modalId);
    });

    this.breakpointSubscription = this.breakpointObserver.observe(['(min-width: 768px)']).subscribe((result: BreakpointState) => {
      if (result.matches && this.displayMode === ModalDisplayMode.MOBILE_ONLY) {
        this.modalService.close(this.modalId);
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
    this.breakpointSubscription?.unsubscribe();
  }

  closeButtonClickHandler() {
    if (this.disableCloseButton) {
      return;
    }

    this.closeButtonClicked.emit(this.modalId);

    if (this.closeAutomatically) {
      this.modalService.close(this.modalId);
    }
  }

  backdropClickHandler() {
    if (this.disableBackdropClick) {
      return;
    }

    this.backdropClicked.emit(this.modalId);

    if (this.closeAutomatically) {
      this.modalService.close(this.modalId);
    }
  }
}
