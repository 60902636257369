import { Component, OnDestroy, OnInit } from '@angular/core';
import { environment } from '../environments/environment';
import { GuardsCheckEnd, GuardsCheckStart, NavigationCancel, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { GoogleAnalyticsService } from './services/google-analytics/google-analytics.service';
import { AuctionStoreService, CountlyService, CustomerStoreService, NftStoreService, SocketService } from './services';
import { InstanaMonitoringService } from './services/instana-monitoring/instana-monitoring.service';
import { Carousel } from 'primeng/carousel';

@Component({
  selector: 'togg-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [],
})
export class AppComponent implements OnInit, OnDestroy {
  private static readonly LOCAL_STORAGE_TRANSLATION_KEY = 'togg_nft_marketplace_translation_language';
  guardsAreWorking = false;
  private subscriptions: Subscription[] = [];

  constructor(
    private readonly translate: TranslateService,
    private readonly router: Router,
    private readonly googleAnalyticsService: GoogleAnalyticsService,
    private readonly countlyService: CountlyService,
    private readonly instanaMonitoringService: InstanaMonitoringService,
    private readonly socketService: SocketService,
    private readonly customerStore: CustomerStoreService,
    private readonly auctionStore: AuctionStoreService,
    private readonly nftStore: NftStoreService,
  ) {
    //to enable vertical scroll on carousel
    Carousel.prototype.onTouchMove = () => {};
  }

  ngOnInit() {
    this.countlyService.loadCountlyScript();
    this.instanaMonitoringService.loadInstanaScript();
    this.googleAnalyticsService.loadGTag();

    this.initTranslationDefaults();
    this.initializeTranslation();
    this.initializeRouteListener();
    this.listenSocketMessages();

    this.subscriptions.push(
      this.router.events.subscribe(event => {
        if (event instanceof GuardsCheckStart) {
          this.guardsAreWorking = true;
        } else if (event instanceof GuardsCheckEnd || event instanceof NavigationCancel) {
          this.guardsAreWorking = false;
        }
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  public onActivate() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  private initTranslationDefaults(): void {
    this.translate.addLangs(environment.languages);
    this.translate.setDefaultLang(environment.currentLang);
  }

  private initializeTranslation(): void {
    let selectedLanguage;

    // This array represents potential query string parameters that, when present in a URL, override the current language setting of the application.
    // The parameters are checked in order from first to last, and any subsequent parameter will override any previous ones.
    // Therefore, the last parameter in this list holds the highest priority in determining the language setting.
    const languageOverrideParamNames = ['lang', 'lng', 'language', 'testlang'];
    const urlParams = new URLSearchParams(window.location.search);

    // If the user's browser language is supported by the application, that language is used at default.
    const browserLanguages = navigator.languages.map(language => language.toLowerCase().split('-')[0]);

    for (const browserLanguage of browserLanguages) {
      if (environment.languages.includes(browserLanguage)) {
        selectedLanguage = browserLanguage.toLowerCase();
        break;
      }
    }

    // If the user has previously selected a language, that choice is used. This part also overrides the browser language.
    if (localStorage?.getItem(AppComponent.LOCAL_STORAGE_TRANSLATION_KEY)) {
      selectedLanguage = localStorage.getItem(AppComponent.LOCAL_STORAGE_TRANSLATION_KEY);
    }

    // If the user has specified a language override parameter in the URL, that choice is used.
    // This part also overrides the browser language and the user's previous choice, but it will not write selected language to local storage.
    languageOverrideParamNames.forEach(paramName => {
      const urlParam = urlParams.get(paramName);

      if (urlParam && environment.languages.includes(urlParam)) {
        selectedLanguage = urlParam;
      }
    });

    // If no language has been selected, the application's default language is used.
    if (!selectedLanguage) {
      selectedLanguage = environment.currentLang;
    }

    this.translate.use(selectedLanguage);
  }

  private listenSocketMessages() {
    this.subscriptions.push(
      this.socketService.listenForMarketplaceBids().subscribe(message => {
        console.log('listenForMarketplaceBids', message);
        this.nftStore.addNftOfferFromWebsocket(message.parsedBody);
        this.nftStore.updateNftItemFromWebsocket(message.parsedBody.nft);
        this.auctionStore.updateOrAddBidHistoryFromWebsocket(message.parsedBody);
      }),
    );

    this.subscriptions.push(
      this.socketService.listenForWalletNfts().subscribe(message => {
        console.log('listenForWalletNfts', message);
        this.nftStore.updateNftItemFromWebsocket(message.parsedBody);
      }),
    );

    this.subscriptions.push(
      this.socketService.listenForCustomerNfts().subscribe(message => {
        console.log('listenForCustomerNfts', message);
        this.nftStore.updateNftItemFromWebsocket(message.parsedBody);
      }),
    );

    this.subscriptions.push(
      this.socketService.listenForMarketplaceOutbids().subscribe(message => {
        console.log('listenForMarketplaceOutbids', message);
        this.nftStore.addNftOfferFromOutbidWebsocket(message.parsedBody);
        this.nftStore.updateNftItemFromWebsocket({
          ...message.parsedBody.nft,
          price: message.parsedBody.newPrice,
        });
        this.auctionStore.updateOrAddBidHistoryFromWebsocket({
          ...message.parsedBody,
          destinationWallet: message.parsedBody.newBidderAddress,
          customerId: message.parsedBody.newBidderToggId,
          nft: {
            ...message.parsedBody.nft,
            price: message.parsedBody.newPrice,
          },
        });
      }),
    );

    this.subscriptions.push(
      this.socketService.listenForWalletCustomers().subscribe(message => {
        console.log('listenForWalletCustomers', message);
        this.customerStore.updateCustomerDataFromWebsocket(message.parsedBody);
      }),
    );

    this.subscriptions.push(
      this.socketService.listenForWalletBalances().subscribe(message => {
        console.log('listenForWalletBalances', message);
        this.customerStore.changeBalance(message.parsedBody.tokenBalances);
      }),
    );

    //this.subscriptions.push(
    //  this.socketService.listenForWalletTransactions().subscribe(message => {
    //    console.log('listenForWalletTransactions', message.parsedBody);
    //  }),
    //);
  }

  private initializeRouteListener() {
    const routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.googleAnalyticsService.sendPageView(event.urlAfterRedirects);
      }
    });

    this.subscriptions.push(routerSubscription);
  }
}
