<button
  (click)="copyToClipboard()"
  [cbContent]="copyText"
  [class.copied]="copied"
  [ngClass]="'INFO-CAROUSEL-COMPONENT.FANCY-BUTTON-CLASS' | translate"
  class="copy-button"
  ngxClipboard>
  <img *ngIf="!copied" alt="" height="19" ngSrc="/assets/images/copy-file.svg" width="19" />
  <img *ngIf="copied" alt="" height="11" ngSrc="/assets/images/copied.svg" width="13" />
</button>
