import { Component, Input } from '@angular/core';

@Component({
  selector: 'togg-info-person',
  templateUrl: './info-person.component.html',
  styleUrls: ['./info-person.component.scss'],
})
export class InfoPersonComponent {
  @Input() label = '';
  @Input() infoLabel = '';
  @Input() numberOfCreation = '';
  @Input() moreInfo = '';
  @Input() type = '';
}
