<ng-container *ngIf="{ isLoading: nftStoreService.loadingNftItems$ | async } as loading">
  <div *ngIf="loading.isLoading" class="fixed w-full h-full bg-black-alpha-50 z-5">
    <div class="spinner-8bit"></div>
  </div>
  <togg-main-header *ngIf="!loading.isLoading"></togg-main-header>
  <div *ngIf="!loading.isLoading" class="nft-list flex flex-column" (click)="showFilter = false">
    <div class="flex w-full px-3 pt-3 gap-5 align-items-center">
      <img alt="profile" src="/assets/images/profile-icon.svg" height="64" width="64" />
      <div class="flex flex-column">
        <span class="font-16 flex gap-3">{{ formatWalletAddress(userData?.walletId) }} <togg-clipboard-button [copyText]="userData?.walletId"></togg-clipboard-button></span>
        <span class="font-12">{{ 'AUCTIONS.JOINED' | translate }} {{ joinedDate?.toLocaleDateString('tr-TR') }}</span>
      </div>
    </div>
    <div class="flex p-4 align-items-center w-full gap-2">
      <img alt="info" height="32" width="32" src="/assets/images/info-text.svg" />
      <span class="font-14">
        {{ 'AUCTIONS.HEADER-TEXT' | translate }}
      </span>
    </div>

    <div class="flex justify-content-between align-items-center toolbar relative">
      <button class="btn-text btn-toggle font-14 flex gap-2 align-items-center" [ngClass]="{ selected: showFilter }" (click)="toggleFilter($event)">
        {{ 'Nft-Filter.FILTER' | translate }}
        <img class="chevron" [ngClass]="{ 'filter-open': showFilter }" ngSrc="/assets/images/chevron-black.svg" alt="back" height="12" width="12" />
      </button>
      <togg-nft-filter
        class="absolute left-0 z-1 filter"
        [ngClass]="{ open: showFilter }"
        (filterChanged)="filterChanged($event)"
        (click)="$event.stopPropagation()"></togg-nft-filter>
      <button
        class="btn-text btn-toggle font-14 flex align-items-center gap-2"
        [ngClass]="{ selected: multiselectMode }"
        (click)="toggleMultiselectionMode()"
        [disabled]="!canMultiselect">
        <img alt="" height="24" ngSrc="/assets/images/multiple.svg" width="24" />
        {{ 'AUCTIONS.MULTI-SELECT' | translate }}
      </button>
    </div>
    <div *ngIf="allNfts.length" class="nft-grid" [ngClass]="{ 'pb-7': multiselectMode }">
      <togg-nft-list-item
        [disabled]="multiselectMode && (item.onSale || item.collectionPaused || item.status === NftStatus.IN_PROGRESS || !item.transferable)"
        (clicked)="nftClicked($event)"
        *ngFor="let item of allNfts; let i = index"
        [nftItem]="item"
        [multiselectMode]="multiselectMode && !item.onSale && !item.collectionPaused && !!item.transferable"
        [selectedCounter]="selectedNfts.indexOf(item) + 1"></togg-nft-list-item>
    </div>
    <div class="w-full flex align-items-center justify-content-center pt-8 font-12" *ngIf="!allNfts.length">{{ 'AUCTIONS.NO-NFTS' | translate }}</div>
    <div *ngIf="multiselectMode" class="multi-select-toolbar flex justify-content-between align-items-baseline p-2">
      <div class="flex align-items-center pt-2 pb-3">
        <span class="mx-2 bold">{{ selectedNfts.length }} {{ 'AUCTIONS.ITEMS' | translate }}</span>
        <button class="btn-transparent" (click)="selectAll()">
          {{ 'AUCTIONS.SELECT-ALL' | translate }}
        </button>
        <div class="separator"></div>
        <button class="btn-transparent" (click)="selectedNfts = []">
          {{ 'AUCTIONS.SELECT-NONE' | translate }}
        </button>
      </div>
      <div class="flex gap-2 pr-4">
        <button class="btn btn-text btn-small font-14" (click)="toggleMultiselectionMode()">{{ 'AUCTIONS.CANCEL' | translate }}</button>
        <button
          [disabled]="!selectedNfts.length || (whiteListingStatus !== WhitelistedEnum.WHITELISTED && whiteListingStatus !== WhitelistedEnum.NOT_WHITELISTED)"
          class="btn btn-primary btn-small font-14"
          (click)="onNextClick()">
          {{ 'AUCTIONS.SELL' | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-container>
