import { NftDto } from '@togg-trumore/toggens-operations-api-client';

export enum NftType {
  UNKNOWN = 'UNKNOWN',
  ERC721 = 'ERC721',
  ERC1155 = 'ERC1155',
  _11184809 = '11184809',
}

export enum OperationType {
  UNKNONW = 'UNKNONW',
  TRANSFER = 'TRANSFER',
  MINTING = 'MINTING',
  BURNING = 'BURNING',
  BIDDING = 'BIDDING',
  LISTING = 'LISTING',
  EDIT_LISTING = 'EDIT_LISTING',
  SELLING = 'SELLING',
  MINTING_LISTING = 'MINTING_LISTING',
  MINTING_SELLING = 'MINTING_SELLING',
  CANCEL_LISTING = 'CANCEL_LISTING',
  CLOSE_AUCTION = 'CLOSE_AUCTION',
  BUY = 'BUY',
  TRANSACTION = 'TRANSACTION',
  CANCEL_AUCTION = 'CANCEL_AUCTION',
  HIGHLIGHTING = 'HIGHLIGHTING',
  _11184809 = '11184809',
}

export enum NftStatus {
  UNKNOWN = 'UNKNOWN',
  CREATED = 'CREATED',
  IN_PROGRESS = 'IN_PROGRESS',
  PAYMENT_ERROR = 'PAYMENT_ERROR',
  RELEASED = 'RELEASED',
  ERROR = 'ERROR',
  BEATEN = 'BEATEN',
  CANCELLED = 'CANCELLED',
  WON = 'WON',
  _11184809 = '11184809',
}

export interface Nft {
  /**
   * The NFT internal ID on the Toggens platform.
   */
  nftInternalId: string;
  /**
   * The NFT owner\'s Togg ID.
   */
  ownerToggId?: string;
  /**
   * The NFT owner\'s wallet.
   */
  ownerWallet: string;
  /**
   * The NFT ID in the collection where the NFT belongs to.
   */
  nftId: number;
  /**
   * The address of the collection where the NFT belongs to.
   */
  collectionAddress: string;
  /**
   * The validation flag of the collection where the NFT belongs to.
   */
  collectionValidated: boolean;
  /**
   * The NFT token URI.
   */
  tokenUri: string;
  /**
   * The NFT media token URI.
   */
  mediaTokenUri: string;
  /**
   * The NFT media type.
   */
  mediaType: NftDto.MediaTypeEnum;
  /**
   * The NFT\'s media file URL.
   */
  mediaFileUrl: string;
  /**
   * The NFT\'s thumbnail Token URI.
   */
  thumbnailTokenUri: string;
  /**
   * The NFT\'s thumbnail file URL.
   */
  thumbnailFileUrl: string;
  /**
   * The NFT rarity.
   */
  rarity?: NftDto.RarityEnum;
  /**
   * The NFT name.
   */
  name: string;
  /**
   * The NFT\'s creator.
   */
  creator: string;
  /**
   * The NFT description.
   */
  description: string;
  /**
   * Flag to display if the NFT is on sale.
   */
  onSale: boolean;
  /**
   * The NFT price.
   */
  price: number;
  /**
   * The NFT initial price in case of bid.
   */
  initialPrice: number;
  /**
   * The NFT max price in case of bid.
   */
  maxPrice: number;
  /**
   * The NFT final price in case of bid.
   */
  finalPrice?: number;
  /**
   * The royalty fee of the NFT when minted.
   */
  royaltyFee: number;
  /**
   * The bid end date of the NFT.
   */
  bidEndDate: string;
  /**
   * The sell end date of the NFT.
   */
  sellEndDate?: string;
  /**
   * The launch date of the NFT.
   */
  launchDate: string;
  /**
   * The listingId when the NFT has been listed.
   */
  listingId: number;
  /**
   * The number of bidding operations associated with the NFT.
   */
  biddings: number;
  /**
   * The NFT gas fee.
   */
  fee: number;
  /**
   * Flag to display if the NFT is highlighted.
   */
  highlighted: boolean;
  /**
   * The NFT\'s smart contract type.
   */
  nftType: NftType;
  /**
   * The NFT\'s quantity for ERC1155 smart contracts.
   */
  quantity: number;
  /**
   * The last NFT\'s admin operation.
   */
  operation?: OperationType;
  /**
   * The last NFT admin operation\'s status.
   */
  status?: NftStatus;
  /**
   * The NFT\'s mint transaction QR Code.
   */
  qrCodeUrl: string;
  /**
   * The paused flag of the collection where the NFT belongs to.
   */
  collectionPaused: boolean;
  /**
   * The min bid value for this NFT.
   */
  minBid: number;
  /**
   * Flag to display if the NFT is transferable.
   */
  transferable?: boolean;
}
