import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CountlyService, Nft } from '../../services';

@Component({
  selector: 'togg-nft-details-header',
  templateUrl: './nft-details-header.component.html',
  styleUrls: ['./nft-details-header.component.scss'],
})
export class NftDetailsHeaderComponent {
  @Input({
    required: true,
  })
  nftItem!: Nft;
  @Input() showEdit = false;

  @Input() type: 'expand_image' | 'only_close_icon' | 'play_video' = 'expand_image';
  @Output() expandButtonClicked = new EventEmitter<void>();
  @Output() closeIconClicked = new EventEmitter<void>();
  @Output() playVideoButtonClicked = new EventEmitter<void>();
  @Output() cancelListingClicked = new EventEmitter<void>();
  @Output() editListingClicked = new EventEmitter<void>();

  constructor(private readonly countlyService: CountlyService) {}

  expandImageButtonClicked() {
    this.expandButtonClicked.emit();
  }

  playVideoButtonClickedHandler() {
    this.playVideoButtonClicked.emit();
  }

  onCloseButtonClicked() {
    this.closeIconClicked.emit();
  }

  emitCancelListing() {
    this.cancelListingClicked.emit();
  }

  emitEditListing() {
    this.editListingClicked.emit();
  }

  sendCountlyPlayVideoClickEvent() {
    this.countlyService.trackEventWithSegmentation({
      key: 'play video button clicked',
    });
  }
}
