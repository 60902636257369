<togg-main-header></togg-main-header>
<div class="loading" *ngIf="loading">
  <div class="spinner-8bit"></div>
</div>
<div class="sell-container flex flex-column">
  <togg-accordion [titleText]="('AUCTIONS.PREVIEW' | translate) + ' (' + selectedNfts.length + ')'" class="mx-2 px-2 py-3" [isDefaultOpen]="true">
    <ng-container accordionContent>
      <div *ngIf="selectedNfts.length === 1; else carousel" class="bordered-box p-3">
        <div class="nft-info">
          <div class="nft-image-wrap">
            <togg-image-with-fallback-and-loader
              class="image-loader"
              imgAlt="NFT image"
              imgSrc="{{ selectedNfts[0].thumbnailFileUrl }}"
              loaderColor="#222222"
              successImageClass="ntf-list-item-image"></togg-image-with-fallback-and-loader>
          </div>
          <div class="nft-name-and-creator">
            <h3 class="nft-name">{{ selectedNfts[0].name }}</h3>

            <div class="nft-creator">
              <img alt="approved" class="nft-list-item-creator-approved" height="11" ngSrc="/assets/images/blue-check.svg" width="11" />
              <span class="nft-list-item-creator-approved-brand">{{ selectedNfts[0].creator }}</span>
            </div>
          </div>
        </div>
      </div>
      <ng-template #carousel>
        <p-carousel [value]="selectedNfts" [showIndicators]="false" [numVisible]="6.2" [numScroll]="1" [responsiveOptions]="carouselResponsiveOptions" class="bordered-box py-3">
          <ng-template let-nft pTemplate="item">
            <div class="nft-info">
              <div class="nft-image-wrap">
                <togg-image-with-fallback-and-loader
                  class="image-loader"
                  imgAlt="NFT image"
                  imgSrc="{{ nft.thumbnailFileUrl }}"
                  loaderColor="#222222"
                  successImageClass="ntf-list-item-image"></togg-image-with-fallback-and-loader>
              </div>
              <div class="nft-name-and-creator">
                <h3 class="nft-name">{{ nft.name }}</h3>

                <div class="nft-creator">
                  <img alt="approved" class="nft-list-item-creator-approved" height="11" ngSrc="/assets/images/blue-check.svg" width="11" />
                  <span class="nft-list-item-creator-approved-brand">{{ nft.creator }}</span>
                </div>
              </div>
            </div>
          </ng-template>
        </p-carousel>
      </ng-template>
    </ng-container>
  </togg-accordion>
  <div class="slider-container">
    <div [ngClass]="{ 'opacity-0': selectedNfts[0].onSale }" class="slide-form h-full" [style.transform]="'translateX(' + step * -100 + '%)'">
      <div class="flex flex-column gap-2 p-3 w-full">
        <span class="step-header">{{ 'AUCTIONS.SELL-METHOD-HEADER' | translate }}</span>
        <span class="step-info white-space-pre-wrap">{{ 'AUCTIONS.SELL-METHOD-TEXT' | translate }}</span>
        <div class="sell-type-buttons pt-3">
          <button type="button" class="btn-primary" (click)="setFixedPriceSell()">{{ 'AUCTIONS.FIXED-PRICE' | translate }}</button>
          <button type="button" class="btn-secondary" (click)="setAuctionSell()">{{ 'AUCTIONS.TIMED-AUCTION' | translate }}</button>
        </div>
      </div>
    </div>
    <form
      [formGroup]="fixedPriceForm"
      (ngSubmit)="onSubmitFixedPrice()"
      *ngIf="!sellType || sellType === SellType.FIXED_PRICE"
      [style.transform]="'translateX(' + (step ? -100 : 0) + '%)'">
      <togg-step-tabs [steps]="fixedPriceSteps" [currentStep]="step" (stepChange)="step = $event" [maxStep]="maxStep"></togg-step-tabs>
      <div class="slide-form" [style.transform]="'translateX(' + (step - 1) * -100 + '%)'">
        <div class="flex flex-column gap-2 px-2 h-full">
          <span class="step-header">{{ 'AUCTIONS.SET-FIXED-PRICE' | translate }}</span>
          <span class="step-info">{{ 'AUCTIONS.SET-FIXED-PRICE-DESCRIPTION' | translate }}</span>
          <p-inputNumber
            #fixedPrice
            styleClass="w-full"
            placeholder="0 AVAX"
            [maxFractionDigits]="2"
            formControlName="fixedPrice"
            inputmode="decimal"
            locale="tr-TR"
            [min]="0"
            [step]="0.1"
            toggCommaInput>
          </p-inputNumber>
          <div class="nav-buttons px-3">
            <div class="w-full flex">
              <button *ngIf="!selectedNfts[0].onSale" type="button" class="btn-text" (click)="step = step - 1">
                <img ngSrc="/assets/images/nav-chevron-left.svg" alt="back" height="16" width="16" />
                {{ 'AUCTIONS.BACK' | translate }}
              </button>
              <button type="button" class="btn-text ml-auto" [disabled]="!fixedPriceControls['fixedPrice'].valid" (click)="step = step + 1">
                {{ 'AUCTIONS.NEXT' | translate }}
                <img ngSrc="/assets/images/nav-chevron-right.svg" alt="next" height="16" width="16" />
              </button>
            </div>
            <button class="btn-text align-self-center" type="button" (click)="goToHomePage()">{{ 'AUCTIONS.CANCEL' | translate }}</button>
          </div>
        </div>
      </div>
      <div class="slide-form" [style.transform]="'translateX(' + (step - 1) * -100 + '%)'">
        <div class="flex flex-column gap-3 px-2 h-full">
          <span class="step-header">{{ 'AUCTIONS.SET-TIME-EXPIRATION' | translate }}</span>
          <span class="step-info">{{ 'AUCTIONS.SET-TIME-EXPIRATION-DESCRIPTION' | translate }}</span>

          <togg-select
            #fixedDateSelect
            [options]="presetTimeOptions"
            appendTo="body"
            [selectedValue]="'Days7'"
            (selectedValueChange)="handlePresetTimeChange($event, fixedPriceControls['endDate'])"
            [disabled]="fixedPriceControls['skipTimeLimitation'].value"></togg-select>
          <div class="flex gap-3">
            <p-calendar
              [readonlyInput]="true"
              [showIcon]="true"
              [firstDayOfWeek]="1"
              dateFormat="dd.mm.yy"
              appendTo="body"
              [ngModel]="fixedPriceControls['endDate'].value"
              [ngModelOptions]="{ standalone: true }"
              (onSelect)="setFixedPriceEndDate($event)"
              placeholder="{{ 'AUCTIONS.SELECT-DATE' | translate }}"
              [styleClass]="'w-full'"
              class="flex-grow-1"
              [disabled]="fixedPriceControls['skipTimeLimitation'].value"></p-calendar>

            <p-calendar
              [readonlyInput]="true"
              [timeOnly]="true"
              [showIcon]="true"
              appendTo="body"
              [ngModel]="fixedPriceControls['endDate'].value"
              [ngModelOptions]="{ standalone: true }"
              (onSelect)="setFixedPriceEndTime($event)"
              placeholder="{{ 'AUCTIONS.SELECT-TIME' | translate }}"
              [disabled]="fixedPriceControls['skipTimeLimitation'].value"
              [styleClass]="'w-full'"
              class="flex-grow-1">
            </p-calendar>
          </div>
          <div class="flex align-items-center gap-2 pt-2">
            <p-inputSwitch ariaLabelledBy="timeLimitationSwitch" formControlName="skipTimeLimitation"></p-inputSwitch>
            <span id="timeLimitationSwitch">{{ 'AUCTIONS.SKIP-TIME-LIMIT' | translate }}</span>
          </div>
          <div class="nav-buttons px-3 pt-3">
            <div class="w-full flex justify-content-between">
              <button type="button" class="btn-text" (click)="step = step - 1">
                <img ngSrc="/assets/images/nav-chevron-left.svg" alt="back" height="16" width="16" />
                {{ 'AUCTIONS.BACK' | translate }}
              </button>
              <button
                type="button"
                class="btn-text"
                [disabled]="!fixedPriceControls['skipTimeLimitation'].value && !fixedPriceControls['endDate'].valid"
                (click)="openFixedPriceSummaryPage()">
                {{ 'AUCTIONS.NEXT' | translate }}
                <img ngSrc="/assets/images/nav-chevron-right.svg" alt="next" height="16" width="16" />
              </button>
            </div>
            <button class="btn-text align-self-center" type="button" (click)="goToHomePage()">{{ 'AUCTIONS.CANCEL' | translate }}</button>
          </div>
        </div>
      </div>
      <div class="slide-form" [style.transform]="'translateX(' + (step - 1) * -100 + '%)'">
        <div class="flex flex-column gap-2 px-2">
          <div class="flex flex-column gap-2 p-4 align-self-stretch bordered-box">
            <div class="flex px-1 py-2 justify-content-center">
              <div class="flex flex-column gap-1 align-items-center">
                <span class="mini-card-name">{{ 'AUCTIONS.FIXED-PRICE' | translate }}</span>
                <div class="flex gap-1 align-items-center">
                  <img alt="avax token" class="avax-icon" height="15" ngSrc="/assets/images/AVAX-black.svg" width="15" />
                  <span class="mini-card-value">{{ fixedPriceControls['fixedPrice'].value }}</span>
                </div>
              </div>
            </div>
            <div class="time-limitation-box">
              <div>
                <span class="block expiration-label"> {{ 'AUCTIONS.EXPIRATION-LABEL' | translate }} </span>
                <span *ngIf="!fixedPriceControls['skipTimeLimitation'].value" class="block expiration-text">
                  {{ 'AUCTIONS.EXPIRATION-DAYS-LEFT' | translate: { daysCount: getExpirationDaysCount(fixedPriceControls['endDate']) } }}
                </span>
                <span *ngIf="fixedPriceControls['skipTimeLimitation'].value" class="block expiration-text"> {{ 'AUCTIONS.NO-EXPIRATION' | translate }} </span>
              </div>
              <span *ngIf="!fixedPriceControls['skipTimeLimitation'].value" class="expiration-info">
                {{
                  'AUCTIONS.EXPIRATION-TEXT'
                    | translate
                      : {
                          expirationDate: fixedPriceControls['endDate'].value.toLocaleDateString('tr-TR'),
                          expirationTime: fixedPriceControls['endDate'].value.toLocaleTimeString('tr-TR')
                        }
                }}
              </span>
            </div>
          </div>
          <div class="fees-table">
            <span class="fees-title">{{ 'AUCTIONS.FEES.LABEL' | translate }}</span>
            <div class="fee-row">
              <span>{{ 'AUCTIONS.FEES.SERVICE' | translate }} <span class="small">(2%)</span></span>
              <span>{{ (fixedPriceControls['fixedPrice'].value * 0.02).toFixed(4) }} <span class="small">AVAX</span></span>
            </div>
            <div class="fee-row">
              <span>{{ 'AUCTIONS.FEES.CREATOR' | translate }} <span class="small">(10%)</span></span>
              <span>{{ (fixedPriceControls['fixedPrice'].value * 0.1).toFixed(4) }} <span class="small">AVAX</span></span>
            </div>
            <div class="fee-row">
              <span>{{ 'AUCTIONS.FEES.GAS' | translate }}</span>
              <span>{{ gasFee.toFixed(4) }} <span class="small">AVAX</span></span>
            </div>
            <div class="fee-row">
              <span>{{ 'AUCTIONS.FEES.TOTAL' | translate }}</span>
              <span class="total"> {{ (fixedPriceControls['fixedPrice'].value * 0.12 + gasFee).toFixed(4) }} <span class="small">AVAX</span></span>
            </div>
          </div>
          <div class="earn">
            <span>{{ 'AUCTIONS.EARN' | translate }}</span>
            <span>{{ (fixedPriceControls['fixedPrice'].value * 0.88 - gasFee).toFixed(4) }} <span class="small">AVAX</span></span>
          </div>
          <div class="sell-confirm">
            <span class="font-20 white-space-normal">{{ 'AUCTIONS.TRANSACTION-FEES' | translate }}</span>
            <span class="font-14 white-space-normal">{{ 'AUCTIONS.FEES-DESCRIPTION' | translate }}</span>
            <span class="bold">{{ (fixedPriceControls['fixedPrice'].value * 0.12 + gasFee).toFixed(4) }} AVAX</span>
            <span class="flex gap-2 align-baseline">
              <p-checkbox formControlName="agreed" [binary]="true" inputId="agreed"></p-checkbox>
              <label class="font-14 white-space-normal" for="agreed">{{ 'AUCTIONS.AGREED' | translate }}</label>
            </span>
          </div>
          <div class="nav-buttons mt-3">
            <button class="btn-primary w-full" type="submit" [disabled]="!fixedPriceControls['agreed'].value">{{ 'AUCTIONS.CONTINUE' | translate }}</button>
            <div class="flex flex-column gap-2 w-full py-3">
              <button type="button" class="btn-text align-self-start" (click)="step = step - 1">
                <img ngSrc="/assets/images/nav-chevron-left.svg" alt="back" height="16" width="16" />
                {{ 'AUCTIONS.BACK' | translate }}
              </button>
              <button class="btn-text align-self-center" type="button" (click)="goToHomePage()">{{ 'AUCTIONS.CANCEL' | translate }}</button>
            </div>
          </div>
        </div>
      </div>
    </form>

    <form [formGroup]="auctionForm" (ngSubmit)="onSubmitAuction()" *ngIf="!sellType || sellType === SellType.AUCTION" [style.transform]="'translateX(' + (step ? -100 : 0) + '%)'">
      <togg-step-tabs [steps]="auctionSteps" [currentStep]="step" (stepChange)="step = $event" [maxStep]="maxStep"></togg-step-tabs>
      <div class="slide-form" [style.transform]="'translateX(' + (step - 1) * -100 + '%)'">
        <div class="flex flex-column gap-2 px-2 h-full">
          <span class="step-header">{{ 'AUCTIONS.START-PRICE' | translate }}</span>
          <span class="step-info">{{ 'AUCTIONS.START-PRICE-DESCRIPTION' | translate }}</span>
          <p-inputNumber styleClass="w-full" placeholder="0 AVAX" [maxFractionDigits]="2" formControlName="startPrice" lang="tr-TR" toggCommaInput> </p-inputNumber>
          <div class="nav-buttons px-3">
            <div class="w-full flex justify-content-between">
              <button *ngIf="!selectedNfts[0].onSale" type="button" class="btn-text" (click)="step = step - 1">
                <img ngSrc="/assets/images/nav-chevron-left.svg" alt="back" height="16" width="16" />
                {{ 'AUCTIONS.BACK' | translate }}
              </button>
              <button type="button" class="btn-text ml-auto" [disabled]="!auctionControls['startPrice'].valid" (click)="step = step + 1">
                {{ 'AUCTIONS.NEXT' | translate }}
                <img ngSrc="/assets/images/nav-chevron-right.svg" alt="next" height="16" width="16" />
              </button>
            </div>
            <button class="btn-text align-self-center" type="button" (click)="goToHomePage()">{{ 'AUCTIONS.CANCEL' | translate }}</button>
          </div>
        </div>
      </div>
      <div class="slide-form" [style.transform]="'translateX(' + (step - 1) * -100 + '%)'">
        <div class="flex flex-column gap-2 px-2 h-full">
          <span class="step-header">{{ 'AUCTIONS.BUY-NOW' | translate }}</span>
          <span class="step-info">{{ 'AUCTIONS.BUY-NOW-DESCRIPTION' | translate }}</span>
          <p-inputNumber styleClass="w-full" placeholder="0 AVAX" [maxFractionDigits]="2" formControlName="maxPrice" lang="tr-TR" toggCommaInput> </p-inputNumber>
          <div class="nav-buttons px-3">
            <div class="w-full flex justify-content-between">
              <button type="button" class="btn-text" (click)="step = step - 1">
                <img ngSrc="/assets/images/nav-chevron-left.svg" alt="back" height="16" width="16" />
                {{ 'AUCTIONS.BACK' | translate }}
              </button>
              <button type="button" class="btn-text" [disabled]="auctionControls['maxPrice'].invalid" (click)="step = step + 1">
                {{ 'AUCTIONS.NEXT' | translate }}
                <img ngSrc="/assets/images/nav-chevron-right.svg" alt="next" height="16" width="16" />
              </button>
            </div>
            <button class="btn-text align-self-center" type="button" (click)="goToHomePage()">{{ 'AUCTIONS.CANCEL' | translate }}</button>
          </div>
        </div>
      </div>
      <div class="slide-form" [style.transform]="'translateX(' + (step - 1) * -100 + '%)'">
        <div class="flex flex-column gap-3 px-2 h-full">
          <span class="step-header">{{ 'AUCTIONS.SET-TIME-EXPIRATION' | translate }}</span>
          <span class="step-info">{{ 'AUCTIONS.SET-TIME-EXPIRATION-DESCRIPTION' | translate }}</span>
          <togg-select [options]="presetTimeOptions" [selectedValue]="'Days7'" (selectedValueChange)="handlePresetTimeChange($event, auctionControls['endDate'])"></togg-select>
          <div class="flex gap-3 justify-content-">
            <p-calendar
              [readonlyInput]="true"
              [showIcon]="true"
              [firstDayOfWeek]="1"
              dateFormat="dd.mm.yy"
              appendTo="body"
              [ngModel]="auctionControls['endDate'].value"
              [ngModelOptions]="{ standalone: true }"
              (onSelect)="setAuctionEndDate($event)"
              placeholder="{{ 'AUCTIONS.SELECT-DATE' | translate }}"
              [styleClass]="'w-full'"
              class="flex-grow-1">
            </p-calendar>
            <p-calendar
              [readonlyInput]="true"
              [timeOnly]="true"
              [showIcon]="true"
              appendTo="body"
              [ngModel]="auctionControls['endDate'].value"
              [ngModelOptions]="{ standalone: true }"
              (onSelect)="setAuctionEndTime($event)"
              placeholder="{{ 'AUCTIONS.SELECT-TIME' | translate }}"
              [styleClass]="'w-full'"
              class="flex-grow-1">
            </p-calendar>
          </div>
          <div class="nav-buttons px-3">
            <div class="w-full flex justify-content-between">
              <button type="button" class="btn-text" (click)="step = step - 1">
                <img ngSrc="/assets/images/nav-chevron-left.svg" alt="back" height="16" width="16" />
                {{ 'AUCTIONS.BACK' | translate }}
              </button>
              <button type="button" class="btn-text" [disabled]="!auctionControls['endDate'].valid" (click)="openAuctionSummaryPage()">
                {{ 'AUCTIONS.NEXT' | translate }}
                <img ngSrc="/assets/images/nav-chevron-right.svg" alt="next" height="16" width="16" />
              </button>
            </div>
            <button class="btn-text align-self-center" type="button" (click)="goToHomePage()">{{ 'AUCTIONS.CANCEL' | translate }}</button>
          </div>
        </div>
      </div>
      <div class="slide-form" [style.transform]="'translateX(' + (step - 1) * -100 + '%)'">
        <div class="flex flex-column gap-2 px-2 flex-grow-1 overflow-x-auto">
          <div class="flex flex-column gap-2 p-4 align-self-stretch bordered-box">
            <div class="flex px-1 py-2 justify-content-between">
              <div class="flex flex-column gap-1 align-items-center">
                <span class="mini-card-name">{{ 'AUCTIONS.START-PRICE' | translate }}</span>
                <div class="flex gap-1 align-items-center">
                  <img alt="avax token" class="avax-icon" height="15" ngSrc="/assets/images/AVAX-black.svg" width="15" />
                  <span class="mini-card-value">{{ auctionControls['startPrice'].value }}</span>
                </div>
              </div>
              <div class="flex flex-column gap-1 align-items-center">
                <span class="mini-card-name">{{ 'AUCTIONS.BUY-NOW' | translate }}</span>
                <div class="flex gap-1 align-items-center">
                  <img alt="avax token" class="avax-icon" height="15" ngSrc="/assets/images/AVAX-black.svg" width="15" />
                  <span class="mini-card-value">{{ auctionControls['maxPrice'].value }}</span>
                </div>
              </div>
            </div>
            <div class="time-limitation-box">
              <div>
                <span class="block expiration-label">{{ 'AUCTIONS.EXPIRATION-LABEL' | translate }} </span>
                <span class="block expiration-text"> {{ 'AUCTIONS.EXPIRATION-DAYS-LEFT' | translate: { daysCount: getExpirationDaysCount(auctionControls['endDate']) } }} </span>
              </div>
              <span class="expiration-info">
                {{
                  'AUCTIONS.EXPIRATION-TEXT'
                    | translate
                      : {
                          expirationDate: auctionControls['endDate'].value.toLocaleDateString('tr-TR'),
                          expirationTime: auctionControls['endDate'].value.toLocaleTimeString('tr-TR')
                        }
                }}
              </span>
            </div>
          </div>
          <div class="fees-table">
            <span class="fees-title">{{ 'AUCTIONS.FEES.LABEL' | translate }}</span>
            <div class="fee-row">
              <span>{{ 'AUCTIONS.FEES.SERVICE' | translate }} <span class="small">(2%)</span></span>
              <span>{{ (auctionControls['startPrice'].value * 0.02).toFixed(4) }} <span class="small">AVAX</span></span>
            </div>
            <div class="fee-row">
              <span>{{ 'AUCTIONS.FEES.CREATOR' | translate }}<span class="small">(10%)</span></span>
              <span>{{ (auctionControls['startPrice'].value * 0.1).toFixed(4) }} <span class="small">AVAX</span></span>
            </div>
            <div class="fee-row">
              <span>{{ 'AUCTIONS.FEES.GAS' | translate }} </span>
              <span>{{ gasFee.toFixed(4) }} <span class="small">AVAX</span></span>
            </div>
            <div class="fee-row">
              <span>{{ 'AUCTIONS.FEES.TOTAL' | translate }}</span>
              <span class="total">{{ (auctionControls['startPrice'].value * 0.12 + gasFee).toFixed(4) }} <span class="small">AVAX</span></span>
            </div>
          </div>
          <div class="earn">
            <span>{{ 'AUCTIONS.EARN' | translate }}</span>
            <span>{{ (auctionControls['maxPrice'].value - auctionControls['startPrice'].value * 0.12 - gasFee).toFixed(4) }} <span class="small">AVAX</span></span>
          </div>
          <div class="sell-confirm">
            <span class="font-20 white-space-normal">{{ 'AUCTIONS.TRANSACTION-FEES' | translate }}</span>
            <span class="font-14 white-space-normal">{{ 'AUCTIONS.FEES-DESCRIPTION' | translate }}</span>
            <span class="bold">{{ (auctionControls['startPrice'].value * 0.12 + gasFee).toFixed(4) }} AVAX</span>
            <span class="flex gap-2 align-baseline">
              <p-checkbox formControlName="agreed" [binary]="true" inputId="agreed"></p-checkbox>
              <label class="font-14 white-space-normal" for="agreed">{{ 'AUCTIONS.AGREED' | translate }}</label>
            </span>
          </div>
          <div class="nav-buttons mt-3">
            <button class="btn-primary w-full" type="submit" [disabled]="!auctionControls['agreed'].value">{{ 'AUCTIONS.CONTINUE' | translate }}</button>
            <div class="flex flex-column py-3 gap-2">
              <button type="button" class="btn-text align-self-start" (click)="step = step - 1">
                <img ngSrc="/assets/images/nav-chevron-left.svg" alt="back" height="16" width="16" />
                {{ 'AUCTIONS.BACK' | translate }}
              </button>
              <button class="btn-text align-self-center" type="button" (click)="goToHomePage()">{{ 'AUCTIONS.CANCEL' | translate }}</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
