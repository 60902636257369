import { NgModule } from '@angular/core';
import { AuthConfig, OAuthModule, OAuthModuleConfig, OAuthService, OAuthStorage, ValidationHandler } from 'angular-oauth2-oidc';
import { OAuthHelperService } from '../../services';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { environment } from '../../../environments/environment';

const config: AuthConfig = {
  issuer: environment.stsAuthority,
  clientId: environment.clientId,
  redirectUri: environment.clientRoot,
  scope: environment.clientScope,
  responseType: environment.responseType,
  silentRefreshRedirectUri: environment.silentRefreshUrl,
  logoutUrl: environment.logoutUrl,
  useSilentRefresh: environment.useSilentRefresh,
  strictDiscoveryDocumentValidation: false,
  showDebugInformation: !environment.production,
  timeoutFactor: 0.25,
  clearHashAfterLogin: true,
  sessionChecksEnabled: false,
};

const oauthModuleConfig: OAuthModuleConfig = {
  resourceServer: {
    allowedUrls: [environment.baseToggMpApiUrl],
    sendAccessToken: true,
  },
};

@NgModule({
  imports: [OAuthModule.forRoot(oauthModuleConfig)],
  providers: [
    OAuthService,
    OAuthHelperService,
    { provide: ValidationHandler, useClass: JwksValidationHandler },
    { provide: OAuthStorage, useValue: sessionStorage },
    { provide: AuthConfig, useValue: config },
  ],
  exports: [OAuthModule],
})
export class AuthModule {}
