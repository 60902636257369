import { Component } from '@angular/core';
import { ModalId } from '../../enums/modal-id';
import { ModalScreenType } from '../../enums/modal-screen-type';
import { INTRO_PAGE_SEEN, ModalsService, OAuthHelperService } from '../../services';

@Component({
  selector: 'togg-logout-modal',
  templateUrl: './logout-modal.component.html',
  styleUrls: ['./logout-modal.component.scss'],
})
export class LogoutModalComponent {
  protected readonly ModalType = ModalId;
  protected readonly ModalScreenType = ModalScreenType;

  constructor(
    private readonly modalsService: ModalsService,
    private oAuthHelperService: OAuthHelperService,
  ) {}

  onCloseButtonClickHandler() {
    this.modalsService.close(ModalId.LOGOUT);
  }

  onLogOutButtonClickHandler() {
    this.oAuthHelperService.logout();
    localStorage.removeItem(INTRO_PAGE_SEEN);
  }
}
