<header class="main-header">
  <div class="max-width-wrapper">
    <div class="nft-list-header-left">
      <img (click)="goToHomePage()" [priority]="true" alt="Trumore logo" class="nft-list-header-logo" height="38" ngSrc="/assets/images/trumore_logo.svg" width="120" />
      <h4 class="nft-list-header-title">{{ 'LIST-MAIN-HEADER.NFT-MARKET' | translate }}</h4>
    </div>

    <div class="nft-list-header-right">
      <button (click)="goToOwnedNfts()" class="btn-text bold btn-red white-space-nowrap" type="button">
        {{ 'LIST-MAIN-HEADER.SELL' | translate }}
      </button>
      <div class="filter-bar" *ngIf="showSearch">
        <button (click)="openMainSearchModal()" class="header-button search-button-area" type="button">
          <img alt="search nft" height="24" ngSrc="/assets/images/search.svg" width="24" />
        </button>
        <div [ngClass]="{ focused: isSearchInputFocused }" class="search-input-wrapper">
          <label class="search-icon-area" for="search-input">
            <img alt="search" height="20" ngSrc="/assets/images/search_icon.svg" width="20" />
          </label>
          <div class="search-input-area">
            <input
              (blur)="searchInputBlurred()"
              (focus)="searchInputFocused()"
              (input)="searchTextChanged($event)"
              [ngModel]="nftStoreService.mainSearchKeyword$ | async"
              autocomplete="off"
              class="search-input"
              id="search-input"
              inputmode="search"
              placeholder="{{ 'LIST-MAIN-HEADER.SEARCH-COLLECTIONS' | translate }}"
              toggAlphanumericTextInput
              type="search" />
          </div>
        </div>
      </div>
      <button (click)="goToIntroduction()" class="header-button" type="button">
        <img alt="info" height="20" ngSrc="/assets/images/clipboard.svg" width="20" />
      </button>

      <button (click)="openBiddingHistoryModal()" class="header-button" type="button">
        <img alt="bidded nfts" height="20" ngSrc="/assets/images/bidded-nfts.svg" width="20" />
      </button>

      <button (click)="openLogoutModal()" *ngIf="!isUserUsingMobileApp" [title]="fullNameOfUser" class="header-button version" type="button">
        <img alt="logOut" class="logout-icon" height="20" ngSrc="/assets/images/logout.svg" width="20" />
        <span class="user-name">{{ fullNameOfUser }}</span>
      </button>

      <span class="version">{{ appVersion }}</span>
    </div>
  </div>
</header>
