<div class="customer-balance">
  <div class="customer-balance-avax-icon-area">
    <img alt="avax-token" class="customer-balance-avax-icon" height="40" ngSrc="/assets/images/AVAX-red.svg" width="40" />
  </div>

  <div class="customer-balance-available-text-area">
    <span class="customer-balance-data">
      {{ balance | number: '1.0-4' }}
      <span class="avax-text">
        {{ 'Nfts-List-Page_footer.Avax' | translate }}
      </span>
    </span>

    <span class="available-balance-text">
      {{ 'Nfts-List-Page_footer.BalanceTitle' | translate }}
    </span>
  </div>
</div>
