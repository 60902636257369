<button class="collection-card" (click)="clicked.emit(collection.address)">
  <img class="collection-image" alt="Collection image" [src]="collection.bannerUrl ?? '/assets/images/banner-1.png'" />

  <div class="block p-4 pb-0 w-full">
    <span class="font-22 text-nowrap">{{ collection.name }}</span>
    <p class="font-12 lg-font-14 collection-description text-nowrap">{{ collection.description }}</p>
  </div>

  <div class="flex justify-content-evenly p-4 w-full">
    <div class="flex flex-column align-items-center">
      <span class="font-16 bold">
        {{ collection.onSaleNftsCount }}
      </span>
      <span class="font-12 bold">{{ 'COLLECTIONS.ITEMS' | translate }}</span>
    </div>
  </div>
</button>
