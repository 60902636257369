<togg-base-modal [modalId]="ModalId.NOT_ENOUGH_BALANCE" [showCloseButton]="true">
  <ng-container bodyContent>
    <div class="user-has-active-bid-error">
      <div class="user-has-active-bid-error-content">
        <div class="error-image-wrapper">
          <img alt="Error icon" height="48" ngSrc="/assets/images/error-icon.svg" width="48" />
        </div>

        <h4 class="user-has-active-bid-error-title">
          {{ 'BIDDING-PAGE-COMPONENT.NOT-BALANCE-TITLE' | translate }}
        </h4>

        <p class="user-has-active-bid-error-description">
          {{ 'BIDDING-PAGE-COMPONENT.NOT-BALANCE' | translate }}
        </p>
      </div>
    </div>
  </ng-container>
</togg-base-modal>
