import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { ITrackingFunction } from './instana-monitoring.service.types';
import { environment } from '../../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { LoadingStatus } from '../../enums/loading-status.enum';

@Injectable({
  providedIn: 'root',
})
export class InstanaMonitoringService {
  private renderer: Renderer2;
  private scriptElement?: HTMLScriptElement;
  private loadingStatus = new BehaviorSubject(LoadingStatus.NOT_LOADED);

  constructor(private readonly rendererFactory: RendererFactory2) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  public loadInstanaScript() {
    if ([LoadingStatus.LOADED, LoadingStatus.LOADING].includes(this.loadingStatus.value)) {
      return;
    }

    //don't load the instana if config is empty
    if (!environment.instanaEumJsUrl || !environment.instanaKey || !environment.instanaReportingUrl) {
      return;
    }

    this.loadingStatus.next(LoadingStatus.LOADING);
    this.initInstanaScript();
    this.loadExternalScript();
    this.loadingStatus.next(LoadingStatus.LOADED);
  }

  private initInstanaScript() {
    if (!window['InstanaEumObject']) {
      window['InstanaEumObject'] = 'ineum';

      const trackingFunction = function () {
        trackingFunction.queue.push(arguments);
      } as unknown as ITrackingFunction;

      trackingFunction.queue = [];
      trackingFunction.version = 2;
      trackingFunction.timestamp = new Date().getTime();
      window['ineum'] = trackingFunction;
    }

    window.ineum('reportingUrl', environment.instanaReportingUrl);
    window.ineum('key', environment.instanaKey);
    window.ineum('trackSessions');
  }

  private loadExternalScript() {
    this.scriptElement = this.renderer.createElement('script');
    this.renderer.setAttribute(this.scriptElement, 'src', environment.instanaEumJsUrl);
    this.renderer.setAttribute(this.scriptElement, 'defer', 'true');
    this.renderer.setAttribute(this.scriptElement, 'crossorigin', 'anonymous');
    this.renderer.appendChild(document.body, this.scriptElement);
  }
}
