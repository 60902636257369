import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalScreenType } from '../../enums/modal-screen-type';
import { ModalId } from '../../enums/modal-id';
import { BehaviorSubject, Subscription } from 'rxjs';
import { CustomerStoreService, ModalsService } from '../../services';
import { CustomerDto, CustomersService } from '@togg-trumore/toggens-customers-api-client';
import { LoadingStatus } from '../../enums/loading-status.enum';
import { PageUrls } from '../../enums/page-urls.enum';
import { Router } from '@angular/router';

@Component({
  selector: 'togg-whitelist-modal',
  templateUrl: './whitelist-modal.component.html',
  styleUrls: ['./whitelist-modal.component.scss'],
})
export class WhitelistModalComponent implements OnInit, OnDestroy {
  enoughBalance = false;
  whitelistFee = '';
  whitelistingOnError = false;
  isWhitelistingInProgress = false;
  balance?: string;
  isCustomerNotWhitelisted = false;
  protected readonly ModalScreenType = ModalScreenType;
  protected readonly ModalId = ModalId;
  protected readonly LoadingStatus = LoadingStatus;
  protected readonly WhitelistingEnum = CustomerDto.WhitelistingEnum;
  private whitelistFeeLoadingStatus = new BehaviorSubject<LoadingStatus>(LoadingStatus.NOT_LOADED);
  whitelistFeeLoadingStatus$ = this.whitelistFeeLoadingStatus.asObservable();
  private readonly customerDataObservable$;
  private customerDataSubscription?: Subscription;
  private modalSubscription?: Subscription;
  private lastUpdateDateWhitelistFee?: Date;

  constructor(
    private readonly customerService: CustomerStoreService,
    private readonly customerApi: CustomersService,
    private readonly modalsService: ModalsService,
    private readonly router: Router,
  ) {
    this.customerDataObservable$ = this.customerService.getCustomerDataObservable();
  }

  ngOnInit() {
    this.customerDataSubscription = this.customerDataObservable$.subscribe(data => {
      if (!data) {
        return;
      }

      this.balance = data.balance?.avaxBalance?.availableAmount;
      this.isCustomerNotWhitelisted = data.whitelisting === this.WhitelistingEnum.NOT_WHITELISTED;

      if (data.whitelisting === this.WhitelistingEnum.NOT_WHITELISTED) {
        this.isCustomerNotWhitelisted = true;
      } else if (data.whitelisting !== this.WhitelistingEnum.WHITELISTED && data.whitelisting !== this.WhitelistingEnum.IN_PROGRESS) {
        this.whitelistingOnError = true;
      }
    });

    this.modalSubscription = this.modalsService.modalState$.subscribe(modalIds => this.modalStateChange(modalIds));
  }

  modalStateChange(modalIds: ModalId[]) {
    if (modalIds.includes(ModalId.BECOME_WHITELISTED) && this.isCustomerNotWhitelisted) {
      if (!this.lastUpdateDateWhitelistFee || new Date().getTime() - this.lastUpdateDateWhitelistFee.getTime() > 60000) {
        this.estimateFee();
        this.lastUpdateDateWhitelistFee = new Date();
      }
    }
  }

  estimateFee() {
    this.whitelistFeeLoadingStatus.next(LoadingStatus.LOADING);

    this.customerApi.dryRunWhitelist().subscribe({
      next: res => {
        this.whitelistFee = res.fee;

        if (this.balance && this.whitelistFee) {
          this.enoughBalance = Number(this.balance) >= Number(this.whitelistFee);
        }

        this.whitelistFeeLoadingStatus.next(LoadingStatus.LOADED);
      },
      error: () => {
        this.whitelistFeeLoadingStatus.next(LoadingStatus.ERROR);
      },
    });
  }

  ngOnDestroy() {
    this.customerDataSubscription?.unsubscribe();
    this.modalSubscription?.unsubscribe();
  }

  whitelistedUser() {
    if (!this.enoughBalance) {
      return;
    }

    this.isWhitelistingInProgress = true;

    this.customerApi.whitelistCustomer().subscribe({
      next: () => {
        this.modalsService.close(ModalId.BECOME_WHITELISTED);
        this.isWhitelistingInProgress = false;
        this.router.navigate([PageUrls.HOME]);
      },
      error: () => {
        this.whitelistingOnError = true;
        this.isWhitelistingInProgress = false;
      },
    });
  }
}
