import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule, provideAnimations } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSliderModule } from 'ngx-slider-v2';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ClipboardModule } from 'ngx-clipboard';
import { NgOptimizedImage } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';

import { register } from 'swiper/element/bundle';

import { AlphanumericInputDirective, CommaInputDirective, TwoDigitsDecimalNumberTextInputDirective } from './directives';

import { ErrorPageComponent, IntroductionPageComponent, LoginPageComponent, NftDetailsPageComponent, HomePageComponent, WalletNotExistsPageComponent } from './pages';

import {
  AccordionComponent,
  BaseModalComponent,
  BidCardComponent,
  BiddingCardComponent,
  BiddingHistoryComponent,
  BidSubmissionConfirmationModalComponent,
  BidSubmissionModalComponent,
  CountdownTimerComponent,
  CustomerBalanceComponent,
  HighlightedComponent,
  ImageWithFallbackAndLoaderComponent,
  InfoPersonComponent,
  LogoutModalComponent,
  MainHeaderComponent,
  NftDetailsHeaderComponent,
  NftDetailsSwiperComponent,
  NftListBannerComponent,
  NftListItemComponent,
  NftListMobileFilterButtonComponent,
  NftSearchModalComponent,
  MainSearchModalComponent,
  NftsListHeaderComponent,
  NotEnoughBalanceModalComponent,
  PresaleModalComponent,
  RarityComponent,
  SelectComponent,
  ToggleButtonComponent,
  WhitelistingPausedModalComponent,
  WhitelistModalComponent,
  NftListPageComponent,
} from './components';

import { ApiClientModule, AppRoutingModule, AuthModule, SocketModule, ToastModule, TranslationModule } from './modules';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { SellNftPageComponent } from './pages/sell-nft-page/sell-nft-page.component';
import { CalendarModule } from 'primeng/calendar';
import { InputNumberModule } from 'primeng/inputnumber';
import { TabViewModule } from 'primeng/tabview';
import { SelectButtonModule } from 'primeng/selectbutton';
import { CarouselModule } from 'primeng/carousel';
import { InputSwitchModule } from 'primeng/inputswitch';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { StepTabsComponent } from './components/step-tabs/step-tabs.component';
import { ClipboardButtonComponent } from './components/clipboard-button/clipboard-button.component';
import { CollectionCardComponent } from './components/collection-card/collection-card.component';
import { CollectionPageComponent } from './pages/collection-page/collection-page.component';
import { ErrorModalComponent } from './components/error-modal/error-modal.component';
import { NftFilterComponent } from './components/nft-filter/nft-filter.component';

const PAGES = [
  LoginPageComponent,
  IntroductionPageComponent,
  HomePageComponent,
  NftDetailsPageComponent,
  ErrorPageComponent,
  WalletNotExistsPageComponent,
  SellNftPageComponent,
  CollectionPageComponent,
  NftListPageComponent,
];

const COMPONENTS = [
  NftListItemComponent,
  NftsListHeaderComponent,
  MainHeaderComponent,
  CustomerBalanceComponent,
  NftListBannerComponent,
  NftListMobileFilterButtonComponent,
  BiddingCardComponent,
  NftDetailsHeaderComponent,
  BidSubmissionModalComponent,
  BidSubmissionConfirmationModalComponent,
  NftDetailsSwiperComponent,
  RarityComponent,
  ImageWithFallbackAndLoaderComponent,
  BidCardComponent,
  HighlightedComponent,
  InfoPersonComponent,
  CountdownTimerComponent,
  ToggleButtonComponent,
  SelectComponent,
  AccordionComponent,
  StepTabsComponent,
  ClipboardButtonComponent,
  CollectionCardComponent,
  NftFilterComponent,
];

const DIRECTIVES = [TwoDigitsDecimalNumberTextInputDirective, AlphanumericInputDirective, CommaInputDirective];

const CUSTOM_MODULES = [AppRoutingModule, AuthModule, ApiClientModule, SocketModule, ApiClientModule, TranslationModule, ToastModule, FormsModule, ReactiveFormsModule];

const PRIME_MODULES = [CalendarModule, InputNumberModule, TabViewModule, SelectButtonModule, CarouselModule, InputSwitchModule, CheckboxModule];

const MODALS = [
  BaseModalComponent,
  BiddingHistoryComponent,
  LogoutModalComponent,
  NftSearchModalComponent,
  MainSearchModalComponent,
  AlphanumericInputDirective,
  WhitelistModalComponent,
  NotEnoughBalanceModalComponent,
  PresaleModalComponent,
  WhitelistingPausedModalComponent,
  ConfirmationModalComponent,
  ErrorModalComponent,
];

@NgModule({
  declarations: [AppComponent, ...PAGES, ...COMPONENTS, ...DIRECTIVES, ...MODALS],
  imports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    InfiniteScrollModule,
    ClipboardModule,
    NgxSliderModule,
    NgOptimizedImage,
    NgSelectModule,
    ...CUSTOM_MODULES,
    ...PRIME_MODULES,
  ],
  providers: [
    provideAnimations(),
    {
      provide: APP_INITIALIZER,
      useFactory: () => register,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
