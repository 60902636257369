import { Injectable } from '@angular/core';
import { NftOperationDto, NftOperationsResponseDto, NftsService } from '@togg-trumore/toggens-operations-api-client';
import { BehaviorSubject, catchError, Subscription } from 'rxjs';
import { OAuthHelperService } from '../o-auth-helper/o-auth-helper.service';
import { LoadingStatus } from '../../enums/loading-status.enum';
import { BidType } from './auction-store.service.types';
import { ReceivedBiddingMessage } from '../socket/socket.service.types';
import { ConfirmationMessage } from './confirmation-message-type';

@Injectable({
  providedIn: 'root',
})
export class AuctionStoreService {
  private static readonly PAGE_SIZE = 11;

  private bidHistoryFirstPageLoadStatus = new BehaviorSubject<LoadingStatus>(LoadingStatus.NOT_LOADED);
  public readonly bidHistoryFirstPageLoadStatusObservable$ = this.bidHistoryFirstPageLoadStatus.asObservable();
  private bidHistoryNextPageLoadStatus = new BehaviorSubject<LoadingStatus>(LoadingStatus.NOT_LOADED);
  public readonly bidHistoryNextPageLoadStatusObservable$ = this.bidHistoryNextPageLoadStatus.asObservable();

  private bidHistory = new BehaviorSubject<NftOperationDto[]>([]);
  public readonly bidHistoryObservable$ = this.bidHistory.asObservable();

  private currentPage = 0;
  private isNextPageAvailable = false;

  private loadBidSubscription?: Subscription;

  private bidValue = new BehaviorSubject<number>(0);
  public readonly bidValueObservable$ = this.bidValue.asObservable();

  private bidType = new BehaviorSubject<BidType>(BidType.BID);
  public readonly bidTypeObservable$ = this.bidType.asObservable();

  private sellState = new BehaviorSubject<boolean>(true);
  public readonly sellState$ = this.sellState.asObservable();

  private confirmationMessage = new BehaviorSubject<ConfirmationMessage | null>(null);
  public readonly confirmationMessage$ = this.confirmationMessage.asObservable();

  private errorMessage = new BehaviorSubject<{ header?: string; text: string } | null>(null);
  public readonly errorMessage$ = this.errorMessage.asObservable();

  constructor(
    private readonly oAuthHelperService: OAuthHelperService,
    private readonly nftsService: NftsService,
  ) {}

  public loadBidHistory(): void {
    if (this.shouldAbortLoading()) return;

    if (this.currentPage === 0) {
      this.bidHistoryFirstPageLoadStatus.next(LoadingStatus.LOADING);
    } else {
      this.bidHistoryNextPageLoadStatus.next(LoadingStatus.LOADING);
    }

    this.loadBidSubscription = this.nftsService
      .getCustomerNftOperationsByFilter(
        this.oAuthHelperService.getTruId(),
        'DESC',
        [
          'BIDDING',
          'BURNING',
          'BUY',
          'CANCEL_AUCTION',
          'CANCEL_LISTING',
          'CLOSE_AUCTION',
          'EDIT_LISTING',
          'HIGHLIGHTING',
          'LISTING',
          'MINTING',
          'MINTING_LISTING',
          'MINTING_SELLING',
          'SELLING',
          'TRANSACTION',
        ],
        ['BEATEN', 'CANCELLED', 'CREATED', 'ERROR', 'IN_PROGRESS', 'PAYMENT_ERROR', 'RELEASED', 'WON'],
        this.currentPage,
        AuctionStoreService.PAGE_SIZE,
      )
      .pipe(
        catchError(error => {
          if (this.currentPage === 0) {
            this.bidHistoryFirstPageLoadStatus.next(LoadingStatus.ERROR);
          } else {
            this.bidHistoryNextPageLoadStatus.next(LoadingStatus.ERROR);
          }
          this.loadBidSubscription?.unsubscribe();
          throw error;
        }),
      )
      .subscribe(this.handleLoadingSuccess.bind(this));
  }

  changeBidValue(value: number): void {
    this.bidValue.next(value);
  }

  changeBidType(type: BidType): void {
    this.bidType.next(type);
  }

  setSellState(state: boolean): void {
    this.sellState.next(state);
  }

  setError(text: string, header?: string): void {
    this.errorMessage.next({ header, text });
  }

  setConfirmation(confirmationMessage: ConfirmationMessage): void {
    this.confirmationMessage.next(confirmationMessage);
  }

  public updateOrAddBidHistoryFromWebsocket(receivedBid: ReceivedBiddingMessage) {
    let computedBidHistory = this.bidHistory.getValue();

    const bidIndex = computedBidHistory.findIndex(bid => bid.nftOperationId === receivedBid.nftOperationId);
    if (bidIndex > -1) {
      computedBidHistory[bidIndex] = { ...computedBidHistory[bidIndex], ...(receivedBid as NftOperationDto) };
    } else {
      computedBidHistory = [receivedBid as NftOperationDto, ...computedBidHistory];
    }

    computedBidHistory = computedBidHistory.map(bid => {
      if (bid.nft?.nftInternalId === receivedBid.nft.nftInternalId) {
        bid.nft.price = receivedBid.nft.price;
      }

      return bid;
    });

    computedBidHistory = computedBidHistory.reduce<NftOperationDto[]>((acc, bid) => {
      return acc.findIndex(b2 => b2.nft.nftInternalId === bid.nft.nftInternalId) > -1 ? acc : [...acc, bid];
    }, []);

    this.bidHistory.next(computedBidHistory);
  }

  private shouldAbortLoading(): boolean {
    const isLoadingFirstPage = this.currentPage === 0;
    return (
      (isLoadingFirstPage && this.bidHistoryFirstPageLoadStatus.getValue() === LoadingStatus.LOADING) ||
      (!isLoadingFirstPage && this.bidHistoryNextPageLoadStatus.getValue() === LoadingStatus.LOADING) ||
      (!isLoadingFirstPage && !this.isNextPageAvailable)
    );
  }

  private handleLoadingSuccess(response: NftOperationsResponseDto): void {
    this.updateNextPageAvailability(response.operations ?? []);
    this.updateBidHistory(response.operations ?? []);
    this.markAsLoaded();

    this.currentPage++;
    this.loadBidSubscription?.unsubscribe();
  }

  private updateNextPageAvailability(response: NftOperationDto[]): void {
    this.isNextPageAvailable = response.length === AuctionStoreService.PAGE_SIZE;
  }

  private updateBidHistory(response: NftOperationDto[]): void {
    let newBidHistory;
    if (this.isNextPageAvailable) {
      newBidHistory = [...this.bidHistory.getValue(), ...response.slice(0, -1)];
    } else {
      newBidHistory = [...this.bidHistory.getValue(), ...response];
    }

    newBidHistory = newBidHistory.reduce<NftOperationDto[]>((acc, bid) => {
      return acc.findIndex(b2 => b2.nft.nftInternalId === bid.nft.nftInternalId) > -1 ? acc : [...acc, bid];
    }, []);

    this.bidHistory.next(newBidHistory);
  }

  private markAsLoaded(): void {
    if (this.currentPage === 0) {
      this.bidHistoryFirstPageLoadStatus.next(LoadingStatus.LOADED);
    } else {
      this.bidHistoryNextPageLoadStatus.next(LoadingStatus.LOADED);
    }
  }
}
