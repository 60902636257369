import { Component } from '@angular/core';
import { ModalId } from 'src/app/enums/modal-id';
import { AuctionStoreService, ModalsService } from 'src/app/services';

@Component({
  selector: 'togg-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss'],
})
export class ErrorModalComponent {
  protected readonly ModalId = ModalId;
  constructor(
    private readonly modalsService: ModalsService,
    protected readonly auctionStoreService: AuctionStoreService,
  ) {}

  close() {
    this.modalsService.close(ModalId.ERROR);
  }
}
