import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[toggCommaInput]',
})
export class CommaInputDirective {
  constructor(private el: ElementRef) {}

  @HostListener('keyup', ['$event'])
  onKeyUp(event: KeyboardEvent) {
    const element = this.el.nativeElement.getElementsByTagName('input')[0];

    if (!element || element.value.indexOf(',') > -1) {
      return;
    }

    if (element.value === 'NaN') {
      element.value = '';
      return;
    }

    if (event.key === '.' && element.value.length > 0) {
      element.value += ',';
    }
  }
}
