<div [class]="'accordion ' + (isOpen ? openedExtraClass : closedExtraClass)" [ngClass]="{ opened: isOpen }">
  <h6 (click)="toggleAccordion()" class="accordion-title">
    {{ titleText }}

    <img alt="chevron" class="chevron" height="8" ngSrc="/assets/images/chevron.svg" width="14" />
  </h6>

  <div class="accordion-content">
    <ng-content select="[accordionContent]"></ng-content>
  </div>
</div>
