import { CustomerDto } from '@togg-trumore/toggens-customers-api-client';
import { WhitelistedEnum } from '../enums/whitelisted.enum';
import WhitelistingEnum = CustomerDto.WhitelistingEnum;

export const nftWhitelistingToWhitelistedMapper = (value: WhitelistingEnum): WhitelistedEnum => {
  switch (value) {
    case 'NOT_WHITELISTED':
      return WhitelistedEnum.NOT_WHITELISTED;
    case 'IN_PROGRESS':
      return WhitelistedEnum.IN_PROGRESS;
    case 'WHITELISTED':
      return WhitelistedEnum.WHITELISTED;
    case 'TRANSFER_ERROR':
      return WhitelistedEnum.TRANSFER_ERROR;
    case 'REGISTER_ERROR':
      return WhitelistedEnum.REGISTER_ERROR;
    case 'BLOCKED':
      return WhitelistedEnum.BLOCKED;
    default:
      return WhitelistedEnum.IN_PROGRESS;
  }
};
