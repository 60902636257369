<router-outlet (activate)="onActivate()"></router-outlet>
<togg-bidding-history></togg-bidding-history>
<togg-logout-modal></togg-logout-modal>
<togg-nft-search-modal></togg-nft-search-modal>
<togg-main-search-modal></togg-main-search-modal>
<togg-nft-filter-modal></togg-nft-filter-modal>
<togg-whitelist-modal></togg-whitelist-modal>
<togg-bid-submission-modal></togg-bid-submission-modal>
<togg-bid-submission-confirmation-modal></togg-bid-submission-confirmation-modal>
<togg-not-enough-balance-modal></togg-not-enough-balance-modal>
<togg-has-active-bid-modal></togg-has-active-bid-modal>
<togg-presale-modal></togg-presale-modal>
<togg-whitelisting-paused-modal></togg-whitelisting-paused-modal>
<togg-confirmation-modal></togg-confirmation-modal>
<togg-error-modal></togg-error-modal>
<div *ngIf="guardsAreWorking" class="center">
  <div class="spinner-8bit"></div>
</div>
