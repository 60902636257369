<togg-base-modal [modalId]="ModalType.LOGOUT" [screenType]="ModalScreenType.NORMAL" [showCloseButton]="true">
  <div bodyContent class="logout-modal-body-content">
    <img alt="" class="logout-icon" height="69" ngSrc="/assets/images/logout.svg" width="77" />
    <h2 class="logout-main-title">{{ 'LOGOUT.MAIN-TITLE' | translate }}</h2>
    <span class="logout-main-subtitle">{{ 'LOGOUT.MAIN-SUBTITLE' | translate }}</span>
  </div>
  <div class="logout-modal-footer-content" footerContent>
    <div class="logout-list-header-left">
      <button (click)="onCloseButtonClickHandler()" class="cancel-button" type="button">
        <span class="">{{ 'LOGOUT.CANCEL' | translate }}</span>
      </button>
    </div>
    <div class="logout-list-header-right">
      <button (click)="onLogOutButtonClickHandler()" class="start-button" type="button">
        <span class="">{{ 'LOGOUT.LOGOUT-ME' | translate }}</span>
      </button>
    </div>
  </div>
</togg-base-modal>
