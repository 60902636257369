import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NftCollectionDto, NftsService } from '@togg-trumore/toggens-operations-api-client';
import { Subject, debounceTime, distinctUntilChanged, filter, forkJoin, map, switchMap, takeUntil, tap } from 'rxjs';
import { PageUrls } from 'src/app/enums/page-urls.enum';
import { CustomerStoreService, ModalsService, Nft, NftStoreService } from 'src/app/services';
import { formatWalletAddress } from 'src/app/utils/formatWalletAddress';
import { LoadingStatus } from '../../enums/loading-status.enum';
import { ModalId } from 'src/app/enums/modal-id';
import { nftWhitelistingToWhitelistedMapper } from 'src/app/mappers/nft-whitelisting-to-whitelisted.mapper';
import { WhitelistedEnum } from 'src/app/enums/whitelisted.enum';

@Component({
  selector: 'togg-collection-page',
  templateUrl: './collection-page.component.html',
  styleUrls: ['./collection-page.component.scss'],
})
export class CollectionPageComponent implements OnInit, OnDestroy {
  protected readonly formatWalletAddress = formatWalletAddress;
  isSearchInputFocused = false;
  isMobile = true;
  private ngOnDestroy$ = new Subject<void>();
  private searchTextChanged$ = new Subject<string>();

  public collection?: NftCollectionDto;
  public readonly NftLoadingStatus = LoadingStatus;
  public uniqueOwners?: number;
  public totalVolume?: number;
  isNotWhitelisted = false;

  constructor(
    private readonly activeRoute: ActivatedRoute,
    private readonly nftsService: NftsService,
    protected readonly nftStoreService: NftStoreService,
    public readonly customerStoreService: CustomerStoreService,
    private readonly router: Router,
    private readonly breakpointObserver: BreakpointObserver,
    private readonly modalsService: ModalsService,
  ) {}

  ngOnInit(): void {
    this.breakpointObserver
      .observe(['(min-width: 768px)'])
      .pipe(takeUntil(this.ngOnDestroy$))
      .subscribe((result: BreakpointState) => {
        if (result.matches) {
          this.isMobile = false;
        }
      });

    this.activeRoute.params
      .pipe(
        map(params => params['collection']),
        filter(value => !!value),
        tap((collectionAddress: string) => {
          this.nftStoreService.setCollectionAddress(collectionAddress);
          this.nftStoreService.saveSearchNftKeyword('');
        }),
        switchMap((collectionAddress: string) => forkJoin([this.nftsService.getNftCollection(collectionAddress), this.nftsService.getCollectionDetails(collectionAddress)])),
      )
      .subscribe(([collection, details]) => {
        this.collection = collection;
        this.totalVolume = details.totalVolume;
        this.uniqueOwners = details.totalUniqueOwners;
      });

    this.searchTextChanged$.pipe(takeUntil(this.ngOnDestroy$), debounceTime(300), distinctUntilChanged()).subscribe(searchValue => {
      this.nftStoreService.saveSearchNftKeyword(searchValue);
    });

    this.customerStoreService
      .getCustomerDataObservable()
      .pipe(takeUntil(this.ngOnDestroy$))
      .subscribe(value => {
        if (value?.whitelisting) {
          this.isNotWhitelisted = nftWhitelistingToWhitelistedMapper(value.whitelisting) === WhitelistedEnum.NOT_WHITELISTED;
        }
      });
  }

  async navigateToNftDetails($event: Nft) {
    if (this.isNotWhitelisted) {
      this.modalsService.open(ModalId.BECOME_WHITELISTED);
      return;
    }

    this.nftStoreService.setSelectedNftItemForDetailsPage($event);
    const navigateUrl = [PageUrls.NFT_DETAILS.replace('/:collection', '').replace('/:id', ''), $event.collectionAddress, $event.nftId];
    await this.router.navigate(navigateUrl);
  }

  searchCollection(searchValue: string) {
    this.nftStoreService.saveSearchNftKeyword(searchValue);
  }

  onScrollDown() {
    this.nftStoreService.loadNextPage();
  }

  ngOnDestroy(): void {
    this.nftStoreService.clearNfts();
    this.ngOnDestroy$.next();
    this.ngOnDestroy$.complete();
    this.nftStoreService.saveSearchNftKeyword('');
  }

  nftSearchTextChanged($event: Event) {
    const inputValue = ($event.target as HTMLInputElement)?.value;
    this.searchTextChanged$.next(inputValue);
  }

  searchInputBlurred() {
    this.isSearchInputFocused = false;
  }

  searchInputFocused() {
    this.isSearchInputFocused = true;
  }

  openNftSearchModal() {
    this.modalsService.open(ModalId.NFT_SEARCH);
  }
}
