import { Component, OnDestroy, OnInit } from '@angular/core';
import { CustomerStoreService } from '../../services';
import { Subscription } from 'rxjs';

@Component({
  selector: 'togg-customer-balance',
  templateUrl: './customer-balance.component.html',
  styleUrls: ['./customer-balance.component.scss'],
})
export class CustomerBalanceComponent implements OnInit, OnDestroy {
  balance?: string;
  private readonly customerDataObservable$;
  private customerDataSubscription?: Subscription;

  constructor(private readonly customerService: CustomerStoreService) {
    this.customerDataObservable$ = this.customerService.getCustomerDataObservable();
  }

  ngOnInit() {
    this.customerDataSubscription = this.customerDataObservable$.subscribe(data => {
      this.balance = data?.balance?.avaxBalance?.availableAmount;
    });
  }

  ngOnDestroy() {
    this.customerDataSubscription?.unsubscribe();
  }
}
