import { NgModule } from '@angular/core';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { environment } from '../../../environments/environment';

const socketIoConfig: SocketIoConfig = {
  url: environment.socketUrl,
  options: {
    transports: ['websocket'],
    upgrade: false,
    autoConnect: false,
  },
};

@NgModule({
  imports: [SocketIoModule.forRoot(socketIoConfig)],
  exports: [SocketIoModule],
})
export class SocketModule {}
