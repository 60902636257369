<togg-base-modal [modalId]="ModalId.BECOME_WHITELISTED" [screenType]="ModalScreenType.NORMAL" [showCloseButton]="true">
  <div bodyContent class="whitelist-modal-body-content">
    <div class="info-icon">
      <img alt="" class="nfts-list-footer-avax-icon" height="33" ngSrc="assets/images/info-banner-modal.svg" width="32" />
    </div>

    <h4 class="whitelist-modal-drawer-title">
      {{ (whitelistingOnError ? 'BANNER.MIN-AVAX-NEEDED-ERROR' : 'BANNER.MIN-AVAX-NEEDED') | translate }}
    </h4>

    <div [innerHtml]="'BANNER.MIN-AVAX-TEXT' | translate" class="whitelist-modal-no-bid black-and-bold-text"></div>
    <div [innerHtml]="'BANNER.TEXT-CLARIFY' | translate" class="whitelist-modal-no-bid black-and-bold-text"></div>

    <ng-container *ngIf="whitelistFeeLoadingStatus$ | async as whitelistFeeLoadingStatus">
      <div *ngIf="whitelistFeeLoadingStatus === LoadingStatus.LOADING" class="spinner-8bit-wrapper">
        <div class="spinner-8bit"></div>
      </div>

      <ng-container *ngIf="whitelistFeeLoadingStatus === LoadingStatus.LOADED">
        <div class="whitelist-modal-no-bid-bold balance">{{ whitelistFee | number: '1.1-4' }} {{ 'SHARED.AVAX' | translate }}</div>

        <div *ngIf="enoughBalance" class="whitelist-modal-no-bid">
          {{ 'BANNER.INFO-WHITELIST-FEE' | translate }}
        </div>

        <!-- If not enough balance:  -->
        <div *ngIf="!enoughBalance">
          <div class="whitelist-modal-no-bid error">
            {{ 'BANNER.MIN-AVAX-NOT-AVAILABLE' | translate }}
          </div>

          <div class="whitelist-modal-no-bid-no-balance">
            {{ 'BANNER.PLEASE' | translate }}
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="whitelistFeeLoadingStatus === LoadingStatus.ERROR">
        <div class="whitelist-modal-whitelist-fee-error">
          <div>
            {{ 'BANNER.NOT-LOADED-WHITELIST-FEE' | translate }}
          </div>
          <img (click)="estimateFee()" alt="" height="24" ngSrc="/assets/images/try-again-danger.svg" width="24" />
        </div>
      </ng-container>
    </ng-container>
  </div>

  <div class="whitelist-modal-footer-content" footerContent>
    <div [ngClass]="{ 'button-bottom': enoughBalance, 'button-bottom-xl': !enoughBalance }">
      <button (click)="whitelistedUser()" *ngIf="!isWhitelistingInProgress" [disabled]="!enoughBalance" class="whitelist-button" type="button">
        {{ (whitelistingOnError ? 'BANNER.ADD-ME-WHITELIST-ERROR' : 'BANNER.ADD-ME-WHITELIST') | translate }}
      </button>
      <div *ngIf="isWhitelistingInProgress" class="spinner-8bit-wrapper">
        <div class="spinner-8bit"></div>
      </div>
    </div>
  </div>
</togg-base-modal>
