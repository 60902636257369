import { Component, Input } from '@angular/core';
import { NftDto } from '@togg-trumore/toggens-operations-api-client';

@Component({
  selector: 'togg-rarity',
  templateUrl: './rarity.component.html',
  styleUrls: ['./rarity.component.scss'],
})
export class RarityComponent {
  rarityEnum = NftDto.RarityEnum;
  @Input() rarity = NftDto.RarityEnum.NORMAL;
}
