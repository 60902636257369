import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuctionStoreService, CustomerStoreService, ModalsService, Nft, NftStoreService } from '../../services';
import { ModalScreenType } from '../../enums/modal-screen-type';
import { ModalId } from '../../enums/modal-id';
import { BehaviorSubject, Subscription } from 'rxjs';
import { PageUrls } from '../../enums/page-urls.enum';
import { LoadingStatus } from '../../enums/loading-status.enum';
import { NftsService } from '@togg-trumore/toggens-operations-api-client';
import { BidType } from '../../services/auction-store/auction-store.service.types';

@Component({
  selector: 'togg-bid-submission-confirmation-modal',
  templateUrl: './bid-submission-confirmation-modal.component.html',
  styleUrls: ['./bid-submission-confirmation-modal.component.scss'],
})
export class BidSubmissionConfirmationModalComponent implements OnInit, OnDestroy {
  nftItem: Nft | null = null;
  value: number | null = 0.01;
  isConfirming: boolean = false;
  isItDirectBuy: boolean = false;
  isSuccess: boolean = false;
  isFailed: boolean = false;

  failedErrorTitle = 'CONFIRMATION-MODAL.ERROR';
  failedErrorSubtitle = 'CONFIRMATION-MODAL.ERROR_SUB';
  failedErrorButton = 'ERROR-PAGE-BUTTON.RETRY';

  feeValue: number | null = null;
  isGasFeeDescriptionOpened = false;
  balance?: string;
  enoughBalance = false;
  protected readonly ModalScreenType = ModalScreenType;
  protected readonly ModalId = ModalId;
  protected readonly LoadingStatus = LoadingStatus;
  private lastUpdateDateBidFee?: Date;
  private bidFeeLoadingStatus = new BehaviorSubject<LoadingStatus>(LoadingStatus.NOT_LOADED);
  bidFeeLoadingStatus$ = this.bidFeeLoadingStatus.asObservable();
  private selectedNftItemSubscription?: Subscription;
  private modalSubscription?: Subscription;
  private auctionBidValueSubscription?: Subscription;
  private auctionBidTypeSubscription?: Subscription;
  private readonly customerDataObservable$;
  private customerDataSubscription?: Subscription;

  constructor(
    private readonly router: Router,
    private readonly nftStoreService: NftStoreService,
    private readonly modalsService: ModalsService,
    private readonly nftsService: NftsService,
    public readonly auctionStoreService: AuctionStoreService,
    private readonly customerService: CustomerStoreService,
  ) {
    this.customerDataObservable$ = this.customerService.getCustomerDataObservable();
  }

  ngOnInit() {
    this.initModalSubscription();
    this.initCustomerData();
    this.initAuctionBidValueSubscription();
    this.initAuctionBidTypeSubscription();
    this.initSelectedNftItemSubscription();
  }

  ngOnDestroy() {
    this.customerDataSubscription?.unsubscribe();
    this.selectedNftItemSubscription?.unsubscribe();
    this.modalSubscription?.unsubscribe();
    this.auctionBidValueSubscription?.unsubscribe();
    this.auctionBidTypeSubscription?.unsubscribe();
  }

  modalStateChange(modalIds: ModalId[]) {
    if (modalIds.includes(ModalId.BID_SUBMISSION_CONFIRMATION)) {
      if (!this.lastUpdateDateBidFee || new Date().getTime() - this.lastUpdateDateBidFee.getTime() > 1000) {
        this.estimateFee();
        this.lastUpdateDateBidFee = new Date();
      }
    } else {
      this.returnToConfirmationStep();
    }
  }

  async goToHomePage() {
    this.modalsService.close(ModalId.BID_SUBMISSION_CONFIRMATION);
    await this.router.navigate([PageUrls.HOME]);
    this.modalsService.open(ModalId.BIDDING_HISTORY);
  }

  returnToConfirmationStep() {
    this.isConfirming = false;
    this.isFailed = false;
    this.isSuccess = false;
  }

  confirm() {
    const bidFeeLoadingStatus = this.bidFeeLoadingStatus.getValue();
    if (this.isConfirming || this.isSuccess || this.isFailed || !this.nftItem || !this.value || bidFeeLoadingStatus !== LoadingStatus.LOADED || !this.enoughBalance) {
      return;
    }

    this.isConfirming = true;

    this.nftsService
      .nftBidding({
        bid: this.value,
        nftId: this.nftItem.nftId,
        collectionAddress: this.nftItem.collectionAddress,
        listingId: this.nftItem.listingId,
      })
      .subscribe({
        next: () => {
          this.isConfirming = false;
          this.isFailed = false;
          this.isSuccess = true;
        },
        error: err => {
          if (err.error.code === '1248' || err.error.code === '1245') {
            // Bid is loading
            this.failedErrorTitle = 'CONFIRMATION-MODAL.ERROR_BID_TITLE';
            this.failedErrorSubtitle = 'CONFIRMATION-MODAL.ERROR_BID_SUB';
            this.failedErrorButton = 'CONFIRMATION-MODAL.VIEW';
          } else if (err.error.code === '1247') {
            // Buy now is loading
            this.failedErrorTitle = 'CONFIRMATION-MODAL.ERROR_BUY_NOW_TITLE';
            this.failedErrorSubtitle = 'CONFIRMATION-MODAL.ERROR_BUY_NOW_SUB';
            this.failedErrorButton = 'CONFIRMATION-MODAL.VIEW';
          } else if (err.error.code === '1047') {
            // "The bid was placed out of time. Auction end date is 2023-09-21 08:20:00.0 and bid was placed on Thu Sep 21 08:20:09 GMT 2023"
            this.failedErrorTitle = 'CONFIRMATION-MODAL.ERROR_NOT_ON_SALE_TITLE';
            this.failedErrorSubtitle = 'CONFIRMATION-MODAL.ERROR_NOT_ON_SALE_SUB';
            this.failedErrorButton = 'CONFIRMATION-MODAL.VIEW';
          } else if (err.error.code === '1244') {
            // "The nft has been sold."
            this.failedErrorTitle = 'CONFIRMATION-MODAL.ERROR_NOT_ON_SALE_TITLE';
            this.failedErrorSubtitle = 'CONFIRMATION-MODAL.ERROR_NOT_ON_SALE_SUB';
            this.failedErrorButton = 'CONFIRMATION-MODAL.VIEW';
          } else {
            this.failedErrorTitle = 'CONFIRMATION-MODAL.ERROR';
            this.failedErrorSubtitle = 'CONFIRMATION-MODAL.ERROR_SUB';
            this.failedErrorButton = 'CONFIRMATION-MODAL.VIEW';
          }

          this.isConfirming = false;
          this.isSuccess = false;
          this.isFailed = true;
        },
      });
  }

  estimateFee() {
    if (!this.nftItem || !this.value) {
      return;
    }

    this.bidFeeLoadingStatus.next(LoadingStatus.LOADING);

    this.nftsService
      .nftBiddingEstimation({
        nftId: this.nftItem.nftId,
        collectionAddress: this.nftItem.collectionAddress,
        listingId: this.nftItem.listingId,
        bid: this.value,
      })
      .subscribe({
        next: res => {
          this.feeValue = res;
          this.bidFeeLoadingStatus.next(LoadingStatus.LOADED);

          if (this.balance && this.value) {
            this.enoughBalance = Number(this.balance) >= Number(this.value) + Number(this.feeValue);
          }
        },
        error: () => {
          this.feeValue = null;
          this.bidFeeLoadingStatus.next(LoadingStatus.ERROR);
          this.enoughBalance = false;
        },
      });
  }

  toggleGasFeeDescription() {
    this.isGasFeeDescriptionOpened = !this.isGasFeeDescriptionOpened;
  }

  async handleFailedErrorButtonClick() {
    if (this.failedErrorButton === 'CONFIRMATION-MODAL.VIEW') {
      await this.goToHomePage();
    } else {
      this.returnToConfirmationStep();
    }
  }

  private initCustomerData() {
    this.customerDataSubscription = this.customerDataObservable$.subscribe(data => {
      this.balance = data?.balance?.avaxBalance?.availableAmount;
    });
  }

  private initSelectedNftItemSubscription() {
    this.selectedNftItemSubscription = this.nftStoreService.selectedNftItemForDetailsPage$.subscribe({
      next: nft => {
        if (!nft) {
          return;
        }

        this.nftItem = nft;
      },
    });
  }

  private initModalSubscription() {
    this.modalSubscription = this.modalsService.modalState$.subscribe(modalIds => this.modalStateChange(modalIds));
  }

  private initAuctionBidValueSubscription() {
    this.auctionBidValueSubscription = this.auctionStoreService.bidValueObservable$.subscribe({
      next: value => {
        this.value = value;
      },
    });
  }

  private initAuctionBidTypeSubscription() {
    this.auctionBidTypeSubscription = this.auctionStoreService.bidTypeObservable$.subscribe({
      next: bidType => {
        this.isItDirectBuy = bidType === BidType.DIRECT_BUY;
      },
    });
  }
}
