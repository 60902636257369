<button [disabled]="disabled || nftItem.status === NftStatus.IN_PROGRESS" (click)="goToDetails()" class="nft-list-item-wrapper">
  <div *ngIf="soldImageShow" class="sold-out-wrapper">
    <span>{{ 'Nfts-List-Page_Component.SOLD' | translate }}</span>
  </div>
  <div class="loading" *ngIf="nftItem.status === NftStatus.IN_PROGRESS">
    <div class="spinner-8bit"></div>
  </div>
  <!-- To make sure the image or video fits within the square area -->
  <div [ngClass]="{ 'sold-effect': this.soldImageShow }" class="nft-list-item">
    <div class="nft-list-image-wrapper">
      <togg-image-with-fallback-and-loader
        [imgAlt]="'NFT image'"
        [loaderColor]="'#222222'"
        [successImageClass]="'ntf-list-item-image'"
        imgSrc="{{ nftItem.thumbnailFileUrl }}"></togg-image-with-fallback-and-loader>
      <div class="position-relative">
        <div *ngIf="this.nftItem.mediaType === 'VIDEO'" class="nft-list-item-video-icon">
          <img alt="video icon" class="play-button" height="25" ngSrc="/assets/images/play-button.svg" width="25" />
        </div>
        <div *ngIf="sellClass" [class]="'font-12 chip ' + sellClass">{{ sellStateName | translate }}</div>
        <div *ngIf="multiselectMode" class="selection-counter" [ngClass]="{ selected: selectedCounter > 0 }">{{ selectedCounter || '' }}</div>
        <div *ngIf="!multiselectMode && this.nftItem.highlighted" class="nft-list-item-highlighted-icon">
          <img alt="star" class="star" height="14" ngSrc="/assets/images/star.svg" width="14" />
        </div>
      </div>
    </div>

    <div class="nft-list-item-details">
      <div class="nft-list-item-nft-data">
        <h4 class="nft-list-item-name">
          {{ this.nftItem.name }}
        </h4>

        <div class="nft-list-item-creator">
          <img alt="approved" class="nft-list-item-creator-approved" height="11" ngSrc="/assets/images/blue-check.svg" width="11" />
          <span class="nft-list-item-creator-approved-brand">{{ this.nftItem.creator }}</span>
        </div>
      </div>

      <div *ngIf="nftItem.onSale && nftItem.initialPrice === nftItem.maxPrice">
        <div class="price-card">
          <span class="font-10 price-title">
            {{ 'Nfts-List-Page_Component.BUY-NOW' | translate }}
          </span>
          <div class="flex gap-1 align-items-center">
            <img alt="avax-token" class="nft-list-item-highest-bid-icon" height="15" ngSrc="/assets/images/AVAX-black.svg" width="15" />

            <span class="price-value">
              {{ this.nftItem.price }}
            </span>
          </div>
        </div>
      </div>

      <div *ngIf="nftItem.onSale && nftItem.initialPrice !== nftItem.maxPrice" class="flex gap-2 justify-content-evenly">
        <div class="price-card">
          <span class="font-10 font-center">
            {{ (nftItem.biddings > 0 ? 'Nfts-List-Page_Component.Highest' : 'Nfts-List-Page_Component.Starting') | translate }}
          </span>
          <div class="flex gap-1 align-items-center">
            <img *ngIf="nftItem.biddings > 0" alt="avax-token" class="nft-list-item-highest-bid-icon" height="15" ngSrc="/assets/images/AVAX-blue.svg" width="15" />

            <img *ngIf="nftItem.biddings <= 0" alt="avax-token" class="nft-list-item-highest-bid-icon" height="15" ngSrc="/assets/images/AVAX-black.svg" width="15" />

            <span class="price-value">
              {{ this.nftItem.price }}
            </span>
          </div>
        </div>
        <div class="price-card">
          <span class="font-10 price-title">
            {{ 'Nfts-List-Page_Component.MaxPrice' | translate }}
          </span>
          <div class="flex gap-1 align-items-center">
            <img alt="avax-token" class="nft-list-item-highest-bid-icon" height="15" ngSrc="/assets/images/AVAX-black.svg" width="15" />
            <span class="price-value">
              {{ this.nftItem.maxPrice }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</button>
