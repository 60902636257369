<div (click)="sendCountlyNftClickEvent(); goToNftDetails()" class="bid-card">
  <div *ngIf="isMyBidLoading">
    <div class="nft-text-skeleton row">
      <div class="column left">
        <togg-image-with-fallback-and-loader
          [imgAlt]="'NFT image'"
          [loaderColor]="'#222222'"
          [successImageClass]="'ntf-list-item-image'"
          class="image-loader"></togg-image-with-fallback-and-loader>
      </div>
      <div class="column right">
        <span class="nft-text-skeleton-span">
          {{ 'ACTIVE-BIDDED.BID-text-wait1' | translate }}
        </span>
      </div>
    </div>
  </div>

  <div *ngIf="!isMyBidLoading && nftOperation.nft">
    <div class="nft-info">
      <div class="nft-image-wrap">
        <togg-image-with-fallback-and-loader
          class="image-loader"
          imgAlt="NFT image"
          imgSrc="{{ nftOperation.nft.thumbnailFileUrl }}"
          loaderColor="#222222"
          successImageClass="ntf-list-item-image"></togg-image-with-fallback-and-loader>
      </div>
      <div class="nft-name-and-creator">
        <h3 class="nft-name">{{ nftOperation.nft.name }}</h3>

        <div class="nft-creator">
          <img alt="approved" class="nft-list-item-creator-approved" height="11" ngSrc="/assets/images/blue-check.svg" width="11" />
          <span class="nft-list-item-creator-approved-brand">{{ nftOperation.nft.creator }}</span>
        </div>
      </div>
    </div>
    <div class="divider-wrapper">
      <div class="divider"></div>
    </div>
    <div class="bid-info">
      <div class="bid">
        <h6 class="bid-title">
          {{ 'ACTIVE-BIDDED.YOUR-BID' | translate }}
        </h6>
        <div class="avax-displayer">
          <img alt="avax token" class="avax-icon" height="16" ngSrc="/assets/images/AVAX-black.svg" width="16" />
          <span class="bid-amount">
            {{ nftOperation.price }}
          </span>
        </div>
      </div>
      <div class="bid">
        <h6 class="bid-title">
          {{ 'Nfts-List-Page_Component.Highest' | translate }}
        </h6>
        <div class="avax-displayer">
          <img alt="avax token" class="avax-icon" height="16" ngSrc="/assets/images/AVAX-blue.svg" width="16" />
          <span class="bid-amount">
            {{ nftOperation.nft.price }}
          </span>
          <img
            *ngIf="highestBid && !beatenStatus && !cancelStatus && !errorStatus"
            alt="highest bid"
            class="highest-bid-icon"
            height="21"
            ngSrc="/assets/images/Trophy.svg"
            width="21" />
        </div>
      </div>
    </div>

    <!-- <pre>FE: {{operation.nft.status}}</pre> -->
    <div *ngIf="beatenStatus || cancelStatus || errorStatus" class="bid-message">
      <h6 class="bid-title">
        {{ 'ERROR.STATUS' | translate }}
      </h6>
      <div class="bid-message-content">
        <img alt="Error icon" height="48" ngSrc="/assets/images/error-icon.svg" width="48" />
        <span *ngIf="beatenStatus" class="error-msg">{{ 'ERROR.BEATEN' | translate }}</span>
        <span *ngIf="cancelStatus" class="error-msg">{{ 'ERROR.CANCEL' | translate }}</span>

        <!-- Error messages available with BC & BE info -->
        <span *ngIf="errorStatus && notWinningBid" class="error-msg">{{ 'ERROR.BEATEN' | translate }}</span>
        <span *ngIf="errorStatus && boughtError" class="error-msg">{{ 'ERROR.BOUGHT-ERROR' | translate }}</span>
        <span *ngIf="errorStatus && auctionClosedError" class="error-msg">{{ 'ERROR.AUCTION-CLOSED-ERROR' | translate }}</span>
        <span *ngIf="errorStatus && panicPauseError" class="error-msg">{{ 'ERROR.PANIC-PAUSE-ERROR' | translate }}</span>
        <span *ngIf="errorStatus && collectionPauseError" class="error-msg">{{ 'ERROR.COLLECTION-PAUSE-ERROR' | translate }}</span>
        <span *ngIf="errorStatus && transactionError" class="error-msg">{{ 'ERROR.TRANSACTION-ERROR' | translate }}</span>
      </div>
    </div>
  </div>
</div>
