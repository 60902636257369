import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ModalId } from '../../enums/modal-id';
import { NavigationStart, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ModalsService {
  private modalState = new BehaviorSubject<ModalId[]>([]);

  constructor(private readonly router: Router) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.closeAll();
      }
    });
    this.modalState.subscribe(state => {
      if (state.length > 0) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'auto';
      }
    });
  }

  get modalState$() {
    return this.modalState.asObservable();
  }

  open(id: ModalId) {
    const modalState = this.modalState.getValue();

    if (modalState.includes(id)) {
      return;
    }

    this.modalState.next([...modalState, id]);
  }

  close(id: ModalId) {
    const modalState = this.modalState.getValue();
    const modalStateWithoutClosed = modalState.filter(modal => modal !== id);
    this.modalState.next(modalStateWithoutClosed);
  }

  closeAll() {
    this.modalState.next([]);
  }
}
