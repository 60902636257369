import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { INTRO_PAGE_SEEN, OAuthHelperService } from '../../services';
import { PageUrls } from '../../enums/page-urls.enum';

@Component({
  selector: 'togg-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent {
  constructor(
    private router: Router,
    private readonly oAuthHelperService: OAuthHelperService,
  ) {}

  async onFabButtonClicked() {
    localStorage.setItem(INTRO_PAGE_SEEN, 'true');
    if (this.oAuthHelperService.isUserLoggedIn()) {
      await this.router.navigate([PageUrls.HOME]);
    } else {
      this.oAuthHelperService.startLoginFlow();
    }
  }
}
