import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CustomerDto } from '@togg-trumore/toggens-customers-api-client';
import { BehaviorSubject, Subject, combineLatest, takeUntil } from 'rxjs';
import { NftFilter } from 'src/app/components/nft-filter/nft-filter.component';
import { ModalId } from 'src/app/enums/modal-id';
import { PageUrls } from 'src/app/enums/page-urls.enum';
import { WhitelistedEnum } from 'src/app/enums/whitelisted.enum';
import { nftWhitelistingToWhitelistedMapper } from 'src/app/mappers/nft-whitelisting-to-whitelisted.mapper';
import { CountlyService, CustomerStoreService, ModalsService, Nft, NftStatus, NftStoreService } from 'src/app/services';
import { formatWalletAddress } from 'src/app/utils/formatWalletAddress';

@Component({
  selector: 'togg-nft-list-page',
  templateUrl: './nft-list-page.component.html',
  styleUrls: ['./nft-list-page.component.scss'],
})
export class NftListPageComponent implements OnInit, OnDestroy {
  protected readonly formatWalletAddress = formatWalletAddress;
  protected readonly NftStatus = NftStatus;
  private readonly ngOnDestroy$ = new Subject<void>();
  protected showFilter = false;
  multiselectMode = false;
  selectedNfts: Nft[] = [];
  allNfts: Nft[] = [];
  userData?: CustomerDto;
  joinedDate?: Date;
  isLoading = false;
  isSellAllowed = false;
  whiteListingStatus?: WhitelistedEnum;
  private filters$ = new BehaviorSubject<NftFilter>({
    all: true,
    fixedPrice: true,
    nonTransferable: true,
    onAuction: true,
    unlisted: true,
  });

  protected readonly WhitelistedEnum = WhitelistedEnum;
  get canMultiselect(): boolean {
    return this.allNfts.some(nft => !nft.onSale && nft.transferable);
  }

  constructor(
    public readonly nftStoreService: NftStoreService,
    public readonly customerStoreService: CustomerStoreService,
    private readonly modalsService: ModalsService,
    private router: Router,
    private countlyService: CountlyService,
  ) {}

  ngOnInit() {
    this.nftStoreService.getUserNfts();

    combineLatest([this.nftStoreService.userNftItems$, this.filters$])
      .pipe(takeUntil(this.ngOnDestroy$))
      .subscribe(([nfts, filter]) => {
        this.allNfts = nfts.filter(
          nft =>
            (filter.unlisted && !nft.onSale && nft.transferable) ||
            (filter.onAuction && nft.onSale && nft.initialPrice !== nft.maxPrice) ||
            (filter.fixedPrice && nft.onSale && nft.initialPrice === nft.maxPrice) ||
            (filter.nonTransferable && !nft.transferable),
        );

        this.selectedNfts = this.selectedNfts.filter(selectedNft => this.allNfts.find(nft => selectedNft.nftInternalId === nft.nftInternalId));
      });

    this.customerStoreService
      .getCustomerDataObservable()
      .pipe(takeUntil(this.ngOnDestroy$))
      .subscribe(value => {
        this.userData = value;

        if (value?.whitelisting) {
          this.whiteListingStatus = nftWhitelistingToWhitelistedMapper(value.whitelisting);
        }

        if (!value?.creationDate) {
          return;
        }
        this.joinedDate = new Date(value.creationDate);
      });
  }

  ngOnDestroy(): void {
    this.ngOnDestroy$.next();
    this.ngOnDestroy$.complete();
  }

  filterChanged(filter: NftFilter) {
    this.filters$.next(filter);
  }

  async nftClicked(nft: Nft) {
    if (this.multiselectMode) {
      const idx = this.selectedNfts.indexOf(nft);
      if (idx > -1) {
        this.selectedNfts.splice(idx, 1);
        return;
      }

      this.selectedNfts.push(nft);
      return;
    }

    this.countlyService.trackEventWithSegmentation({
      key: 'magnet detail clicked',
    });

    const navigateUrl = [PageUrls.NFT_DETAILS.replace('/:collection', '').replace('/:id', ''), nft.collectionAddress, nft.nftId];
    await this.router.navigate(navigateUrl);
  }

  toggleMultiselectionMode() {
    this.selectedNfts = [];
    this.multiselectMode = !this.multiselectMode;
  }

  selectAll() {
    this.allNfts.forEach(nft => {
      if (!this.selectedNfts.includes(nft) && !nft.onSale && nft.transferable) {
        this.selectedNfts.push(nft);
      }
    });
  }

  async onNextClick() {
    if (this.whiteListingStatus === WhitelistedEnum.NOT_WHITELISTED) {
      this.modalsService.open(ModalId.BECOME_WHITELISTED);
      return;
    }
    this.countlyService.trackEventWithSegmentation({
      key: 'bulk sell nft clicked',
    });
    await this.router.navigate([PageUrls.SELL_NFT], { state: { selectedNfts: this.selectedNfts } });
  }

  toggleFilter(event: MouseEvent) {
    this.showFilter = !this.showFilter;
    event.stopPropagation();
  }
}
