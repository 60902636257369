<div class="introduction-page">
  <section class="introduction-text">
    <h1 class="font-28 turquoise bold">
      {{ 'INTRODUCTION-PAGE.BIG-TITLE' | translate }}
    </h1>

    <div class="font-16 bold">
      {{ 'INTRODUCTION-PAGE.SUBTITLE-1' | translate }}
    </div>

    <div class="font-16 bold">
      {{ 'INTRODUCTION-PAGE.SUBTITLE-2' | translate }}
    </div>

    <h5 class="pt-7">
      {{ 'INTRODUCTION-PAGE.TITLE-1' | translate }}
    </h5>

    <p class="font-16">{{ 'INTRODUCTION-PAGE.PARAGRAPH-1-1' | translate }}</p>
    <p class="font-16">{{ 'INTRODUCTION-PAGE.PARAGRAPH-1-2' | translate }}</p>
    <p class="font-16">{{ 'INTRODUCTION-PAGE.PARAGRAPH-1-3' | translate }}</p>

    <h5>{{ 'INTRODUCTION-PAGE.TITLE-2' | translate }}</h5>

    <p>{{ 'INTRODUCTION-PAGE.PARAGRAPH-2-1' | translate }}</p>

    <h5>{{ 'INTRODUCTION-PAGE.TITLE-3' | translate }}</h5>

    <p>{{ 'INTRODUCTION-PAGE.PARAGRAPH-3-1' | translate }}</p>

    <h5>{{ 'INTRODUCTION-PAGE.TITLE-4' | translate }}</h5>

    <p>{{ 'INTRODUCTION-PAGE.PARAGRAPH-4-1' | translate }}</p>

    <h5>{{ 'INTRODUCTION-PAGE.TITLE-5' | translate }}</h5>

    <p>{{ 'INTRODUCTION-PAGE.PARAGRAPH-5-1' | translate }}</p>

    <h5>{{ 'INTRODUCTION-PAGE.TITLE-6' | translate }}</h5>

    <p>{{ 'INTRODUCTION-PAGE.PARAGRAPH-6-1' | translate }}</p>
    <h5>{{ 'INTRODUCTION-PAGE.TITLE-7' | translate }}</h5>

    <p>{{ 'INTRODUCTION-PAGE.PARAGRAPH-7-1' | translate }}</p>
    <h5>{{ 'INTRODUCTION-PAGE.TITLE-8' | translate }}</h5>

    <p>{{ 'INTRODUCTION-PAGE.PARAGRAPH-8-1' | translate }}</p>

    <div (click)="showParagraph24 = !showParagraph24" class="paragraph-wrapper">
      <h5 class="clickable-title">
        {{ 'INTRODUCTION-PAGE.TITLE-PRIVACY' | translate }}
      </h5>
      <img [src]="showParagraph24 ? '/assets/images/chevron-black.svg' : '/assets/images/chevron-down-black.svg'" alt="chevron" class="chevron" />
    </div>

    <p [innerHTML]="'INTRODUCTION-PAGE.PARAGRAPH-PRIVACY' | translate" [ngClass]="{ show: showParagraph24 }" class="no-top-padding accordion-text"></p>

    <div class="checkbox-paragraph-wrapper">
      <input (change)="toggleParagraph25()" [checked]="checkedParagraph25" type="checkbox" />
      <h5 (click)="toggleParagraph25()" class="clickable-title">
        {{ 'INTRODUCTION-PAGE.TITLE-AGREEMENT' | translate }}
      </h5>
      <img
        (click)="showParagraph25 = !showParagraph25"
        [src]="showParagraph25 ? '/assets/images/chevron-black.svg' : '/assets/images/chevron-down-black.svg'"
        alt="chevron"
        class="chevron" />
    </div>

    <p [innerHTML]="'INTRODUCTION-PAGE.PARAGRAPH-AGREEMENT' | translate" [ngClass]="{ show: showParagraph25 }" class="no-top-padding accordion-text"></p>
  </section>

  <button (click)="finishIntroduction()" [disabled]="!checkedParagraph25 || signingAgreement" [ngClass]="{ disabled: !checkedParagraph25 }" class="continue-button">
    <i *ngIf="signingAgreement" class="spinner-8bit"></i>
    <span *ngIf="!signingAgreement">{{ 'INTRODUCTION-PAGE.CONTINUE' | translate }}</span>
  </button>
</div>
