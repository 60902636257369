<togg-base-modal [modalId]="ModalId.WHITELISTING_PAUSED" [screenType]="ModalScreenType.NORMAL" [showCloseButton]="true">
  <ng-container bodyContent>
    <div class="whitelisting-paused-error">
      <div class="whitelisting-paused-error-content">
        <div class="error-image-wrapper">
          <img [ngSrc]="'assets/images/alert-triangle-warning.svg'" alt="info-alert" height="48" width="48" />
        </div>

        <h4 class="whitelisting-paused-error-title">
          {{ 'BANNER.TITLE-LOCKED' | translate }}
        </h4>

        <p class="whitelisting-paused-error-description">
          {{ 'BANNER.DESCRIP-LOCKED' | translate }}
        </p>
      </div>
    </div>
  </ng-container>
</togg-base-modal>
