<ng-container *ngIf="nftStoreService.firstPageLoadStatus$ | async as loadingStatus">
  <div *ngIf="loadingStatus === NftLoadingStatus.LOADING || loadingStatus === NftLoadingStatus.NOT_LOADED" class="fixed w-full h-full bg-black-alpha-50 z-5">
    <div class="spinner-8bit"></div>
  </div>
  <div *ngIf="loadingStatus === NftLoadingStatus.LOADED" class="flex flex-column collection-page">
    <div class="relative">
      <img class="banner-img" [src]="collection?.bannerUrl ?? '/assets/images/banner-1.png'" alt="banner" />
      <img class="logo-img" [src]="collection?.logoUrl ?? '/assets/images/trumore_logo_square.svg'" alt="logo" />
    </div>

    <div class="w-full grid px-3 pt-7 pb-2 column-gap-5 row-gap-3 justify-content-between">
      <div class="flex flex-column gap-3">
        <span class="font-32">{{ collection?.name }}</span>
        <span class="font-16 flex flex-nowrap gap-2"
          ><span class="bold">{{ 'COLLECTIONS.COLLECTION-ADDRESS' | translate }}</span> {{ formatWalletAddress(collection?.address) }}
          <togg-clipboard-button [copyText]="collection?.address"></togg-clipboard-button
        ></span>
        <span class="font-14">{{ collection?.description }}</span>
      </div>
      <div class="flex gap-2">
        <div class="card-data">
          <span class="font-10 card-name">{{ 'COLLECTIONS.TOTAL-VOLUME' | translate }}</span>
          <span class="font-12 card-value flex align-items-center gap-1"
            ><img alt="avax token" class="avax-icon" height="13" ngSrc="/assets/images/AVAX-black.svg" width="13" /> {{ totalVolume }}</span
          >
        </div>
        <div class="card-data">
          <span class="font-10 card-name">{{ 'COLLECTIONS.TOTAL-AMOUNT' | translate }}</span>
          <span class="font-12 card-value">{{ collection?.onSaleNftsCount }}</span>
        </div>
        <div class="card-data">
          <span class="font-10 card-name">{{ 'COLLECTIONS.UNIQUE-OWNERS' | translate }}</span>
          <span class="font-12 card-value">{{ uniqueOwners }}</span>
        </div>
      </div>
    </div>
    <div class="filter-bar">
      <button (click)="openNftSearchModal()" class="header-button search-button-area" type="button">
        <img alt="search nft" height="24" ngSrc="/assets/images/search.svg" width="24" />
      </button>
      <div [ngClass]="{ focused: isSearchInputFocused }" class="search-input-wrapper">
        <label class="search-icon-area" for="search-input">
          <img alt="search" height="20" ngSrc="/assets/images/search_icon.svg" width="20" />
        </label>
        <div class="search-input-area">
          <input
            (blur)="searchInputBlurred()"
            (focus)="searchInputFocused()"
            (input)="nftSearchTextChanged($event)"
            [ngModel]="nftStoreService.searchNftKeyword$ | async"
            autocomplete="off"
            class="search-input"
            id="search-input"
            inputmode="search"
            placeholder="{{ 'LIST-MAIN-HEADER.SEARCH-NFTS' | translate }}"
            toggAlphanumericTextInput
            type="search" />
        </div>
      </div>
    </div>
    <div class="flex flex-column nft-grid-wrapper" *ngIf="nftStoreService.nftItems$ | async as nftItems">
      <div
        *ngIf="nftItems.length"
        class="nft-grid"
        (scrolled)="onScrollDown()"
        [fromRoot]="!isMobile"
        [infiniteScrollContainer]="undefined"
        [infiniteScrollDisabled]="
          (nftStoreService.firstPageLoadStatus$ | async) === NftLoadingStatus.LOADING ||
          (nftStoreService.nextPageLoadStatus$ | async) === NftLoadingStatus.LOADING ||
          (nftStoreService.morePageAvailable$ | async) === false
        "
        [infiniteScrollDistance]="1.5"
        [infiniteScrollThrottle]="500"
        infinite-scroll>
        <togg-nft-list-item (clicked)="navigateToNftDetails($event)" *ngFor="let item of nftItems; let i = index" [nftItem]="item"></togg-nft-list-item>
      </div>

      <div *ngIf="nftItems.length === 0">
        <div class="flex flex-column w-full align-items-center">
          <img alt="Sold Out" height="150" ngSrc="/assets/images/sold-out.png" priority="true" width="150" />
          <h5 class="font-24 gray-back">{{ 'Home-Page_Component.SoldOut' | translate }}</h5>
          <span class="font-12 gray-back text-center">
            {{ 'Home-Page_Component.SoldOutMessage' | translate }}
          </span>
        </div>
      </div>
    </div>
  </div>
</ng-container>
