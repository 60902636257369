<togg-nft-list-banner class="header-wrapper"></togg-nft-list-banner>

<div class="header-wrapper">
  <p-carousel
    *ngIf="banners$ | async as banners"
    [value]="banners"
    [numVisible]="1"
    [circular]="true"
    [autoplayInterval]="banners.length > 1 ? 7000 : 0"
    [showNavigators]="false"
    [showIndicators]="banners.length > 1"
    class="w-full"
    [indicatorsContentStyle]="{ position: 'absolute', bottom: 0, 'padding-bottom': 0, left: 0, right: 0, 'margin-left': 'auto', 'margin-right': 'auto' }">
    <ng-template let-banner pTemplate="item">
      <button class="banner" [disabled]="!banner.navigateUrl" (click)="navigateTo(banner.navigateUrl)" [ngStyle]="{ background: 'url(' + banner.imgSrc + ')' }">
        <div class="banner-info">
          <span class="font-16 bold lg-font-20 banner-header">{{ banner.title }}</span>
          <span class="font-12 lg-font-14 banner-description">{{ banner.subText }}</span>
        </div>
      </button>
    </ng-template>
  </p-carousel>
</div>
