import { Component } from '@angular/core';
import { ModalId } from '../../enums/modal-id';
import { ModalScreenType } from '../../enums/modal-screen-type';
import { ModalDisplayMode } from '../../enums/modal-display-mode';
import { CountlyService, ModalsService, NftStoreService } from '../../services';

@Component({
  selector: 'togg-nft-search-modal',
  templateUrl: './nft-search-modal.component.html',
  styleUrls: ['./nft-search-modal.component.scss'],
})
export class NftSearchModalComponent {
  public searchKeywordInputValue = '';

  protected readonly ModalType = ModalId;
  protected readonly ModalScreenType = ModalScreenType;
  protected readonly ModalDisplayMode = ModalDisplayMode;

  constructor(
    private readonly nftStoreService: NftStoreService,
    private readonly countlyService: CountlyService,
    private readonly modalsService: ModalsService,
  ) {}

  applyAndSearch() {
    this.sendCountlySearchButtonClickedEvent();
    this.nftStoreService.saveSearchNftKeyword(this.searchKeywordInputValue);
    this.modalsService.close(ModalId.NFT_SEARCH);
  }

  sendCountlySearchButtonClickedEvent() {
    this.countlyService.trackEventWithSegmentation({
      key: 'search NFT button clicked',
      segmentation: {
        id: this.searchKeywordInputValue,
      },
    });
  }
}
