<ng-container *ngIf="nftItem">
  <div [ngClass]="{ opened: isShowingDetails }" [style.background-image]="'url(' + nftItem.thumbnailFileUrl + ')'" class="nft-details-show-image">
    <togg-nft-details-header (closeIconClicked)="closeDetails()" [nftItem]="nftItem" [type]="'only_close_icon'"></togg-nft-details-header>

    <div class="make-blur"></div>

    <togg-image-with-fallback-and-loader
      *ngIf="nftItem.mediaType === 'IMAGE'"
      [imgAlt]="'NFT image'"
      [imgSrc]="nftItem.mediaFileUrl"
      [loaderColor]="'#FFFFFF'"
      [successImageClass]="'nft-details-big-image'"
      imgSrcFromDetail="detail"
      media="{{ nftItem.mediaType }}"></togg-image-with-fallback-and-loader>

    <togg-image-with-fallback-and-loader
      *ngIf="nftItem.mediaType === 'VIDEO'"
      [imgAlt]="'NFT image'"
      [imgSrc]="nftItem.mediaFileUrl"
      [isVideoMuted]="!isShowingDetails"
      [loaderColor]="'#FFFFFF'"
      [successImageClass]="'nft-details-big-image'"
      imgSrcFromDetail="detail"
      media="{{ nftItem.mediaType }}"></togg-image-with-fallback-and-loader>

    <div class="nft-data">
      <div class="details-container">
        <h4 class="title">{{ nftItem.name }}</h4>
      </div>

      <div *ngIf="actionsActive && nftItem.onSale && !ownedNft" class="buttons">
        <button *ngIf="nftItem.initialPrice !== nftItem.maxPrice" (click)="handleBid()" [disabled]="!actionsActive" class="outbid-now-button">
          {{
            nftIsSold ? ('BIDDING-PAGE-COMPONENT.SOLD' | translate) : ((amIHighestBidder ? 'BIDDING-PAGE-COMPONENT.OUTBID-NOW' : 'BIDDING-PAGE-COMPONENT.PLACE-BID') | translate)
          }}
        </button>

        <button (click)="handleBuyNow()" [disabled]="!actionsActive" class="buy-now-button">
          {{ (nftIsSold ? 'BIDDING-PAGE-COMPONENT.SOLD' : 'BIDDING-PAGE-COMPONENT.BUY-NOW') | translate }}
        </button>
      </div>
    </div>
  </div>

  <div *ngIf="isModalLoading" class="fixed w-full h-full bg-black-alpha-50 z-5">
    <div class="spinner-8bit"></div>
  </div>

  <togg-customer-balance></togg-customer-balance>

  <div class="nft-details-container">
    <div [style.background-image]="'url(' + nftItem.thumbnailFileUrl + ')'" class="background-image">
      <div class="background-image-black-overlay"></div>
      <div class="main-content-header-wrapper">
        <togg-nft-details-header
          (expandButtonClicked)="expandButtonClicked()"
          (playVideoButtonClicked)="expandButtonClicked()"
          [nftItem]="nftItem"
          [type]="headerType"
          class="main-content-header"
          (cancelListingClicked)="cancelListingClicked()"
          (editListingClicked)="sellNft()"
          [showEdit]="isSellAllowed && ownedNft && nftItem.onSale && nftItem.biddings <= 0"></togg-nft-details-header>
      </div>

      <div class="main-content">
        <div class="details-container">
          <togg-countdown-timer
            *ngIf="nftItem.onSale && nftItem?.bidEndDate && !auctionAboutToStart && !auctionFinished"
            [finishDateString]="nftItem.bidEndDate!"
            [nftsList]="false"></togg-countdown-timer>
          <div class="title-highlighted">
            <span class="title"> {{ nftItem.name }} </span>
            <togg-highlighted *ngIf="nftItem.highlighted" class="highlighted-fit"></togg-highlighted>
          </div>

          <div class="description">
            {{ descriptionOpen ? nftItem.description : shortDescription }}
            <span (click)="descriptionToggle()" *ngIf="showMoreDetailsButton" class="hyperlink">
              {{ (descriptionOpen ? 'NFT-DETAILS-COMPONENT.SHOW-LESS' : 'NFT-DETAILS-COMPONENT.SHOW-DETAILS') | translate }}
            </span>
          </div>

          <div class="detail-cards" *ngIf="nftItem.onSale">
            <div *ngIf="nftItem.initialPrice !== nftItem.maxPrice" (click)="handleBid()" [ngClass]="{ bid: actionsActive }" class="detail-card-container">
              <span class="detail-card-title">{{ (nftItem.biddings! > 0 ? 'BID-CARD-COMPONENT.HIGHEST-BID' : 'BID-CARD-COMPONENT.STARTING-BID') | translate }}</span>
              <div class="avax-displayer">
                <img *ngIf="nftItem.biddings > 0" alt="avax token" class="avax-icon" height="14" ngSrc="/assets/images/AVAX-vividBlue.svg" width="15" />
                <img *ngIf="nftItem.biddings <= 0" alt="avax token" class="avax-icon" height="14" ngSrc="/assets/images/AVAX-white.svg" width="15" />

                <span class="bid-amount">
                  {{ nftItem.price }}
                </span>
                <!-- <img *ngIf="amIHighestBidder && lastBidStatus === 'RELEASED'" alt="highest bid" class="highest-bid-icon" src="/assets/images/Trophy.svg" /> -->
              </div>
              <span *ngIf="!auctionAboutToStart && !ownedNft" [ngClass]="{ 'detail-card-btn': actionsActive, 'detail-card-btn-disabled': !actionsActive }">
                {{
                  nftIsSold
                    ? ('BIDDING-PAGE-COMPONENT.SOLD' | translate)
                    : ((amIHighestBidder ? 'BIDDING-PAGE-COMPONENT.OUTBID-NOW' : 'BIDDING-PAGE-COMPONENT.PLACE-BID') | translate)
                }}
                <img alt="chevron" class="chevron" height="14" ngSrc="/assets/images/chevron-right.svg" width="9" />
              </span>
              <span *ngIf="auctionAboutToStart" [ngClass]="{ 'detail-card-btn': actionsActive, 'detail-card-btn-disabled': !actionsActive }">
                {{ 'BIDDING-PAGE-COMPONENT.COMING-SOON' | translate }}
              </span>
            </div>

            <div (click)="handleBuyNow()" [ngClass]="{ buyNow: actionsActive }" class="detail-card-container">
              <span class="detail-card-title">{{ 'BIDDING-PAGE-COMPONENT.BUY-NOW-PRICE' | translate }}</span>
              <div class="avax-displayer">
                <img alt="avax token" class="avax-icon" height="21" ngSrc="/assets/images/AVAX-white.svg" width="21" />
                <span class="bid-amount">
                  {{ nftItem.maxPrice }}
                </span>
              </div>
              <span *ngIf="!auctionAboutToStart && !ownedNft" [ngClass]="{ 'detail-card-btn': actionsActive, 'detail-card-btn-disabled': !actionsActive }">
                {{ (nftIsSold ? 'BIDDING-PAGE-COMPONENT.SOLD' : 'BIDDING-PAGE-COMPONENT.BUY-NOW') | translate }}
                <img alt="chevron" class="chevron" height="14" ngSrc="/assets/images/chevron-right.svg" width="9" />
              </span>
              <span *ngIf="auctionAboutToStart" [ngClass]="{ 'detail-card-btn': actionsActive, 'detail-card-btn-disabled': !actionsActive }">
                {{ 'BIDDING-PAGE-COMPONENT.COMING-SOON' | translate }}
              </span>
            </div>
          </div>
        </div>
        <button class="btn btn-primary sell-btn" *ngIf="ownedNft && !nftItem.onSale && nftItem.transferable" (click)="sellNft()" [disabled]="nftItem.collectionPaused">
          {{ 'BIDDING-PAGE-COMPONENT.SELL-IT' | translate }}
        </button>

        <div class="details-swiper-wrapper">
          <togg-nft-details-swiper [auctionAboutToStart]="auctionAboutToStart" [nftItem]="nftItem"></togg-nft-details-swiper>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!nftItem">
  <div class="spinner-8bit"></div>
</ng-container>
