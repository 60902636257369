import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { OAuthHelperService } from '../services';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private oAuthHelperService: OAuthHelperService) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(
      tap(evt => evt),
      catchError((err: unknown) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            this.oAuthHelperService.logout();
          }
        }
        throw err;
      }),
    );
  }
}
