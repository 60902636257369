import { NftOperationDto } from '@togg-trumore/toggens-operations-api-client';
import { Offer } from '../components/nft-details-swiper/nft-details-swiper.component.types';
import { formatWalletAddress } from '../utils/formatWalletAddress';

export function nftOperationsDtoToOfferMapper(operation: NftOperationDto): Offer {
  let floor = 0;

  if (operation.nft?.initialPrice && operation.price) {
    floor = ((operation.price - operation.nft.initialPrice) * 100) / +operation.nft.initialPrice;
  }

  return {
    price: operation.price || 0,
    floor,
    fromAddress: formatWalletAddress(operation.destinationWallet || ''),
    status: operation.status || NftOperationDto.StatusEnum.ERROR,
  };
}
