import { Component, Input, OnDestroy } from '@angular/core';

@Component({
  selector: 'togg-image-with-fallback-and-loader',
  templateUrl: './image-with-fallback-and-loader.component.html',
  styleUrls: ['./image-with-fallback-and-loader.component.scss'],
})
export class ImageWithFallbackAndLoaderComponent implements OnDestroy {
  @Input() imgSrcFromDetail?: string;
  @Input() media?: string;
  @Input() isVideoMuted: boolean;
  @Input() fallbackImage = '/assets/images/broken_image.png';
  @Input() loaderColor = '#FFFFFF';
  @Input() imgAlt?: string;
  @Input() successImageClass?: string;
  @Input() fallbackImageClass?: string;
  @Input() loaderImageClass?: string;
  loading = true;
  public loadState = 0;
  private _imageLoadTimeout?: ReturnType<typeof setTimeout>;

  constructor() {
    this.isVideoMuted = false;
  }

  private _imgSrc?: string;

  get imgSrc() {
    return this._imgSrc ?? '';
  }

  @Input() set imgSrc(value: string) {
    this._imgSrc = value;

    if (this._imageLoadTimeout) {
      clearTimeout(this._imageLoadTimeout);
    }

    if (this._imgSrc != value) {
      this.loadState = 0;
      this._imageLoadTimeout = setTimeout(this.onError, 10000);
    }
  }

  ngOnDestroy() {
    clearTimeout(this._imageLoadTimeout);
  }

  onLoad() {
    this.loadState = 1;
    clearTimeout(this._imageLoadTimeout);
  }

  onError() {
    this.loadState = 2;
    clearTimeout(this._imageLoadTimeout);
  }

  updateUrl(oldUrl: string): string {
    const url = new URL(oldUrl);
    const params = new URLSearchParams(url.search);
    const newTime = new Date().getTime();

    if (params.has('date')) {
      params.set('date', newTime.toString());
    } else {
      params.append('date', newTime.toString());
    }

    url.search = params.toString();
    return url.toString();
  }

  onSourceError() {
    this.imgSrc = this.updateUrl(this.imgSrc);
    const videoElement: HTMLVideoElement = document.getElementById('video') as HTMLVideoElement;
    videoElement.load();
  }

  onLoadVideo() {
    this.loadState = 2;
    this.loading = false;
  }
}
