<togg-main-header [showSearch]="true"></togg-main-header>

<div class="home-page">
  <div class="nft-grid-and-filter-splitter">
    <div class="nft-grid-area">
      <togg-nfts-list-header [collections$]="nftStoreService.collections$"></togg-nfts-list-header>

      <div *ngIf="(nftStoreService.loadingCollections$ | async) === NftLoadingStatus.LOADED && (nftStoreService.collections$ | async)?.length === 0">
        <div class="all-nfts-sold-out">
          <img alt="Sold Out" height="250" ngSrc="/assets/images/sold-out.png" priority="true" width="250" />
          <h5>{{ 'Home-Page_Component.SoldOut' | translate }}</h5>
          <p>
            {{ 'Home-Page_Component.SoldOutMessage' | translate }}
          </p>
        </div>
      </div>
      <div *ngIf="(nftStoreService.loadingCollections$ | async) === NftLoadingStatus.ERROR">
        <div class="all-nfts-sold-out">
          <img alt="Sold Out" height="250" ngSrc="/assets/images/sold-out.png" priority="true" width="250" />
          <h5>{{ 'Home-Page_Component.ErrorLoadingPage' | translate }}</h5>
        </div>
      </div>

      <div class="collections-carousel" *ngIf="(nftStoreService.loadingCollections$ | async) === NftLoadingStatus.LOADED">
        <p-carousel
          [value]="(nftStoreService.collections$ | async) || []"
          [numVisible]="4"
          [numScroll]="4"
          [showIndicators]="false"
          [responsiveOptions]="collectionsResponsiveOptions">
          <ng-template let-collection pTemplate="item">
            <togg-collection-card [collection]="collection" (clicked)="collectionClicked($event)"></togg-collection-card>
          </ng-template>
        </p-carousel>
      </div>
      <div class="collections-mobile" *ngIf="(nftStoreService.loadingCollections$ | async) === NftLoadingStatus.LOADED">
        <togg-collection-card
          *ngFor="let collection of nftStoreService.collections$ | async"
          [collection]="collection"
          (clicked)="collectionClicked($event)"></togg-collection-card>
      </div>

      <div *ngIf="(nftStoreService.loadingCollections$ | async) === NftLoadingStatus.LOADING" class="loading-nfts-area">
        <div class="spinner-8bit"></div>
      </div>
    </div>
  </div>

  <togg-customer-balance></togg-customer-balance>
</div>
