<!-- -->
<img class="fullimg" [src]="'assets/images/error-bg-landscape.png'" alt="No Found" />

<div class="container">
  <div class="texts">
    <h1 class="nofound">{{ 'DOCUMENT-LOAD-ERROR.title' | translate }}</h1>
    <h6 class="nofound1">{{ 'DOCUMENT-LOAD-ERROR.description' | translate }}</h6>
  </div>
  <div class="button-wrapper">
    <button [disabled]="isRetrying" type="button" class="nofound-apply-button" (click)="retry()">
      {{ 'DOCUMENT-LOAD-ERROR.buttonText' | translate }}
    </button>
  </div>
</div>
