import { NftDto } from '@togg-trumore/toggens-operations-api-client';
import { Nft, NftStatus, NftType, OperationType } from '../services';

export const nftDtoToNftMapper = (nftDto: NftDto): Nft => {
  return {
    nftId: nftDto.nftId ?? -1,
    sellEndDate: nftDto.sellEndDate,
    ownerToggId: nftDto.ownerToggId,
    operation: nftDtoOperationFieldToOperationTypeMapper(nftDto.operation),
    finalPrice: nftDto.finalPrice,
    biddings: nftDto.biddings!,
    nftInternalId: nftDto.nftInternalId ?? '',
    bidEndDate: nftDto.bidEndDate ?? '',
    collectionAddress: nftDto.collectionAddress ?? '',
    collectionValidated: nftDto.collectionValidated ?? false,
    creator: nftDto.creator ?? '',
    mediaFileUrl: nftDto.mediaFileUrl ?? '',
    mediaTokenUri: nftDto.mediaTokenUri ?? '',
    mediaType: nftDto.mediaType ?? NftDto.MediaTypeEnum.UNKNOWN_DEFAULT_OPEN_API,
    name: nftDto.name ?? '',
    ownerWallet: nftDto.ownerWallet ?? '',
    rarity: nftDto.rarity,
    thumbnailFileUrl: nftDto.thumbnailFileUrl ?? '',
    thumbnailTokenUri: nftDto.thumbnailTokenUri ?? '',
    tokenUri: nftDto.tokenUri ?? '',
    fee: nftDto.fee ?? 0,
    description: nftDto.description ?? '',
    nftType: nftDtoNftTypeFieldToNftTypeMapper(nftDto.nftType),
    highlighted: nftDto.highlighted ?? false,
    initialPrice: nftDto.initialPrice ?? 0,
    launchDate: nftDto.launchDate ?? '',
    listingId: nftDto.listingId ?? -1,
    maxPrice: nftDto.maxPrice ?? 0,
    onSale: nftDto.onSale ?? false,
    price: nftDto.price ?? 0,
    qrCodeUrl: nftDto.qrCodeUrl ?? '',
    quantity: nftDto.quantity ?? 0,
    royaltyFee: nftDto.royaltyFee ?? 0,
    status: nftDtoNftStatusFieldToNftStatusMapper(nftDto.status),
    collectionPaused: nftDto.collectionPaused,
    minBid: nftDto.minBid,
    transferable: nftDto.transferable,
  };
};

export const nftDtoOperationFieldToOperationTypeMapper = (operation?: string): OperationType => {
  if (!operation) {
    return OperationType.UNKNONW;
  }

  switch (operation) {
    case 'TRANSFER':
      return OperationType.TRANSFER;
    case 'MINTING':
      return OperationType.MINTING;
    case 'BURNING':
      return OperationType.BURNING;
    case 'BIDDING':
      return OperationType.BIDDING;
    case 'LISTING':
      return OperationType.LISTING;
    case 'EDIT_LISTING':
      return OperationType.EDIT_LISTING;
    case 'SELLING':
      return OperationType.SELLING;
    case 'MINTING_LISTING':
      return OperationType.MINTING_LISTING;
    case 'MINTING_SELLING':
      return OperationType.MINTING_SELLING;
    case 'CANCEL_LISTING':
      return OperationType.CANCEL_LISTING;
    case 'CLOSE_AUCTION':
      return OperationType.CLOSE_AUCTION;
    case 'BUY':
      return OperationType.BUY;
    case 'TRANSACTION':
      return OperationType.TRANSACTION;
    case 'CANCEL_AUCTION':
      return OperationType.CANCEL_AUCTION;
    case 'HIGHLIGHTING':
      return OperationType.HIGHLIGHTING;
    case '11184809':
      return OperationType._11184809;
    default:
      return OperationType.UNKNONW;
  }
};

export const nftDtoNftTypeFieldToNftTypeMapper = (nftType?: string): NftType => {
  if (!nftType) {
    return NftType.UNKNOWN;
  }

  switch (nftType) {
    case 'ERC721':
      return NftType.ERC721;
    case 'ERC1155':
      return NftType.ERC1155;
    case '11184809':
      return NftType._11184809;
    default:
      return NftType.UNKNOWN;
  }
};

export const nftDtoNftStatusFieldToNftStatusMapper = (nftStatus?: string): NftStatus => {
  if (!nftStatus) {
    return NftStatus.UNKNOWN;
  }

  switch (nftStatus) {
    case 'CREATED':
      return NftStatus.CREATED;
    case 'IN_PROGRESS':
      return NftStatus.IN_PROGRESS;
    case 'PAYMENT_ERROR':
      return NftStatus.PAYMENT_ERROR;
    case 'RELEASED':
      return NftStatus.RELEASED;
    case 'ERROR':
      return NftStatus.ERROR;
    case 'BEATEN':
      return NftStatus.BEATEN;
    case 'CANCELLED':
      return NftStatus.CANCELLED;
    case 'WON':
      return NftStatus.WON;
    case '11184809':
      return NftStatus._11184809;
    default:
      return NftStatus.UNKNOWN;
  }
};

export const operationTypeToNftDtoOperationFieldMapper = (operation: OperationType): string => {
  switch (operation) {
    case OperationType.TRANSFER:
      return 'TRANSFER';
    case OperationType.MINTING:
      return 'MINTING';
    case OperationType.BURNING:
      return 'BURNING';
    case OperationType.BIDDING:
      return 'BIDDING';
    case OperationType.LISTING:
      return 'LISTING';
    case OperationType.EDIT_LISTING:
      return 'EDIT_LISTING';
    case OperationType.SELLING:
      return 'SELLING';
    case OperationType.MINTING_LISTING:
      return 'MINTING_LISTING';
    case OperationType.MINTING_SELLING:
      return 'MINTING_SELLING';
    case OperationType.CANCEL_LISTING:
      return 'CANCEL_LISTING';
    case OperationType.CLOSE_AUCTION:
      return 'CLOSE_AUCTION';
    case OperationType.BUY:
      return 'BUY';
    case OperationType.TRANSACTION:
      return 'TRANSACTION';
    case OperationType.CANCEL_AUCTION:
      return 'CANCEL_AUCTION';
    case OperationType.HIGHLIGHTING:
      return 'HIGHLIGHTING';
    case OperationType._11184809:
      return '11184809';
    default:
      return 'UNKNONW';
  }
};

export const nftTypeToNftDtoNftTypeFieldMapper = (nftType: NftType): string => {
  switch (nftType) {
    case NftType.ERC721:
      return 'ERC721';
    case NftType.ERC1155:
      return 'ERC1155';
    case NftType._11184809:
      return '11184809';
    default:
      return 'UNKNOWN';
  }
};

export const nftStatusToNftDtoNftStatusFieldMapper = (nftStatus: NftStatus): string => {
  switch (nftStatus) {
    case NftStatus.CREATED:
      return 'CREATED';
    case NftStatus.IN_PROGRESS:
      return 'IN_PROGRESS';
    case NftStatus.PAYMENT_ERROR:
      return 'PAYMENT_ERROR';
    case NftStatus.RELEASED:
      return 'RELEASED';
    case NftStatus.ERROR:
      return 'ERROR';
    case NftStatus.BEATEN:
      return 'BEATEN';
    case NftStatus.CANCELLED:
      return 'CANCELLED';
    case NftStatus.WON:
      return 'WON';
    case NftStatus._11184809:
      return '11184809';
    default:
      return 'UNKNOWN';
  }
};
