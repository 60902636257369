import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { map, Observable, Subscription } from 'rxjs';
import {
  NftInBiddingMessage,
  ReceivedBiddingMessage,
  ReceivedOutbiddingMessage,
  ReceivedTokenBalanceMessage,
  ReceivedWalletCustomerMessage,
  WebsocketMarketplaceMessage,
  WebsocketMarketplaceMessageTopic,
} from './socket.service.types';
import { CustomerStoreService } from '../customer-store/customer-store.service';
import { OAuthHelperService } from '../o-auth-helper/o-auth-helper.service';

@Injectable({
  providedIn: 'root',
})
export class SocketService {
  private lastConnectedTruId?: string;
  private tokenSubscription?: Subscription;

  constructor(
    private socket: Socket,
    private oAuthHelperService: OAuthHelperService,
    private customerStoreService: CustomerStoreService,
  ) {}

  public connectSocket() {
    if (!this.socket.ioSocket.connected) {
      this.connectIfUserAuthenticated();
      this.watchTokenChangedAndReconnectWhenTokenChanged();
    }
  }

  async connectIfUserAuthenticated() {
    if (!this.oAuthHelperService.isUserLoggedIn()) {
      return;
    }

    const currentTruId = this.oAuthHelperService.getTruId();

    if (!currentTruId || currentTruId === this.lastConnectedTruId) {
      return;
    }

    this.lastConnectedTruId = currentTruId;
    const customer = await this.customerStoreService.loadCustomerData();

    this.socket.ioSocket.io.opts.query = {
      jwtTokenSub: currentTruId,
      walletAddress: customer.walletId,
    };

    this.disconnectIfAlreadyConnected();
    this.socket.connect();
  }

  watchTokenChangedAndReconnectWhenTokenChanged() {
    this.tokenSubscription?.unsubscribe();

    this.tokenSubscription = this.oAuthHelperService.OAuthService.events.subscribe(async event => {
      if (event.type === 'token_received') {
        await this.connectIfUserAuthenticated();
      } else if (event.type === 'logout') {
        this.disconnectIfAlreadyConnected();
      }
    });
  }

  public disconnectIfAlreadyConnected() {
    if (this.socket.ioSocket.connected) {
      this.socket.disconnect();
      this.tokenSubscription?.unsubscribe();
    }
  }

  // TODO Alttaki metod şu anki kullanıcının verdiği teklife ait güncelleme olduğu zaman tetikleniyor.
  listenForMarketplaceBids(): Observable<WebsocketMarketplaceMessage<ReceivedBiddingMessage>> {
    return this.createListener(WebsocketMarketplaceMessageTopic.MARKETPLACE_BIDS);
  }

  //listenForWalletTransactions(): Observable<WebsocketMarketplaceMessage<ReceivedWalletTransactionMessage>> {
  //  return this.createListener(WebsocketMarketplaceMessageTopic.WALLET_TRANSACTIONS);
  //}

  // TODO Alttaki metod herhangi bir NFT'de bir değişim olduğu zaman çağırılıyor. ( Fiyat, zaman vs. )
  listenForWalletNfts(): Observable<WebsocketMarketplaceMessage<NftInBiddingMessage>> {
    return this.createListener(WebsocketMarketplaceMessageTopic.WALLET_NFTS);
  }

  // TODO Alttaki metod şu anki kullanıcının teklif verdiği bir NFT'ye, başka bir kullanıcı daha yüksek teklif verirse çağrılıyor.
  listenForMarketplaceOutbids(): Observable<WebsocketMarketplaceMessage<ReceivedOutbiddingMessage>> {
    return this.createListener(WebsocketMarketplaceMessageTopic.MARKETPLACE_OUTBIDS);
  }

  // TODO Alttaki metod şu anki kullanıcının customer bilgilerini güncelliyor. Örneğin whitelist durumu değiştiğinde bu metod çağrılıyor.
  listenForWalletCustomers(): Observable<WebsocketMarketplaceMessage<ReceivedWalletCustomerMessage>> {
    return this.createListener(WebsocketMarketplaceMessageTopic.WALLET_CUSTOMERS);
  }

  listenForWalletBalances(): Observable<WebsocketMarketplaceMessage<ReceivedTokenBalanceMessage>> {
    return this.createListener(WebsocketMarketplaceMessageTopic.WALLET_BALANCES);
  }

  listenForCustomerNfts(): Observable<WebsocketMarketplaceMessage<NftInBiddingMessage>> {
    return this.createListener(WebsocketMarketplaceMessageTopic.CUSTOMER_NFTS);
  }

  private updateAndParseMessage<T>(message: WebsocketMarketplaceMessage<unknown>): WebsocketMarketplaceMessage<T> {
    const parsedBody = this.parseWebsocketMessage<T>(message);
    return {
      ...message,
      parsedBody,
    };
  }

  private parseWebsocketMessage<T>(message: WebsocketMarketplaceMessage<unknown>): T {
    return JSON.parse(JSON.parse(message._body));
  }

  private createListener<T>(topic: WebsocketMarketplaceMessageTopic): Observable<WebsocketMarketplaceMessage<T>> {
    return this.socket.fromEvent<WebsocketMarketplaceMessage<T>>(topic).pipe(map(message => this.updateAndParseMessage(message)));
  }
}
