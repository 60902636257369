import { NftOperationDto } from '@togg-trumore/toggens-operations-api-client';
import { NftOperation, NftOperationType } from '../services/auction-store/auction-store.service.types';
import { StatusesBidEnum } from '../enums/statuses-bids.enum';
import { nftDtoToNftMapper } from './nft-dto-to-nft.mapper';

export function nftOperationDtoToNftOperationMapper(dto: NftOperationDto): NftOperation {
  let type: NftOperationType;
  let status: StatusesBidEnum;

  switch (dto.type) {
    case 'MINTING':
      type = NftOperationType.MINTING;
      break;
    case 'BURNING':
      type = NftOperationType.BURNING;
      break;
    case 'BIDDING':
      type = NftOperationType.BIDDING;
      break;
    case 'LISTING':
      type = NftOperationType.LISTING;
      break;
    case 'EDIT_LISTING':
      type = NftOperationType.EDIT_LISTING;
      break;
    case 'SELLING':
      type = NftOperationType.SELLING;
      break;
    case 'MINTING_LISTING':
      type = NftOperationType.MINTING_LISTING;
      break;
    case 'MINTING_SELLING':
      type = NftOperationType.MINTING_SELLING;
      break;
    case 'CANCEL_LISTING':
      type = NftOperationType.CANCEL_LISTING;
      break;
    case 'CLOSE_AUCTION':
      type = NftOperationType.CLOSE_AUCTION;
      break;
    case 'BUY':
      type = NftOperationType.BUY;
      break;
    case 'TRANSACTION':
      type = NftOperationType.TRANSACTION;
      break;
    case 'CANCEL_AUCTION':
      type = NftOperationType.CANCEL_AUCTION;
      break;
    case 'HIGHLIGHTING':
      type = NftOperationType.HIGHLIGHTING;
      break;
    default:
      type = NftOperationType.UNKNOWN_DEFAULT_OPEN_API;
  }

  switch (dto.status) {
    case 'CREATED':
      status = StatusesBidEnum.CREATED;
      break;
    case 'IN_PROGRESS':
      status = StatusesBidEnum.IN_PROGRESS;
      break;
    case 'PAYMENT_ERROR':
      status = StatusesBidEnum.ERROR;
      break;
    case 'RELEASED':
      status = StatusesBidEnum.RELEASED;
      break;
    case 'ERROR':
      status = StatusesBidEnum.ERROR;
      break;
    case 'BEATEN':
      status = StatusesBidEnum.BEATEN;
      break;
    case 'CANCELLED':
      status = StatusesBidEnum.CANCELLED;
      break;
    case 'WON':
      status = StatusesBidEnum.WON;
      break;
    default:
      status = StatusesBidEnum.ERROR;
  }

  return {
    nftOperationId: dto.nftOperationId,
    nft: dto.nft ? nftDtoToNftMapper(dto.nft) : undefined,
    type,
    date: dto.date,
    updateDate: dto.updateDate,
    destinationWallet: dto.destinationWallet,
    price: dto.price,
    initialPrice: dto.initialPrice,
    maxPrice: dto.maxPrice,
    finalPrice: dto.finalPrice,
    launchDate: dto.launchDate,
    bidEndDate: dto.bidEndDate,
    status,
    descriptionError: dto.descriptionError,
    customerId: dto.customerId,
    fee: dto.fee,
    highlighted: dto.highlighted,
  };
}
