import { Component } from '@angular/core';
import { ModalId } from '../../enums/modal-id';
import { ModalScreenType } from '../../enums/modal-screen-type';
import { ModalDisplayMode } from '../../enums/modal-display-mode';
import { CountlyService, ModalsService, NftStoreService } from '../../services';

@Component({
  selector: 'togg-main-search-modal',
  templateUrl: './main-search-modal.component.html',
  styleUrls: ['./main-search-modal.component.scss'],
})
export class MainSearchModalComponent {
  public searchKeywordInputValue = '';

  protected readonly ModalType = ModalId;
  protected readonly ModalScreenType = ModalScreenType;
  protected readonly ModalDisplayMode = ModalDisplayMode;

  constructor(
    private readonly nftStoreService: NftStoreService,
    private readonly countlyService: CountlyService,
    private readonly modalsService: ModalsService,
  ) {}

  applyAndSearch() {
    this.sendCountlySearchButtonClickedEvent();
    this.nftStoreService.saveMainSearchKeyword(this.searchKeywordInputValue);
    this.modalsService.close(ModalId.MAIN_SEARCH);
  }

  sendCountlySearchButtonClickedEvent() {
    this.countlyService.trackEventWithSegmentation({
      key: 'Main search button clicked',
      segmentation: {
        id: this.searchKeywordInputValue,
      },
    });
  }
}
