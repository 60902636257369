export function formatWalletAddress(wallet: string | undefined): string {
  if (!wallet) {
    return '-';
  }

  if (wallet.length < 13) {
    return wallet;
  }

  return wallet.substring(0, 5) + ' ... ' + wallet.substring(wallet.length - 5, wallet.length);
}
