export enum PageUrls {
  LOGIN = '',
  INTRODUCTION = 'introduction',
  HOME = 'home',
  NFT_DETAILS = 'nft/:collection/:id',
  WALLET_NOT_EXISTS = 'wallet-not-exists',
  ERROR = 'error-page',
  MY_NFTS = 'my-nfts',
  SELL_NFT = 'sell',
  COLLECTION = 'collection/:collection',
}
