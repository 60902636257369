import { Component } from '@angular/core';
import { ModalId } from 'src/app/enums/modal-id';

@Component({
  selector: 'togg-not-enough-balance-modal',
  templateUrl: './not-enough-balance-modal.component.html',
  styleUrls: ['./not-enough-balance-modal.component.scss'],
})
export class NotEnoughBalanceModalComponent {
  protected readonly ModalId = ModalId;
}
