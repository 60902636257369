import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { runGuardsSequentially } from '../../utils/runGuardsSequentially';
import { PageUrls } from '../../enums/page-urls.enum';
import { ErrorPageComponent, IntroductionPageComponent, LoginPageComponent, NftDetailsPageComponent, HomePageComponent, WalletNotExistsPageComponent } from '../../pages';
import {
  ConnectSocketGuard,
  DisconnectSocketGuard,
  documentLoaderGuard,
  environmentCheckerGuard,
  redirectIfAlreadyAuthenticatedGuard,
  requireAuthGuard,
  walletAndIntroductionMissingRedirectGuard,
} from '../../guards';
import { nftDetailsGuard } from '../../guards/nft-details/nft-details.guard';
import { SellNftPageComponent } from 'src/app/pages/sell-nft-page/sell-nft-page.component';
import { NftListPageComponent } from 'src/app/pages/nft-list-page/nft-list-page.component';
import { CollectionPageComponent } from 'src/app/pages/collection-page/collection-page.component';

const routes: Routes = [
  {
    path: PageUrls.LOGIN,
    component: LoginPageComponent,
    canActivate: runGuardsSequentially([environmentCheckerGuard, documentLoaderGuard, redirectIfAlreadyAuthenticatedGuard, DisconnectSocketGuard]),
  },
  {
    path: PageUrls.INTRODUCTION,
    component: IntroductionPageComponent,
    canActivate: runGuardsSequentially([environmentCheckerGuard, documentLoaderGuard, requireAuthGuard]),
  },
  {
    path: PageUrls.HOME,
    component: HomePageComponent,
    canActivate: runGuardsSequentially([environmentCheckerGuard, documentLoaderGuard, requireAuthGuard, walletAndIntroductionMissingRedirectGuard, ConnectSocketGuard]),
  },
  {
    path: PageUrls.MY_NFTS,
    component: NftListPageComponent,
    canActivate: runGuardsSequentially([environmentCheckerGuard, documentLoaderGuard, requireAuthGuard, walletAndIntroductionMissingRedirectGuard, ConnectSocketGuard]),
  },
  {
    path: PageUrls.SELL_NFT,
    component: SellNftPageComponent,
    canActivate: runGuardsSequentially([environmentCheckerGuard, documentLoaderGuard, requireAuthGuard, walletAndIntroductionMissingRedirectGuard, ConnectSocketGuard]),
  },
  {
    path: PageUrls.COLLECTION,
    component: CollectionPageComponent,
    canActivate: runGuardsSequentially([environmentCheckerGuard, documentLoaderGuard, requireAuthGuard, walletAndIntroductionMissingRedirectGuard, ConnectSocketGuard]),
  },
  {
    path: PageUrls.NFT_DETAILS,
    component: NftDetailsPageComponent,
    canActivate: runGuardsSequentially([
      environmentCheckerGuard,
      documentLoaderGuard,
      requireAuthGuard,
      walletAndIntroductionMissingRedirectGuard,
      nftDetailsGuard,
      ConnectSocketGuard,
    ]),
  },
  {
    path: PageUrls.WALLET_NOT_EXISTS,
    component: WalletNotExistsPageComponent,
    canActivate: runGuardsSequentially([environmentCheckerGuard, DisconnectSocketGuard]),
  },
  {
    path: PageUrls.ERROR,
    component: ErrorPageComponent,
    canActivate: runGuardsSequentially([environmentCheckerGuard]),
  },

  // Wild Card Route for 404 request
  {
    path: '**',
    pathMatch: 'full',
    component: LoginPageComponent,
    canActivate: runGuardsSequentially([environmentCheckerGuard, documentLoaderGuard, redirectIfAlreadyAuthenticatedGuard]),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      bindToComponentInputs: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
