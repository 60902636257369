import { inject } from '@angular/core';
import { InitializeResult, OAuthHelperService } from '../../services';
import { Router } from '@angular/router';
import { PageUrls } from '../../enums/page-urls.enum';
import { AsyncGuardFunction } from '../../declarations/guard';

export const documentLoaderGuard: AsyncGuardFunction = async () => {
  const oAuthHelperService = inject(OAuthHelperService);
  const router = inject(Router);

  const oAuthInitResult = await oAuthHelperService.initialize();

  if (oAuthInitResult === InitializeResult.DISCOVERY_DOCUMENT_LOAD_ERROR) {
    return router.parseUrl(PageUrls.ERROR);
  }

  return true;
};
