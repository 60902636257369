<div
  (click)="backdropClickHandler()"
  [ngClass]="{
    show: isOpened,
    backdropClickDisabled: disableBackdropClick,
    hideOnDesktopOrTabletViewport: displayMode === ModalDisplayMode.MOBILE_ONLY
  }"
  class="backdrop"></div>

<div
  [ngClass]="{
    show: isOpened,
    hideOnDesktopOrTabletViewport: displayMode === ModalDisplayMode.MOBILE_ONLY,
    fullScreen: screenType === ModalScreenType.FULLSCREEN,
    fullScreenOnlyMobile: screenType === ModalScreenType.ONLY_MOBILE_FULLSCREEN
  }"
  class="modalContentWrapper">
  <div class="modalContent">
    <header>
      <ng-content select="[headerContent]"></ng-content>
      <button (click)="closeButtonClickHandler()" *ngIf="showCloseButton" [ngClass]="{ disabled: disableCloseButton }" class="closeButton">
        <img alt="close icon" height="24" ngSrc="/assets/images/close_icon.svg" width="24" />
      </button>
    </header>
    <main>
      <ng-content select="[bodyContent]"></ng-content>
    </main>
    <footer>
      <ng-content select="[footerContent]"></ng-content>
    </footer>
  </div>
</div>
