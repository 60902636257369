<div id="tabs-container">
  <ul class="tabs" role="tablist">
    <li
      *ngFor="let step of steps"
      role="presentation"
      [id]="'step-' + step.step"
      [ngClass]="{ active: currentStep === step.step, disabled: maxStep < step.step }"
      (click)="stepChange.emit(step.step)">
      <a role="tab" [ariaSelected]="currentStep === step.step">{{ step.label }}</a>
    </li>
  </ul>
</div>
