<div class="level-alert">
  <div class="options">
    <div [class]="{ selected: rarity === rarityEnum.NORMAL }" class="option">
      {{ 'RARITY.NORMAL' | translate }}
      <img *ngIf="rarity === rarityEnum.NORMAL" alt="triangle-up" class="triangle" height="8" ngSrc="/assets/images/triangle-up.svg" width="11" />
    </div>
    <div [class]="{ selected: rarity === rarityEnum.RARE }" class="option">
      {{ 'RARITY.RARE' | translate }}
      <img *ngIf="rarity === rarityEnum.RARE" alt="triangle-up" class="triangle" height="8" ngSrc="/assets/images/triangle-up.svg" width="11" />
    </div>
    <div [class]="{ selected: rarity === rarityEnum.SUPER_RARE }" class="option">
      {{ 'RARITY.SUPER_RARE' | translate }}
      <img *ngIf="rarity === rarityEnum.SUPER_RARE" alt="triangle-up" class="triangle" height="8" ngSrc="/assets/images/triangle-up.svg" width="11" />
    </div>
    <div [class]="{ selected: rarity === rarityEnum.ULTRA_RARE }" class="option">
      {{ 'RARITY.ULTRA_RARE' | translate }}
      <img *ngIf="rarity === rarityEnum.ULTRA_RARE" alt="triangle-up" class="triangle" height="8" ngSrc="/assets/images/triangle-up.svg" width="11" />
    </div>
  </div>
</div>
