import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PageUrls } from '../../enums/page-urls.enum';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { CustomerStoreService } from '../../services';

@Component({
  selector: 'togg-introduction-page',
  templateUrl: './introduction-page.component.html',
  styleUrls: ['./introduction-page.component.scss'],
})
export class IntroductionPageComponent implements OnInit {
  showParagraph24 = false;
  showParagraph25 = false;
  checkedParagraph25 = false;

  signingAgreement = false;

  private checkedParagraphWhenPageLoaded = false;

  constructor(
    private readonly router: Router,
    private readonly customerService: CustomerStoreService,
    private readonly toastr: ToastrService,
    private readonly translateService: TranslateService,
  ) {}

  async ngOnInit() {
    let customerData;

    try {
      customerData = await this.customerService.loadCustomerData();

      this.checkedParagraphWhenPageLoaded = !customerData.showConditions;
      this.checkedParagraph25 = !customerData.showConditions;
    } catch (err: unknown) {
      await this.router.navigate([PageUrls.ERROR], {
        queryParams: {
          retry_url: `/${PageUrls.INTRODUCTION}`,
        },
      });
    }
  }

  toggleParagraph25() {
    this.checkedParagraph25 = !this.checkedParagraph25;
  }

  async finishIntroduction() {
    if (this.checkedParagraph25 && !this.checkedParagraphWhenPageLoaded) {
      this.signingAgreement = true;
      const result = await this.customerService.completeIntroduction();
      this.signingAgreement = false;

      if (result) {
        await this.router.navigate([PageUrls.HOME]);
      } else {
        this.toastr.error(this.translateService.instant('INTRODUCTION-PAGE.ERROR-TOAST-MESSAGE'), this.translateService.instant('INTRODUCTION-PAGE.ERROR-TOAST-TITLE'));
      }
    } else {
      await this.router.navigate([PageUrls.HOME]);
    }
  }
}
