import { Component, OnDestroy, OnInit } from '@angular/core';
import { WhitelistedEnum } from '../../enums/whitelisted.enum';
import { Subscription } from 'rxjs';
import { CustomerStoreService, ModalsService, NftStoreService } from '../../services';
import { nftWhitelistingToWhitelistedMapper } from '../../mappers/nft-whitelisting-to-whitelisted.mapper';
import { CustomersService } from '@togg-trumore/toggens-customers-api-client';
import { ModalId } from '../../enums/modal-id';

@Component({
  selector: 'togg-nft-list-banner',
  templateUrl: './nft-list-banner.component.html',
  styleUrls: ['./nft-list-banner.component.scss'],
})
export class NftListBannerComponent implements OnInit, OnDestroy {
  whitelisting?: WhitelistedEnum;
  isWhitelistedLocked = false;
  hideBanner = false;
  protected readonly whitelistedEnum = WhitelistedEnum;
  private readonly LOCAL_STORAGE_WHITELIST_SHOW_BANNER_KEY = 'is_whitelist_completed_show_banner';
  private customersSubscription?: Subscription;
  private whitelistingSubscription?: Subscription;
  private readonly customerDataObservable$;

  constructor(
    private readonly customerService: CustomerStoreService,
    private readonly customerApi: CustomersService,
    private readonly modalsService: ModalsService,
    private readonly nftStoreService: NftStoreService,
  ) {
    this.customerDataObservable$ = this.customerService.getCustomerDataObservable();
  }

  ngOnInit() {
    this.customersSubscription = this.customerDataObservable$.subscribe(data => {
      if (!data?.whitelisting) {
        return;
      }

      this.whitelisting = nftWhitelistingToWhitelistedMapper(data.whitelisting);
      this.hideBanner = localStorage?.getItem(this.LOCAL_STORAGE_WHITELIST_SHOW_BANNER_KEY) === '1' && this.whitelisting === WhitelistedEnum.WHITELISTED;
    });

    this.whitelistingSubscription = this.nftStoreService.isWhitelistingEnabled$.subscribe(data => {
      this.isWhitelistedLocked = !data;
    });
  }

  ngOnDestroy() {
    this.customersSubscription?.unsubscribe();
    this.whitelistingSubscription?.unsubscribe();
  }

  tryAgainWhitelist() {
    this.customerApi.whitelistCustomer().subscribe({
      next: () => {
        this.whitelisting = WhitelistedEnum.IN_PROGRESS;
      },
      error: () => {
        this.whitelisting = WhitelistedEnum.REGISTER_ERROR;
      },
    });
  }

  public closeBanner() {
    localStorage?.setItem(this.LOCAL_STORAGE_WHITELIST_SHOW_BANNER_KEY, '1');
    this.hideBanner = true;
  }

  openWhitelistModal() {
    this.modalsService.open(ModalId.BECOME_WHITELISTED);
  }
}
