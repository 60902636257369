import { AsyncGuardFunction } from '../../declarations/guard';

export const environmentCheckerGuard: AsyncGuardFunction = async route => {
  if (sessionStorage.getItem('isMobileApp') === '1') {
    return true;
  }

  const channel = route.queryParams['channel'];
  if (channel === 'MOBILE_APP') {
    sessionStorage.setItem('isMobileApp', '1');
  }

  return true;
};
