import { Injectable } from '@angular/core';
import { CustomerDto, CustomersService } from '@togg-trumore/toggens-customers-api-client';
import { BehaviorSubject, catchError, filter, first, firstValueFrom, Observable, retry, tap, timeout } from 'rxjs';
import { CustomerLoadingStatus } from './customer-store.service.types';
import { OAuthHelperService } from '../o-auth-helper/o-auth-helper.service';
import { ReceivedWalletCustomerMessage, TokenBalanceInTokenBalanceMessage } from '../socket/socket.service.types';

@Injectable({
  providedIn: 'root',
})
export class CustomerStoreService {
  private isCustomerDataLoading$ = new BehaviorSubject<CustomerLoadingStatus>(CustomerLoadingStatus.NOT_LOADED);
  private customerData$ = new BehaviorSubject<CustomerDto | undefined>(undefined);

  constructor(
    private readonly customerApi: CustomersService,
    private readonly oAuthHelperService: OAuthHelperService,
  ) {}

  public async loadCustomerData(): Promise<CustomerDto> {
    if (this.isCustomerDataLoading$.getValue() === CustomerLoadingStatus.LOADED) {
      return this.customerData$.getValue()!;
    } else if (this.isCustomerDataLoading$.getValue() === CustomerLoadingStatus.LOADING) {
      const customerDtoResult = await firstValueFrom(
        this.customerData$.pipe(
          filter(x => x != null),
          first(),
        ),
      );
      return customerDtoResult as CustomerDto;
    }

    return firstValueFrom(this.loadCustomerDataFromApi());
  }

  /***
   * This method is used to set the introduction as completed in server.
   * It calls api to set the introduction as completed and then updates the local customer data.
   * @returns {boolean} true if the method successfully set the introduction as completed, false otherwise.
   * @public
   * @memberof CustomerStoreService
   */
  public async completeIntroduction(): Promise<boolean> {
    try {
      const readConditionsResult = await firstValueFrom(this.customerApi.readConditions());
      this.customerData$.next({ ...this.customerData$.getValue()!, ...readConditionsResult });
      return true;
    } catch (e) {
      return false;
    }
  }

  public getCustomerDataObservable() {
    return this.customerData$.asObservable();
  }

  public changeBalance(newBalances: TokenBalanceInTokenBalanceMessage[]) {
    const currentCustomerData = this.customerData$.getValue()!;

    for (const newBalance of newBalances) {
      this.mutateCustomerData(currentCustomerData, newBalance);
    }

    this.customerData$.next({
      ...currentCustomerData,
    });
  }

  public updateCustomerDataFromWebsocket(customerData: ReceivedWalletCustomerMessage) {
    this.customerData$.next({
      ...this.customerData$.getValue()!,
      ...(customerData as CustomerDto),
    });
  }

  private mutateCustomerData(customerData: CustomerDto, newBalance: TokenBalanceInTokenBalanceMessage) {
    if (newBalance.symbol !== 'AVAX' && newBalance.symbol !== 'TGN') {
      return;
    }

    const targetKeys = {
      AVAX: 'avaxBalance',
      TGN: 'toggensBalance',
    } as const;

    const foundKey = targetKeys[newBalance.symbol];

    if (foundKey && customerData?.balance?.[foundKey]) {
      const targetBalance = customerData.balance[foundKey];

      if (targetBalance) {
        targetBalance.totalAmount = newBalance.amount.toString();
        targetBalance.availableAmount = newBalance.amount.toString();
      }
    }

    const targetTokenBalance = customerData.customerBalance?.tokenBalances?.findIndex(x => x.symbol === newBalance.symbol);

    if (targetTokenBalance) {
      customerData.customerBalance!.tokenBalances![targetTokenBalance] = newBalance;
    }
  }

  private loadCustomerDataFromApi(): Observable<CustomerDto> {
    this.isCustomerDataLoading$.next(CustomerLoadingStatus.LOADING);

    return this.customerApi
      .getCustomer(this.oAuthHelperService.getTruId())
      .pipe(timeout(1500))
      .pipe(retry({ count: 1 }))
      .pipe(
        catchError(err => {
          this.isCustomerDataLoading$.next(CustomerLoadingStatus.NOT_LOADED);
          throw err;
        }),
      )
      .pipe(
        tap(customerData => {
          this.customerData$.next(customerData);
          this.isCustomerDataLoading$.next(CustomerLoadingStatus.LOADED);
        }),
      );
  }
}
