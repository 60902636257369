export enum ModalId {
  BIDDING_HISTORY = 'bidding-history',
  BECOME_WHITELISTED = 'become-whitelisted',
  BID_SUBMISSION = 'bid-submission',
  BID_SUBMISSION_CONFIRMATION = 'bid-submission-confirmation',
  LOGOUT = 'logout',
  NFT_SEARCH = 'nft-search',
  MAIN_SEARCH = 'main-search',
  NFT_FILTERS = 'nft-filters',
  NOT_ENOUGH_BALANCE = 'not-enough-balance',
  PRESALE = 'presale',
  WHITELISTING_PAUSED = 'whitelisting-paused',
  CONFIRMATION = 'confirmation',
  ERROR = 'error',
}
