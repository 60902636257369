import { Component } from '@angular/core';
import { ModalId } from 'src/app/enums/modal-id';
import { ModalScreenType } from 'src/app/enums/modal-screen-type';

@Component({
  selector: 'togg-whitelisting-paused-modal',
  templateUrl: './whitelisting-paused-modal.component.html',
  styleUrls: ['./whitelisting-paused-modal.component.scss'],
})
export class WhitelistingPausedModalComponent {
  protected readonly ModalScreenType = ModalScreenType;
  protected readonly ModalId = ModalId;

  constructor() {}
}
