import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { PageUrls } from '../../enums/page-urls.enum';
import { INTRO_PAGE_SEEN, OAuthHelperService } from '../../services';
import { AsyncGuardFunction } from '../../declarations/guard';

export const redirectIfAlreadyAuthenticatedGuard: AsyncGuardFunction = async () => {
  const oAuthHelperService = inject(OAuthHelperService);
  const router = inject(Router);

  const isLoggedIn = oAuthHelperService.isUserLoggedIn();

  if (isLoggedIn) {
    return router.parseUrl(PageUrls.HOME);
  }

  if (localStorage.getItem(INTRO_PAGE_SEEN)) {
    oAuthHelperService.startLoginFlow();
    return false;
  }

  return true;
};
