import { Component } from '@angular/core';
import { ModalId } from '../../enums/modal-id';
import { ModalScreenType } from '../../enums/modal-screen-type';

@Component({
  selector: 'togg-presale-modal',
  templateUrl: './presale-modal.component.html',
  styleUrls: ['./presale-modal.component.scss'],
})
export class PresaleModalComponent {
  protected readonly ModalId = ModalId;
  protected readonly ModalScreenType = ModalScreenType;
}
