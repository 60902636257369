import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[toggTwoDigitsDecimalNumberTextInput]',
})
export class TwoDigitsDecimalNumberTextInputDirective {
  private regex = new RegExp(/^(\d{0,4}|\d{1,4}[.,]\d{0,4})$/);
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight'];

  constructor(private el: ElementRef) {}

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    // Allow Select All
    if ((event.ctrlKey || event.metaKey) && ['a', 'x', 'c', 'v'].includes(event.key)) {
      return;
    }

    if (this.specialKeys.includes(event.key) || (event.key.length > 1 && (event.ctrlKey || event.metaKey))) {
      return;
    }

    const current: string = this.el.nativeElement.value;
    const next: string = current.concat(event.key);

    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }

  @HostListener('keyup', ['$event'])
  onKeyUp(event: KeyboardEvent) {
    if (this.el.nativeElement.value === 'NaN') {
      this.el.nativeElement.value = '';
      return;
    }

    if (event.key === ',' && this.el.nativeElement.value.length > 0) {
      this.el.nativeElement.value = this.el.nativeElement.value.replace(',', '.');
    }
  }

  // Blur
  @HostListener('blur', [])
  onBlur() {
    const inputValue = this.el.nativeElement.value;
    if (inputValue) {
      if (inputValue === 'NaN') {
        this.el.nativeElement.value = '';
        return;
      }

      const parts = inputValue.split('.');

      if (parts.length === 2 && parts[1].length === 0) {
        this.el.nativeElement.value = this.el.nativeElement.value.concat('0');
      }
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    const pasteData = event.clipboardData?.getData('text/plain');
    let pastedValue: string;

    if (pasteData !== undefined) {
      pastedValue = this.el.nativeElement.value.toString().concat(pasteData.replace(',', '.'));
    } else {
      pastedValue = this.el.nativeElement.value.toString();
    }

    if (pastedValue.includes(',')) {
      pastedValue = pastedValue.replace(',', '.');
    }

    const floatParsedValue = parseFloat(pastedValue);

    if (isNaN(floatParsedValue) || floatParsedValue > 9999.9999 || floatParsedValue < 0) {
      event.preventDefault();
    }

    const fixedFloatParsedValue = parseFloat(floatParsedValue.toFixed(4));

    event.preventDefault();
    this.el.nativeElement.value = fixedFloatParsedValue.toString();

    const keypressEvent = new KeyboardEvent('keypress', { bubbles: true });
    this.el.nativeElement.dispatchEvent(keypressEvent);
  }
}
