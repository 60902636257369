<togg-base-modal [displayMode]="ModalDisplayMode.MOBILE_ONLY" [modalId]="ModalType.NFT_SEARCH" [screenType]="ModalScreenType.NORMAL" [showCloseButton]="true">
  <div class="header-content" headerContent>
    <h4 class="title">
      {{ 'LIST-MAIN-HEADER.SEARCH-MODAL-TITLE' | translate }}
    </h4>
  </div>

  <div bodyContent class="body-content">
    <input
      [(ngModel)]="searchKeywordInputValue"
      autocomplete="off"
      class="search-input font-16"
      id="search-input"
      inputmode="search"
      placeholder="{{ 'LIST-MAIN-HEADER.SEARCH-NFTS' | translate }}"
      toggAlphanumericTextInput
      type="search"
      (keyup.enter)="applyAndSearch()" />
  </div>

  <div class="footer-content" footerContent>
    <button (click)="applyAndSearch()" class="apply-button" type="button">
      {{ 'LIST-MAIN-HEADER.APPLY' | translate }}
    </button>
  </div>
</togg-base-modal>
