import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NftCollectionDto } from '@togg-trumore/toggens-operations-api-client';

@Component({
  selector: 'togg-collection-card',
  templateUrl: './collection-card.component.html',
  styleUrls: ['./collection-card.component.scss'],
})
export class CollectionCardComponent {
  @Input() collection!: NftCollectionDto;
  @Output() clicked = new EventEmitter<string>();
}
