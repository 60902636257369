import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class DeviceEnvironmentService {
  isMobileApp(): boolean {
    return sessionStorage.getItem('isMobileApp') === '1';
  }
}
