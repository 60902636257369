<togg-base-modal [modalId]="ModalId.BID_SUBMISSION" [screenType]="ModalScreenType.NORMAL" [showCloseButton]="true">
  <ng-container *ngIf="nftItem" bodyContent>
    <div class="create-bid">
      <div class="create-bid-content">
        <div class="create-bid-content-top">
          <div class="create-bid-highest-bid-area">
            <div class="highest-bid-title">
              {{ (nftItem.biddings ? 'BID-CARD-COMPONENT.HIGHEST-BID' : 'BID-CARD-COMPONENT.STARTING-BID') | translate }}
            </div>

            <div class="avax-displayer">
              <img *ngIf="nftItem.biddings > 0" alt="avax token" class="avax-icon" height="14" ngSrc="/assets/images/AVAX-vividBlue.svg" width="15" />

              <img *ngIf="nftItem.biddings <= 0" alt="avax token" class="avax-icon" height="14" ngSrc="/assets/images/AVAX-white.svg" width="15" />
              <span class="bid-amount">
                {{ nftItem.price }}
              </span>
              <img *ngIf="amIHighestBidder" alt="highest bid" class="highest-bid-icon" height="21" ngSrc="/assets/images/Trophy.svg" width="21" />
            </div>

            <div *ngIf="nftItem.biddings > 0" class="error-message show white">
              {{ 'BIDDING-PAGE-COMPONENT.ERROR-MIN-BID-PLEASE' | translate: { minBid: nftItem.minBid } }}
            </div>
          </div>

          <div class="create-bid-give-bid-area">
            <img alt="avax icon" class="avax-icon" height="40" ngSrc="/assets/images/AVAX-red-white-bg.svg" width="40" />

            <div class="enter-bid-amount-text">
              {{ 'BIDDING-PAGE-COMPONENT.ENTER-BID' | translate }}
            </div>

            <input
              [(ngModel)]="bidInputValue"
              [ngClass]="{ error: !isBidInputValid }"
              autocomplete="off"
              class="bid-amount-input"
              inputmode="decimal"
              lang="en-150"
              min="0"
              placeholder="0.00"
              step=".1"
              toggTwoDigitsDecimalNumberTextInput
              type="text" />

            <div [ngClass]="{ show: !isBidInputValid }" class="error-message">
              {{ errorMessage | translate: errorParams }}
            </div>

            <div class="available-balance-title">
              {{ 'BIDDING-PAGE-COMPONENT.BALANCE' | translate }}
            </div>

            <div class="available-balance-amount">{{ availableBalance | number: '1.0-4' }} AVAX</div>
          </div>
        </div>

        <button (click)="openConfirmationModal()" [disabled]="!isBidInputValid || !bidInputValue" class="create-bid-send-button" type="button">
          {{ (nftItem.biddings ? 'BIDDING-PAGE-COMPONENT.OUTBID-NOW' : 'BIDDING-PAGE-COMPONENT.PLACE-BID') | translate }}
        </button>
      </div>
    </div>
  </ng-container>
</togg-base-modal>
