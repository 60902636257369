import { EnvironmentInjector, inject, runInInjectionContext } from '@angular/core';
import { AsyncGuardFunction } from '../declarations/guard';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

export const runGuardsSequentially = (guards: AsyncGuardFunction[]) => {
  return [
    async (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
      const injector = inject(EnvironmentInjector);

      for (const guard of guards) {
        const result = await runInInjectionContext(injector, () => guard(route, state));

        if (result !== true) {
          return result;
        }
      }

      return true;
    },
  ];
};
