<h6 *ngIf="nftsList" class="countdown-title">
  {{ 'COUNTDOWN-TIMER.TITLE' | translate }}
</h6>

<div class="countdown-wrapper" [class]="{ 'not-list': !nftsList }">
  <div class="countdown-list">
    <div class="date-countdown-list">
      <p>{{ time.days > 0 ? time.days : 0 }}</p>
      <span *ngIf="time.days !== 1">{{ 'DETAIL-CARD-COMPONENT.DAYS' | translate }}</span>
      <span *ngIf="time.days === 1">{{ 'DETAIL-CARD-COMPONENT.DAY' | translate }}</span>
    </div>
  </div>
  <div class="separator-list">:</div>
  <div class="countdown-list">
    <div class="date-countdown-list">
      <p>{{ time.hours > 0 ? time.hours : 0 }}</p>
      <span *ngIf="time.hours !== 1">{{ 'DETAIL-CARD-COMPONENT.HOURS' | translate }}</span>
      <span *ngIf="time.hours === 1">{{ 'DETAIL-CARD-COMPONENT.HOUR' | translate }}</span>
    </div>
  </div>
  <div class="separator-list">:</div>
  <div class="countdown-list">
    <div class="date-countdown-list">
      <p>{{ time.minutes > 0 ? time.minutes : 0 }}</p>
      <span>{{ 'DETAIL-CARD-COMPONENT.MINUTES' | translate }}</span>
    </div>
  </div>
  <div class="separator-list">:</div>
  <div class="countdown-list">
    <div class="date-countdown-list">
      <p>{{ time.seconds > 0 ? time.seconds : 0 }}</p>
      <span>{{ 'DETAIL-CARD-COMPONENT.SECONDS' | translate }}</span>
    </div>
  </div>
</div>
