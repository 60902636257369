import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { AsyncGuardFunction } from '../../declarations/guard';
import { PageUrls } from '../../enums/page-urls.enum';
import { AuctionStoreService, NftStoreService } from '../../services';
import { nftDtoToNftMapper } from '../../mappers/nft-dto-to-nft.mapper';
import { NftsService } from '@togg-trumore/toggens-operations-api-client';
import { firstValueFrom } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { LoadingStatus } from '../../enums/loading-status.enum';

export const nftDetailsGuard: AsyncGuardFunction = async route => {
  const router = inject(Router);
  const nftStoreService = inject(NftStoreService);
  const nftApi = inject(NftsService);
  const toastrService = inject(ToastrService);
  const translateService = inject(TranslateService);
  const auctionStoreService = inject(AuctionStoreService);
  const { id: nftInternalId, collection: nftCollectionId } = route?.params ?? {};

  if (!nftInternalId || !nftCollectionId) {
    return router.parseUrl(PageUrls.HOME);
  }

  if ((await firstValueFrom(auctionStoreService.bidHistoryFirstPageLoadStatusObservable$)) === LoadingStatus.NOT_LOADED) {
    auctionStoreService.loadBidHistory();
  }

  const selectedNftItem = await firstValueFrom(nftStoreService.selectedNftItemForDetailsPage$);
  const selectedItemIsTheSameAsRequested = selectedNftItem?.nftId.toString() === nftInternalId && selectedNftItem?.collectionAddress.toString() === nftCollectionId;
  if (selectedItemIsTheSameAsRequested) {
    return true;
  } else {
    try {
      const nftDtoData = await firstValueFrom(nftApi.getNft(nftInternalId, nftCollectionId));
      nftStoreService.setSelectedNftItemForDetailsPage(nftDtoToNftMapper(nftDtoData));
    } catch (err) {
      console.log(err);
      toastrService.error(await firstValueFrom(translateService.get('Nfts-List-Page_Component.NOT-FOUND')));
      return router.parseUrl(PageUrls.HOME);
    }
  }

  return true;
};
