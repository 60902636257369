<togg-base-modal [modalId]="ModalType.BIDDING_HISTORY" [screenType]="ModalScreenType.NORMAL" [showCloseButton]="true">
  <div class="header-content" headerContent>
    <h4 class="title">
      {{ 'BIDDED-MODAL.ACTIONS' | translate }}
    </h4>
  </div>

  <div *ngIf="auctionStoreService.bidHistoryFirstPageLoadStatusObservable$ | async as bidHistoryFirstPageLoadStatus" class="h-full overflow-auto" bodyContent>
    <div *ngIf="bidHistoryFirstPageLoadStatus === LoadingStatus.LOADING || bidHistoryFirstPageLoadStatus === LoadingStatus.NOT_LOADED" class="loading-container">
      <div class="spinner-8bit"></div>
    </div>

    <ng-container *ngIf="bidHistoryFirstPageLoadStatus === LoadingStatus.ERROR">
      <div class="fee-error-container">
        <p>
          {{ 'BIDDED-MODAL.ERROR-LOADING-BID-HISTORY' | translate }}
        </p>
        <button (click)="auctionStoreService.loadBidHistory()" class="retry-button" type="button">
          <img alt="" height="16" ngSrc="/assets/images/refresh-cw.svg" width="16" />
          {{ 'BIDDED-MODAL.RETRY' | translate }}
        </button>
      </div>
    </ng-container>

    <ng-container *ngIf="auctionStoreService.bidHistoryObservable$ | async as bidHistory">
      <div *ngIf="bidHistoryFirstPageLoadStatus === LoadingStatus.LOADED" class="body-content">
        <ng-container *ngIf="bidHistory && bidHistory.length > 0">
          <h6 class="active-bids-title">{{ 'BIDDED-MODAL.PAST-ACTIONS' | translate }} ({{ bidHistory.length }})</h6>

          <div class="grid-bidded-area">
            <togg-bidding-card
              *ngFor="let nftItem of bidHistory"
              [highestBid]="false"
              [nftOperation]="nftOperationDtoToNftOperationMapper(nftItem)"
              class="bidding-history-past-bid-card"></togg-bidding-card>
          </div>
        </ng-container>

        <div *ngIf="bidHistory?.length === 0" class="bidding-history-no-bid">
          <div class="bidding-history-no-bid-header">
            <img alt="No bid" class="no-bid" height="64" ngSrc="/assets/images/bidding-nfts-empty.svg" width="64" />

            <div class="no-bid-text">
              {{ 'BIDDED-MODAL.YOU-HAVE-NOT-PLACED-A-BID' | translate }}
            </div>

            <div class="explore-text">
              {{ 'BIDDED-MODAL.EXPLORE-TEXT' | translate }}
            </div>
          </div>

          <button (click)="goToMarketplace()" class="explore-nft-marketplace-button" type="button">
            {{ 'BIDDED-MODAL.EXPLORE-NFT-MARKETPLACE' | translate }}
          </button>
        </div>
      </div>
    </ng-container>
  </div>
</togg-base-modal>
