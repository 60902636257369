import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { CountlyService, NftStoreService } from '../../services';
import { PageUrls } from '../../enums/page-urls.enum';
import { NftOperation } from '../../services/auction-store/auction-store.service.types';
import { StatusesBidEnum } from '../../enums/statuses-bids.enum';

@Component({
  selector: 'togg-bidding-card[nftOperation]',
  templateUrl: './bidding-card.component.html',
  styleUrls: ['./bidding-card.component.scss'],
})
export class BiddingCardComponent implements OnInit, OnChanges {
  @Input({ required: true }) nftOperation!: NftOperation;
  @Input() highestBid: boolean = false;
  @Input() mainView: boolean = false;

  isMyBidLoading: boolean = true;

  errorStatus = false;
  beatenStatus = false;
  cancelStatus = false;

  notWinningBid = false;
  boughtError = false;
  auctionClosedError = false;
  panicPauseError = false;
  collectionPauseError = false;
  transactionError = false;

  constructor(
    private readonly router: Router,
    private readonly countlyService: CountlyService,
    private readonly nftStoreService: NftStoreService,
  ) {}

  ngOnInit() {
    this.updateStatus();
  }

  updateStatus() {
    if (!this.nftOperation.nft) {
      return;
    }

    this.isMyBidLoading = this.nftOperation.status === StatusesBidEnum.IN_PROGRESS;
    this.beatenStatus = this.nftOperation.status === StatusesBidEnum.BEATEN;
    this.errorStatus = this.nftOperation.status === StatusesBidEnum.ERROR;
    this.cancelStatus = this.nftOperation.status === StatusesBidEnum.CANCELLED;
    if (this.nftOperation.status === StatusesBidEnum.BEATEN) {
      this.setErrorMessages();
    } else if (this.nftOperation.status === StatusesBidEnum.ERROR) {
      this.setErrorMessages();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const changedNftItem = changes['nftOperation'];
    if (changedNftItem?.currentValue) {
      this.updateStatus();
    }
  }

  public async goToNftDetails() {
    if (this.highestBid && this.mainView && this.nftOperation.nft) {
      this.nftStoreService.setSelectedNftItemForDetailsPage(this.nftOperation.nft);
      const navigateUrl = [PageUrls.NFT_DETAILS.replace('/:collection', '').replace('/:id', ''), this.nftOperation.nft.collectionAddress, this.nftOperation.nft.nftId];
      await this.router.navigate(navigateUrl);
    }
  }

  sendCountlyNftClickEvent() {
    if (!this.nftOperation.nft) {
      return;
    }

    this.countlyService.trackEventWithSegmentation({
      key: 'nftViews',
      segmentation: {
        id: this.nftOperation.nft.name,
      },
    });
  }

  private setErrorMessages() {
    this.notWinningBid = !!(
      this.nftOperation.descriptionError?.includes('not winning bid') || this.nftOperation.descriptionError?.includes('Error retrieving transaction receipt: null')
    );
    this.boughtError = !!this.nftOperation.descriptionError?.includes('DNE');
    this.auctionClosedError = !!this.nftOperation.descriptionError?.includes('inactive listing');
    this.panicPauseError = !!this.nftOperation.descriptionError?.includes('Pausable: paused');
    this.collectionPauseError = !!this.nftOperation.descriptionError?.includes('PCL');
    this.transactionError = !!this.nftOperation.descriptionError?.includes('Partially failed on chain');
    //Show error by default in case any message fit with the possible options:
    if (!this.notWinningBid && !this.boughtError && !this.auctionClosedError && !this.panicPauseError && !this.collectionPauseError && !this.transactionError) {
      this.transactionError = true;
    }
  }
}
