import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { environment } from '../../../environments/environment';
import { LoadingStatus } from '../../enums/loading-status.enum';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  private loadingStatus = new BehaviorSubject(LoadingStatus.NOT_LOADED);
  private renderer: Renderer2;

  constructor(private readonly rendererFactory: RendererFactory2) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  loadGTag(): void {
    if ([LoadingStatus.LOADED, LoadingStatus.LOADING].includes(this.loadingStatus.value)) {
      return;
    }

    this.loadingStatus.next(LoadingStatus.LOADING);

    const script = this.renderer.createElement('script');
    this.renderer.setAttribute(script, 'async', 'true');
    this.renderer.setAttribute(script, 'src', `https://www.googletagmanager.com/gtag/js?id=${environment.googleAnalyticsMeasurementId}`);

    this.renderer.listen(script, 'load', () => {
      this.loadingStatus.next(LoadingStatus.LOADED);
    });

    this.renderer.listen(script, 'error', () => {
      this.loadingStatus.next(LoadingStatus.ERROR);
    });

    this.renderer.appendChild(document.head, script);

    window['dataLayer'] = window['dataLayer'] || [];
    window['gtag'] = function () {
      window['dataLayer']?.push(arguments);
    };
    window['gtag']('js', new Date());
    window['gtag']('config', environment.googleAnalyticsMeasurementId);
  }

  sendPageView(url: string): void {
    window['gtag']('config', environment.googleAnalyticsMeasurementId, {
      page_path: url,
    });
  }
}
